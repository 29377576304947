<template>
  <div class="mainBox">
    <div class="header_common_box">
      <v-header :params="{ title, leftIcon: true }"></v-header>
    </div>
    <van-sidebar v-model="activeKey">
      <van-sidebar-item v-for="(item,index) in menuList" :title="item.name" :key="index" @click="clickScroll(item.name)"/>
    </van-sidebar>
    <div class="right_box">
      <div v-for="(item,index) in menuList" :key="index" :id="item.name">
        <h4>{{item.name}}</h4>
        <van-grid v-for="(itemc,index) in item.children" :border="false" :column-num="1" :key="index">
          <van-grid-item>
            <div class="infoTitleBox">
              <h4 :class="returnStyle(itemc.name)">{{itemc.name}}</h4>
            </div>
            <div class="infoMesBox">
              <p v-for="(itemMes,index) in itemc.mes" :key="index">{{itemMes}}</p>
            </div>
            <div class="infoBtnBox">
              <van-button v-for="(itemBtn,index) in itemc.btn" type="danger" size="mini" :key="index" @click="condSelect(itemc.name,itemBtn.name,'add')">{{itemBtn.name}}</van-button>
            </div>
          </van-grid-item>
        </van-grid>
      </div>
    </div>
    <van-tabbar>
      <van-badge class="mes">
        <div>
          <p>{{this.groupMes.groupAll}}组</p>
          <p>{{this.groupMes.mes}}</p>
        </div>
      </van-badge>
      <van-badge class="condition">
        <div @click="showCondition">
          <p>已选择{{this.condSelectNumber}}个条件</p>
          <p>共计{{this.conditionNumber}}个条件</p>
        </div>
      </van-badge>
      <van-badge class="groupNum">
        <div @click="toShowNumber">
          <p>{{this.groupMes.groupSelect}}组</p>
          <p>筛选结果>></p>
        </div>
      </van-badge>
    </van-tabbar>
    <van-popup v-model="selectShow" position="bottom" :style="{ height: 'auto' }" @close="clickCancel">
      <div>
        <a>{{selectTitle1}}</a>
        <a>{{selectTitle2}}</a>
        <van-button type="danger" @click="clickCancel">取消</van-button>
      </div>
      <div>
        <div v-if="this.selectTitle1 === '排除码（死数）'">
          <h5>选择需要排除的号码</h5>
          <ol>
            <li :class="ite ? 'liStyleCheck' : 'liStyle'" v-for="(ite,index) in this.condList.find(item =>(item.name === '排除码（死数）')).btn.find(item => (item.name === '设置')).val" :key="index" @click="Btn1(index,1)">{{index}}</li>
          </ol>
          <p class="mesBox">{{condMesText}}</p>
        </div>
        <div v-if="this.selectTitle1 === '必出码（铁率）'">
          <h5>请选择号码</h5>
          <ol>
            <li :class="ite ? 'liStyleCheck' : 'liStyle'" v-for="(ite,index) in this.condList.find(item =>(item.name === '必出码（铁率）')).btn.find(item => (item.name === '设置')).val" :key="index" @click="Btn1(index,2)">{{index}}</li>
          </ol>
          <p class="mesBox">{{condMesText}}</p>
        </div>
        <div v-if="this.selectTitle1 === '合值（合分）'">
          <h5 style="display:inline-block;">请选择合值</h5>
          <button :class="this.condList.find(item =>(item.name === '合值（合分）')).btn.find(item => (item.name === '设置')).isSave === 0 ? 'btnExclude' : 'btnStyle'"
                  @click="excludeOrSave(0)">排除</button>
          <button :class="this.condList.find(item =>(item.name === '合值（合分）')).btn.find(item => (item.name === '设置')).isSave === 1 ? 'btnSave' : 'btnStyle'"
                  @click="excludeOrSave(1)">保留</button>
          <ol>
            <li ref="check" v-for="(ite,index) in this.condList.find(item =>(item.name === '合值（合分）')).btn.find(item => (item.name === '设置')).val" :key="index" @click="Btn1(index,3)">{{index}}</li>
          </ol>
          <p class="mesBox">{{condMesText}}</p>
        </div>
        <div v-if="this.selectTitle1 === '极值' && this.selectTitle2 === '最大值'">
          <h5 style="display:inline-block;">请选择最大值</h5>
          <button :class="this.condList.find(item =>(item.name === '极值')).btn.find(item => (item.name === '最大值')).isSave === 0 ? 'btnExclude' : 'btnStyle'"
                  @click="excludeOrSave(0)">排除</button>
          <button :class="this.condList.find(item =>(item.name === '极值')).btn.find(item => (item.name === '最大值')).isSave === 1 ? 'btnSave' : 'btnStyle'"
                  @click="excludeOrSave(1)">保留</button>
          <ol>
            <li ref="check" v-for="(ite,index) in this.condList.find(item =>(item.name === '极值')).btn.find(item => (item.name === '最大值')).val" :key="index" @click="Btn1(index,4)">{{index}}</li>
          </ol>
          <p class="mesBox">{{condMesText}}</p>
        </div>
        <div v-if="this.selectTitle1 === '极值' && this.selectTitle2 === '最小值'">
          <h5 style="display:inline-block;">请选择最小值</h5>
          <button :class="this.condList.find(item =>(item.name === '极值')).btn.find(item => (item.name === '最小值')).isSave === 0 ? 'btnExclude' : 'btnStyle'"
                  @click="excludeOrSave(0)">排除</button>
          <button :class="this.condList.find(item =>(item.name === '极值')).btn.find(item => (item.name === '最小值')).isSave === 1 ? 'btnSave' : 'btnStyle'"
                  @click="excludeOrSave(1)">保留</button>
          <ol>
            <li ref="check" v-for="(ite,index) in this.condList.find(item =>(item.name === '极值')).btn.find(item => (item.name === '最小值')).val" :key="index" @click="Btn1(index,5)">{{index}}</li>
          </ol>
          <p class="mesBox">{{condMesText}}</p>
        </div>
        <div v-if="this.selectTitle1 === '和值'">
          <h5 style="display:inline-block;">请选择合值</h5>
          <button :class="this.condList.find(item =>(item.name === '和值')).btn.find(item => (item.name === '设置')).isSave === 0 ? 'btnExclude' : 'btnStyle'"
                  @click="excludeOrSave(0)">排除</button>
          <button :class="this.condList.find(item =>(item.name === '和值')).btn.find(item => (item.name === '设置')).isSave === 1 ? 'btnSave' : 'btnStyle'"
                  @click="excludeOrSave(1)">保留</button>
          <ol>
            <li ref="check" v-for="(ite,index) in this.condList.find(item =>(item.name === '和值')).btn.find(item => (item.name === '设置')).val" :key="index" @click="Btn1(index,6)">{{index}}</li>
          </ol>
          <p class="mesBox">{{condMesText}}</p>
        </div>
        <div v-if="this.selectTitle1 === '头尾有数'">
          <h5 style="display:inline-block;font-size: 13px;margin: 10px 10px;">请选择头尾两个位置中其中<br/>一个位置所包含的号码</h5>
          <button :class="this.condList.find(item =>(item.name === '头尾有数')).btn.find(item => (item.name === '设置')).isSave === 0 ? 'btnExclude' : 'btnStyle'"
                  @click="excludeOrSave(0)">排除</button>
          <button :class="this.condList.find(item =>(item.name === '头尾有数')).btn.find(item => (item.name === '设置')).isSave === 1 ? 'btnSave' : 'btnStyle'"
                  @click="excludeOrSave(1)">保留</button>
          <ol>
            <li ref="check" v-for="(ite,index) in this.condList.find(item =>(item.name === '头尾有数')).btn.find(item => (item.name === '设置')).val" :key="index" @click="Btn1(index,7)">{{index}}</li>
          </ol>
          <p class="mesBox">{{condMesText}}</p>
        </div>
        <div v-if="this.selectTitle1 === '中肚有数'">
          <h5 style="display:inline-block;font-size: 13px;margin: 10px 10px;">请选择百,十两个位置中其中<br/>一个位置所包含的号码</h5>
          <button :class="this.condList.find(item =>(item.name === '中肚有数')).btn.find(item => (item.name === '设置')).isSave === 0 ? 'btnExclude' : 'btnStyle'"
                  @click="excludeOrSave(0)">排除</button>
          <button :class="this.condList.find(item =>(item.name === '中肚有数')).btn.find(item => (item.name === '设置')).isSave === 1 ? 'btnSave' : 'btnStyle'"
                  @click="excludeOrSave(1)">保留</button>
          <ol>
            <li ref="check" v-for="(ite,index) in this.condList.find(item =>(item.name === '中肚有数')).btn.find(item => (item.name === '设置')).val" :key="index" @click="Btn1(index,8)">{{index}}</li>
          </ol>
          <p class="mesBox">{{condMesText}}</p>
        </div>
        <div v-if="this.selectTitle1 === '跨度'">
          <h5 style="display:inline-block;">请选择号码</h5>
          <button :class="this.condList.find(item =>(item.name === '跨度')).btn.find(item => (item.name === '设置')).isSave === 0 ? 'btnExclude' : 'btnStyle'"
                  @click="excludeOrSave(0)">排除</button>
          <button :class="this.condList.find(item =>(item.name === '跨度')).btn.find(item => (item.name === '设置')).isSave === 1 ? 'btnSave' : 'btnStyle'"
                  @click="excludeOrSave(1)">保留</button>
          <ol>
            <li ref="check" v-for="(ite,index) in this.condList.find(item =>(item.name === '跨度')).btn.find(item => (item.name === '设置')).val" :key="index" @click="Btn1(index,9)">{{index}}</li>
          </ol>
          <p class="mesBox">{{condMesText}}</p>
        </div>
        <div v-if="this.selectTitle1 === '合值+跨度' && (this.$store.state.payWay.payType === 5 || this.$store.state.payWay.payType === 6)">
          <h5 style="display:inline-block;">请选择号码</h5>
          <button :class="this.condList.find(item =>(item.name === '合值+跨度')).btn.find(item => (item.name === '设置')).isSave === 0 ? 'btnExclude' : 'btnStyle'"
                  @click="excludeOrSave(0)">排除</button>
          <button :class="this.condList.find(item =>(item.name === '合值+跨度')).btn.find(item => (item.name === '设置')).isSave === 1 ? 'btnSave' : 'btnStyle'"
                  @click="excludeOrSave(1)">保留</button>
          <ol>
            <li ref="check" v-for="(ite,index) in this.condList.find(item =>(item.name === '合值+跨度')).btn.find(item => (item.name === '设置')).val" :key="index" @click="Btn1(index,10)">{{index*2}}</li>
          </ol>
          <p class="mesBox">{{condMesText}}</p>
        </div>
        <div v-if="this.selectTitle1 === '合值+跨度' && this.$store.state.payWay.payType in [1,2,3,4,5]">
          <h5 style="display:inline-block;">请选择号码</h5>
          <button :class="this.condList.find(item =>(item.name === '合值+跨度')).btn.find(item => (item.name === '设置')).isSave === 0 ? 'btnExclude' : 'btnStyle'"
                  @click="excludeOrSave(0)">排除</button>
          <button :class="this.condList.find(item =>(item.name === '合值+跨度')).btn.find(item => (item.name === '设置')).isSave === 1 ? 'btnSave' : 'btnStyle'"
                  @click="excludeOrSave(1)">保留</button>
          <ol>
            <li ref="check" v-for="(ite,index) in this.condList.find(item =>(item.name === '合值+跨度')).btn.find(item => (item.name === '设置')).val" :key="index" @click="Btn1(index,11)">{{index}}</li>
          </ol>
          <p class="mesBox">{{condMesText}}</p>
        </div>
        <div v-if="this.selectTitle1 === '合值-跨度' && (this.$store.state.payWay.payType === 5 || this.$store.state.payWay.payType === 6)">
          <h5 style="display:inline-block;">请选择号码</h5>
          <button :class="this.condList.find(item =>(item.name === '合值-跨度')).btn.find(item => (item.name === '设置')).isSave === 0 ? 'btnExclude' : 'btnStyle'"
                  @click="excludeOrSave(0)">排除</button>
          <button :class="this.condList.find(item =>(item.name === '合值-跨度')).btn.find(item => (item.name === '设置')).isSave === 1 ? 'btnSave' : 'btnStyle'"
                  @click="excludeOrSave(1)">保留</button>
          <ol>
            <li ref="check" v-for="(ite,index) in this.condList.find(item =>(item.name === '合值-跨度')).btn.find(item => (item.name === '设置')).val" :key="index" @click="Btn1(index,12)">{{index*2-8}}</li>
          </ol>
          <p class="mesBox">{{condMesText}}</p>
        </div>
        <div v-if="this.selectTitle1 === '合值-跨度' && this.$store.state.payWay.payType in [1,2,3,4,5]">
          <h5 style="display:inline-block;">请选择号码</h5>
          <button :class="this.condList.find(item =>(item.name === '合值-跨度')).btn.find(item => (item.name === '设置')).isSave === 0 ? 'btnExclude' : 'btnStyle'"
                  @click="excludeOrSave(0)">排除</button>
          <button :class="this.condList.find(item =>(item.name === '合值-跨度')).btn.find(item => (item.name === '设置')).isSave === 1 ? 'btnSave' : 'btnStyle'"
                  @click="excludeOrSave(1)">保留</button>
          <ol>
            <li ref="check" v-for="(ite,index) in this.condList.find(item =>(item.name === '合值-跨度')).btn.find(item => (item.name === '设置')).val" :key="index" @click="Btn1(index,13)">{{index-9}}</li>
          </ol>
          <p class="mesBox">{{condMesText}}</p>
        </div>
        <div v-if="this.selectTitle1 === '余数和'">
          <h5 style="display:inline-block;margin: 5px 0px;font-size: 18px" v-if="[2,4,6].find(item =>(item === this.$store.state.payWay.payType)) != undefined">请选择每个号码分别<br>除以3的余数之和</h5>
          <h5 style="display:inline-block;margin: 5px 0px;font-size: 18px" v-if="[1,3,5].find(item =>(item === this.$store.state.payWay.payType)) != undefined">请选择A/3,B/3,C/3,D/3<br>的余数之和</h5>
          <button :class="this.condList.find(item =>(item.name === '余数和')).btn.find(item => (item.name === '除以3')).isSave === 0 ? 'btnExclude' : 'btnStyle'"
                  @click="excludeOrSave(0)">排除</button>
          <button :class="this.condList.find(item =>(item.name === '余数和')).btn.find(item => (item.name === '除以3')).isSave === 1 ? 'btnSave' : 'btnStyle'"
                  @click="excludeOrSave(1)">保留</button>
          <ol>
            <li ref="check" v-for="(ite,index) in this.condList.find(item =>(item.name === '余数和')).btn.find(item => (item.name === '除以3')).val" :key="index" @click="Btn1(index,14)">{{index}}</li>
          </ol>
          <p class="mesBox">{{condMesText}}</p>
        </div>
        <div v-if="this.selectTitle1 === '配号码'" class="divTwo">
          <h5>请选择号码</h5>
          <div v-for="(ite,index) in this.condList.find(item =>(item.name === '配号码')).btn.find(item =>(item.name === '设置')).group" :key="index">
            <h5>第{{index+1}}组</h5>
            <ol>
              <li :class="item ? 'liConfigNumber' : ''" v-for="(item,index2) in ite" :key="index2" @click="Btn2(index,index2)">{{index2}}</li>
            </ol>
          </div>
          <p class="mesBox">{{condMesText}}</p>
        </div>
        <div v-if="this.selectTitle1 === '连号（兄弟号）'">
          <h5 style="display:inline-block;font-size: 15px;margin: 10px 10px;">请选择连号类型</h5>
          <button :class="this.condList.find(item =>(item.name === '连号（兄弟号）')).btn.find(item => (item.name === '设置')).isSave === 0 ? 'btnExclude' : 'btnStyle'"
                  @click="excludeOrSave(0,'checkBox')">排除</button>
          <button :class="this.condList.find(item =>(item.name === '连号（兄弟号）')).btn.find(item => (item.name === '设置')).isSave === 1 ? 'btnSave' : 'btnStyle'"
                  @click="excludeOrSave(1,'checkBox')">保留</button>
          <van-checkbox-group v-model="continueNumber">
            <van-checkbox name="2" @click="Btn3()" v-if="this.$store.state.payWay.payType in [1,2,3,4,5,6,7]" shape="square" :checked-color="this.condList.find(item =>(item.name === '连号（兄弟号）')).btn.find(item => (item.name === '设置')).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">二连号</span><span style="font-size: 15px">&emsp;出现2个相邻的号码</span></van-checkbox>
            <van-checkbox name="3" @click="Btn3()" v-if="this.$store.state.payWay.payType in [1,2,3,4,7]" shape="square" :checked-color="this.condList.find(item =>(item.name === '连号（兄弟号）')).btn.find(item => (item.name === '设置')).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">三连号</span><span style="font-size: 15px">&emsp;出现3个相邻的号码</span></van-checkbox>
            <van-checkbox name="4" @click="Btn3()" v-if="this.$store.state.payWay.payType in [1,2,7]" shape="square" :checked-color="this.condList.find(item =>(item.name === '连号（兄弟号）')).btn.find(item => (item.name === '设置')).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">四连号</span><span style="font-size: 15px">&emsp;出现4个相邻的号码</span></van-checkbox>
          </van-checkbox-group>
          <p class="mesBox">{{condMesText}}</p>
        </div>
        <div v-if="this.selectTitle1 === '重数'">
          <h5 style="display:inline-block;font-size: 15px;margin: 10px 10px;">请选择重复类型</h5>
          <button :class="this.condList.find(item =>(item.name === '重数')).btn.find(item => (item.name === '设置')).isSave === 0 ? 'btnExclude' : 'btnStyle'"
                  @click="excludeOrSave(0,'checkBox')">排除</button>
          <button :class="this.condList.find(item =>(item.name === '重数')).btn.find(item => (item.name === '设置')).isSave === 1 ? 'btnSave' : 'btnStyle'"
                  @click="excludeOrSave(1,'checkBox')">保留</button>
          <van-checkbox-group v-model="repeatNumber">
            <van-checkbox name="2" @click="Btn4()" v-if="this.$store.state.payWay.payType in [1,2,3,4,5,6,7]" shape="square" :checked-color="this.condList.find(item =>(item.name === '重数')).btn.find(item => (item.name === '设置')).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">双重</span><span style="font-size: 15px">&emsp;含2个相同的数字</span></van-checkbox>
            <van-checkbox name="3" @click="Btn4()" v-if="this.$store.state.payWay.payType in [1,2,3,4,7]" shape="square" :checked-color="this.condList.find(item =>(item.name === '重数')).btn.find(item => (item.name === '设置')).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">三重</span><span style="font-size: 15px">&emsp;含3个相同的数字</span></van-checkbox>
            <van-checkbox name="4" @click="Btn4()" v-if="this.$store.state.payWay.payType in [1,2,7]" shape="square" :checked-color="this.condList.find(item =>(item.name === '重数')).btn.find(item => (item.name === '设置')).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">四重</span><span style="font-size: 15px">&emsp;含4个相同的数字</span></van-checkbox>
            <van-checkbox name="22" @click="Btn4()" v-if="this.$store.state.payWay.payType in [1,2,7]" shape="square" :checked-color="this.condList.find(item =>(item.name === '重数')).btn.find(item => (item.name === '设置')).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">双双重</span><span style="font-size: 15px">&emsp;含2个数字（可以相等）,分别重复2次</span></van-checkbox>
          </van-checkbox-group>
          <p class="mesBox">{{condMesText}}</p>
        </div>
        <div v-if="this.selectTitle1 === '两数乘积' || this.selectTitle1 === '三数乘积' || this.selectTitle1 === '四数乘积'">
          <h5 style="display:inline-block;font-size: 15px;margin: 10px 10px;">请选择位置,合值</h5>
          <button :class="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === '合值')).isSave === 0 ? 'btnExclude' : 'btnStyle'"
                  @click="excludeOrSave(0,'checkBox','multipleBox')">排除</button>
          <button :class="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === '合值')).isSave === 1 ? 'btnSave' : 'btnStyle'"
                  @click="excludeOrSave(1,'checkBox','multipleBox')">保留</button>
          <van-checkbox-group v-model="multiplyNumber" class="multiplyWay" v-if="[1,3].find((item) => (item === this.$store.state.payWay.payType)) !== undefined && this.selectTitle1 === '三数乘积'">
            <van-checkbox name="abc" @click="Btn5()" shape="square" :checked-color="this.condList.find(item =>(item.name === '三数乘积')).btn.find(item => (item.name === '合值')).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">A*B*C</span></van-checkbox>
            <van-checkbox name="abd" @click="Btn5()" shape="square" :checked-color="this.condList.find(item =>(item.name === '三数乘积')).btn.find(item => (item.name === '合值')).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">A*B*D</span></van-checkbox>
            <van-checkbox name="acd" @click="Btn5()" shape="square" :checked-color="this.condList.find(item =>(item.name === '三数乘积')).btn.find(item => (item.name === '合值')).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">A*C*D</span></van-checkbox>
            <van-checkbox name="bcd" @click="Btn5()" shape="square" :checked-color="this.condList.find(item =>(item.name === '三数乘积')).btn.find(item => (item.name === '合值')).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">B*C*D</span></van-checkbox>
          </van-checkbox-group>
          <van-checkbox-group v-model="multiplyNumber" class="multiplyWay" v-if="[1,3,5].find((item) => (item === this.$store.state.payWay.payType)) !== undefined && this.selectTitle1 === '两数乘积'">
            <van-checkbox name="ab" @click="Btn5()" shape="square" :checked-color="this.condList.find(item =>(item.name === '两数乘积')).btn.find(item => (item.name === '合值')).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">A*B</span></van-checkbox>
            <van-checkbox name="ac" @click="Btn5()" shape="square" :checked-color="this.condList.find(item =>(item.name === '两数乘积')).btn.find(item => (item.name === '合值')).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">A*C</span></van-checkbox>
            <van-checkbox name="ad" @click="Btn5()" shape="square" :checked-color="this.condList.find(item =>(item.name === '两数乘积')).btn.find(item => (item.name === '合值')).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">A*D</span></van-checkbox>
            <van-checkbox name="bc" @click="Btn5()" shape="square" :checked-color="this.condList.find(item =>(item.name === '两数乘积')).btn.find(item => (item.name === '合值')).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">B*C</span></van-checkbox>
            <van-checkbox name="bd" @click="Btn5()" shape="square" :checked-color="this.condList.find(item =>(item.name === '两数乘积')).btn.find(item => (item.name === '合值')).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">B*D</span></van-checkbox>
            <van-checkbox name="cd" @click="Btn5()" shape="square" :checked-color="this.condList.find(item =>(item.name === '两数乘积')).btn.find(item => (item.name === '合值')).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">C*D</span></van-checkbox>
          </van-checkbox-group>
          <ol style="padding-top: 10px">
            <li ref="check" v-for="(ite,index) in this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val" :key="index" @click="Btn5(index)">{{index}}</li>
          </ol>
          <p class="mesBox">{{condMesText}}</p>
        </div>
        <div v-if="this.selectTitle1 === '对数'">
          <h5 style="display:inline-block;font-size: 10px;margin: 10px 10px;">请选择号码中出现的对数情况</h5>
          <button :class="this.condList.find(item =>(item.name === '对数')).btn.find(item => (item.name === '设置')).isSave === 0 ? 'btnExclude' : 'btnStyle'"
                  @click="excludeOrSave(0,'checkBox')">排除</button>
          <button :class="this.condList.find(item =>(item.name === '对数')).btn.find(item => (item.name === '设置')).isSave === 1 ? 'btnSave' : 'btnStyle'"
                  @click="excludeOrSave(1,'checkBox')">保留</button>
          <van-checkbox-group v-model="coupleNumber">
            <van-checkbox name="1" @click="Btn6()" v-if="this.$store.state.payWay.payType in [1,2,3,4,5,6,7]" shape="square" :checked-color="this.condList.find(item =>(item.name === '对数')).btn.find(item => (item.name === '设置')).isSave === 0 ? 'red' : 'green'"><span style="font-size: 15px">至少包含1组</span></van-checkbox>
            <van-checkbox name="2" @click="Btn6()" v-if="this.$store.state.payWay.payType in [1,2,3]" shape="square" :checked-color="this.condList.find(item =>(item.name === '对数')).btn.find(item => (item.name === '设置')).isSave === 0 ? 'red' : 'green'"><span style="font-size: 15px">至少包含2组</span></van-checkbox>
          </van-checkbox-group>
          <p class="mesBox">{{condMesText}}</p>
        </div>
        <div v-if="this.selectTitle1 === '有数'">
          <h5 style="display:inline-block;font-size: 15px;margin: 10px 10px;">请选择号码和玩法</h5>
          <button :class="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'btnExclude' : 'btnStyle'"
                  @click="excludeOrSave(0,'checkBox','multipleBox')">排除</button>
          <button :class="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 1 ? 'btnSave' : 'btnStyle'"
                  @click="excludeOrSave(1,'checkBox','multipleBox')">保留</button>
          <van-checkbox-group v-model="haveNumber" class="multiplyWay" v-if="this.selectTitle2 === '3个位置'">
            <van-checkbox name="abc" @click="Btn7()" shape="square" :checked-color="this.condList.find(item =>(item.name === '有数')).btn.find(item => (item.name === '3个位置')).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">A*B*C</span></van-checkbox>
            <van-checkbox name="abd" @click="Btn7()" shape="square" :checked-color="this.condList.find(item =>(item.name === '有数')).btn.find(item => (item.name === '3个位置')).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">A*B*D</span></van-checkbox>
            <van-checkbox name="acd" @click="Btn7()" shape="square" :checked-color="this.condList.find(item =>(item.name === '有数')).btn.find(item => (item.name === '3个位置')).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">A*C*D</span></van-checkbox>
            <van-checkbox name="bcd" @click="Btn7()" shape="square" :checked-color="this.condList.find(item =>(item.name === '有数')).btn.find(item => (item.name === '3个位置')).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">B*C*D</span></van-checkbox>
          </van-checkbox-group>
          <van-checkbox-group v-model="haveNumber" class="multiplyWay" v-if="this.selectTitle2 === '2个位置'">
            <van-checkbox name="ab" @click="Btn7()" shape="square" :checked-color="this.condList.find(item =>(item.name === '有数')).btn.find(item => (item.name === '2个位置')).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">A*B</span></van-checkbox>
            <van-checkbox name="ac" @click="Btn7()" shape="square" :checked-color="this.condList.find(item =>(item.name === '有数')).btn.find(item => (item.name === '2个位置')).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">A*C</span></van-checkbox>
            <van-checkbox name="ad" @click="Btn7()" shape="square" :checked-color="this.condList.find(item =>(item.name === '有数')).btn.find(item => (item.name === '2个位置')).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">A*D</span></van-checkbox>
            <van-checkbox name="bc" @click="Btn7()" shape="square" :checked-color="this.condList.find(item =>(item.name === '有数')).btn.find(item => (item.name === '2个位置')).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">B*C</span></van-checkbox>
            <van-checkbox name="bd" @click="Btn7()" shape="square" :checked-color="this.condList.find(item =>(item.name === '有数')).btn.find(item => (item.name === '2个位置')).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">B*D</span></van-checkbox>
            <van-checkbox name="cd" @click="Btn7()" shape="square" :checked-color="this.condList.find(item =>(item.name === '有数')).btn.find(item => (item.name === '2个位置')).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">C*D</span></van-checkbox>
          </van-checkbox-group>
          <ol style="padding-top: 10px">
            <li ref="check" v-for="(ite,index) in this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val" :key="index" @click="Btn7(index)">{{index}}</li>
          </ol>
          <p class="mesBox">{{condMesText}}</p>
        </div>
        <div v-if="this.selectTitle1 === '两数合' || this.selectTitle1 === '三数合'">
          <h5 style="display:inline-block;font-size: 15px;margin: 10px 10px;">请选择合值</h5>
          <button :class="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'btnExclude' : 'btnStyle'"
                  @click="excludeOrSave(0,'checkBox','multipleBox')">排除</button>
          <button :class="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 1 ? 'btnSave' : 'btnStyle'"
                  @click="excludeOrSave(1,'checkBox','multipleBox')">保留</button>
          <van-checkbox-group v-model="numberSum" class="multiplyWay" v-if="[1,3].find((item) => (item === this.$store.state.payWay.payType)) !== undefined && this.selectTitle2 === '定位置' && this.selectTitle1 === '三数合'">
            <van-checkbox name="abc" @click="Btn8()" shape="square" :checked-color="this.condList.find(item =>(item.name === '三数合')).btn.find(item => (item.name === '定位置')).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">A*B*C</span></van-checkbox>
            <van-checkbox name="abd" @click="Btn8()" shape="square" :checked-color="this.condList.find(item =>(item.name === '三数合')).btn.find(item => (item.name === '定位置')).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">A*B*D</span></van-checkbox>
            <van-checkbox name="acd" @click="Btn8()" shape="square" :checked-color="this.condList.find(item =>(item.name === '三数合')).btn.find(item => (item.name === '定位置')).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">A*C*D</span></van-checkbox>
            <van-checkbox name="bcd" @click="Btn8()" shape="square" :checked-color="this.condList.find(item =>(item.name === '三数合')).btn.find(item => (item.name === '定位置')).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">B*C*D</span></van-checkbox>
          </van-checkbox-group>
          <van-checkbox-group v-model="numberSum" class="multiplyWay" v-if="[1,3,5].find((item) => (item === this.$store.state.payWay.payType)) !== undefined && this.selectTitle2 === '定位置' && this.selectTitle1 === '两数合'">
            <van-checkbox name="ab" @click="Btn8()" shape="square" :checked-color="this.condList.find(item =>(item.name === '两数合')).btn.find(item => (item.name === '定位置')).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">A*B</span></van-checkbox>
            <van-checkbox name="ac" @click="Btn8()" shape="square" :checked-color="this.condList.find(item =>(item.name === '两数合')).btn.find(item => (item.name === '定位置')).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">A*C</span></van-checkbox>
            <van-checkbox name="ad" @click="Btn8()" shape="square" :checked-color="this.condList.find(item =>(item.name === '两数合')).btn.find(item => (item.name === '定位置')).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">A*D</span></van-checkbox>
            <van-checkbox name="bc" @click="Btn8()" shape="square" :checked-color="this.condList.find(item =>(item.name === '两数合')).btn.find(item => (item.name === '定位置')).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">B*C</span></van-checkbox>
            <van-checkbox name="bd" @click="Btn8()" shape="square" :checked-color="this.condList.find(item =>(item.name === '两数合')).btn.find(item => (item.name === '定位置')).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">B*D</span></van-checkbox>
            <van-checkbox name="cd" @click="Btn8()" shape="square" :checked-color="this.condList.find(item =>(item.name === '两数合')).btn.find(item => (item.name === '定位置')).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">C*D</span></van-checkbox>
          </van-checkbox-group>
          <ol style="padding-top: 10px">
            <li ref="check" v-for="(ite,index) in this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val" :key="index" @click="Btn8(index)">{{index}}</li>
          </ol>
          <p class="mesBox">{{condMesText}}</p>
        </div>
        <div v-if="this.selectTitle1 === '单双（奇偶）' || this.selectTitle1 === '大小' || this.selectTitle1 === '质合' || this.selectTitle1 === '大中小' || this.selectTitle1 === '012路'">
          <h5 style="display:inline-block;font-size: 15px;margin: 10px 10px;">请选择号码</h5>
          <button :class="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'btnExclude' : 'btnStyle'"
                  @click="excludeOrSave(0,'checkBox')">排除</button>
          <button :class="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 1 ? 'btnSave' : 'btnStyle'"
                  @click="excludeOrSave(1,'checkBox')">保留</button>
          <van-radio-group v-model="thousandRadio" direction="horizontal" v-if="this.selectTitle2 === '按位快选' && this.selectTitle1 === '单双（奇偶）'">
            <a style="padding-right: 30px">千位</a>
            <van-radio name="1" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">单</van-radio>
            <van-radio name="2" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">双</van-radio>
          </van-radio-group>
          <van-radio-group v-model="hundredRadio" direction="horizontal" v-if="this.selectTitle2 === '按位快选' && this.selectTitle1 === '单双（奇偶）'">
            <a style="padding-right: 30px">百位</a>
            <van-radio name="1" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">单</van-radio>
            <van-radio name="2" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">双</van-radio>
          </van-radio-group>
          <van-radio-group v-model="tenRadio" direction="horizontal" v-if="this.selectTitle2 === '按位快选' && this.selectTitle1 === '单双（奇偶）'">
            <a style="padding-right: 30px">十位</a>
            <van-radio name="1" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">单</van-radio>
            <van-radio name="2" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">双</van-radio>
          </van-radio-group>
          <van-radio-group v-model="pieceRadio" direction="horizontal" v-if="this.selectTitle2 === '按位快选' && this.selectTitle1 === '单双（奇偶）'">
            <a style="padding-right: 30px">个位</a>
            <van-radio name="1" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">单</van-radio>
            <van-radio name="2" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">双</van-radio>
          </van-radio-group>
          <van-radio-group v-model="thousandRadio" direction="horizontal" v-if="this.selectTitle2 === '按位快选' && this.selectTitle1 === '大小'">
            <a style="padding-right: 30px">千位</a>
            <van-radio name="1" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">大</van-radio>
            <van-radio name="2" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">小</van-radio>
          </van-radio-group>
          <van-radio-group v-model="hundredRadio" direction="horizontal" v-if="this.selectTitle2 === '按位快选' && this.selectTitle1 === '大小'">
            <a style="padding-right: 30px">百位</a>
            <van-radio name="1" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">大</van-radio>
            <van-radio name="2" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">小</van-radio>
          </van-radio-group>
          <van-radio-group v-model="tenRadio" direction="horizontal" v-if="this.selectTitle2 === '按位快选' && this.selectTitle1 === '大小'">
            <a style="padding-right: 30px">十位</a>
            <van-radio name="1" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">大</van-radio>
            <van-radio name="2" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">小</van-radio>
          </van-radio-group>
          <van-radio-group v-model="pieceRadio" direction="horizontal" v-if="this.selectTitle2 === '按位快选' && this.selectTitle1 === '大小'">
            <a style="padding-right: 30px">个位</a>
            <van-radio name="1" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">大</van-radio>
            <van-radio name="2" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">小</van-radio>
          </van-radio-group>
          <van-radio-group v-model="thousandRadio" direction="horizontal" v-if="this.selectTitle2 === '按位快选' && this.selectTitle1 === '质合'">
            <a style="padding-right: 30px">千位</a>
            <van-radio name="1" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">质</van-radio>
            <van-radio name="2" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">合</van-radio>
          </van-radio-group>
          <van-radio-group v-model="hundredRadio" direction="horizontal" v-if="this.selectTitle2 === '按位快选' && this.selectTitle1 === '质合'">
            <a style="padding-right: 30px">百位</a>
            <van-radio name="1" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">质</van-radio>
            <van-radio name="2" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">合</van-radio>
          </van-radio-group>
          <van-radio-group v-model="tenRadio" direction="horizontal" v-if="this.selectTitle2 === '按位快选' && this.selectTitle1 === '质合'">
            <a style="padding-right: 30px">十位</a>
            <van-radio name="1" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">质</van-radio>
            <van-radio name="2" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">合</van-radio>
          </van-radio-group>
          <van-radio-group v-model="pieceRadio" direction="horizontal" v-if="this.selectTitle2 === '按位快选' && this.selectTitle1 === '质合'">
            <a style="padding-right: 30px">个位</a>
            <van-radio name="1" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">质</van-radio>
            <van-radio name="2" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">合</van-radio>
          </van-radio-group>
          <van-radio-group v-model="thousandRadio" direction="horizontal" v-if="this.selectTitle2 === '按位快选' && this.selectTitle1 === '大中小'">
            <a style="padding-right: 30px">千位</a>
            <van-radio name="1" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">大</van-radio>
            <van-radio name="3" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">中</van-radio>
            <van-radio name="2" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">小</van-radio>
          </van-radio-group>
          <van-radio-group v-model="hundredRadio" direction="horizontal" v-if="this.selectTitle2 === '按位快选' && this.selectTitle1 === '大中小'">
            <a style="padding-right: 30px">百位</a>
            <van-radio name="1" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">大</van-radio>
            <van-radio name="3" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">中</van-radio>
            <van-radio name="2" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">小</van-radio>
          </van-radio-group>
          <van-radio-group v-model="tenRadio" direction="horizontal" v-if="this.selectTitle2 === '按位快选' && this.selectTitle1 === '大中小'">
            <a style="padding-right: 30px">十位</a>
            <van-radio name="1" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">大</van-radio>
            <van-radio name="3" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">中</van-radio>
            <van-radio name="2" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">小</van-radio>
          </van-radio-group>
          <van-radio-group v-model="pieceRadio" direction="horizontal" v-if="this.selectTitle2 === '按位快选' && this.selectTitle1 === '大中小'">
            <a style="padding-right: 30px">个位</a>
            <van-radio name="1" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">大</van-radio>
            <van-radio name="3" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">中</van-radio>
            <van-radio name="2" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">小</van-radio>
          </van-radio-group>
          <van-radio-group v-model="thousandRadio" direction="horizontal" v-if="this.selectTitle2 === '按位快选' && this.selectTitle1 === '012路'">
            <a style="padding-right: 30px">千位</a>
            <van-radio name="1" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">0路</van-radio>
            <van-radio name="2" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">1路</van-radio>
            <van-radio name="3" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">2路</van-radio>
          </van-radio-group>
          <van-radio-group v-model="hundredRadio" direction="horizontal" v-if="this.selectTitle2 === '按位快选' && this.selectTitle1 === '012路'">
            <a style="padding-right: 30px">百位</a>
            <van-radio name="1" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">0路</van-radio>
            <van-radio name="2" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">1路</van-radio>
            <van-radio name="3" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">2路</van-radio>
          </van-radio-group>
          <van-radio-group v-model="tenRadio" direction="horizontal" v-if="this.selectTitle2 === '按位快选' && this.selectTitle1 === '012路'">
            <a style="padding-right: 30px">十位</a>
            <van-radio name="1" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">0路</van-radio>
            <van-radio name="2" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">1路</van-radio>
            <van-radio name="3" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">2路</van-radio>
          </van-radio-group>
          <van-radio-group v-model="pieceRadio" direction="horizontal" v-if="this.selectTitle2 === '按位快选' && this.selectTitle1 === '012路'">
            <a style="padding-right: 30px">个位</a>
            <van-radio name="1" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">0路</van-radio>
            <van-radio name="2" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">1路</van-radio>
            <van-radio name="3" @click="Btn9" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">2路</van-radio>
          </van-radio-group>
          <van-checkbox-group v-model="posAnyChooseDS" class="positionAnyChoose" direction="horizontal" v-if="this.selectTitle2 === '按位任选' && this.selectTitle1 === '单双（奇偶）' && this.$store.state.payWay.payType === 1">
            <van-checkbox @click="Btn10" name="dddd" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">双双双双</van-checkbox>
            <van-checkbox @click="Btn10" name="ssss" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">单单单单</van-checkbox>
            <van-checkbox @click="Btn10" name="ddds" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">双双双单</van-checkbox>
            <van-checkbox @click="Btn10" name="sssd" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">单单单双</van-checkbox>
            <van-checkbox @click="Btn10" name="ddss" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">双双单单</van-checkbox>
            <van-checkbox @click="Btn10" name="ssdd" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">单单双双</van-checkbox>
            <van-checkbox @click="Btn10" name="dsss" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">双单单单</van-checkbox>
            <van-checkbox @click="Btn10" name="sddd" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">单双双双</van-checkbox>
            <van-checkbox @click="Btn10" name="ddsd" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">双双单双</van-checkbox>
            <van-checkbox @click="Btn10" name="ssds" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">单单双单</van-checkbox>
            <van-checkbox @click="Btn10" name="dsdd" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">双单双双</van-checkbox>
            <van-checkbox @click="Btn10" name="sdss" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">单双单单</van-checkbox>
            <van-checkbox @click="Btn10" name="dsds" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">双单双单</van-checkbox>
            <van-checkbox @click="Btn10" name="sdsd" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">单双单双</van-checkbox>
            <van-checkbox @click="Btn10" name="dssd" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">双单单双</van-checkbox>
            <van-checkbox @click="Btn10" name="sdds" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">单双双单</van-checkbox>
          </van-checkbox-group>
          <van-checkbox-group v-model="posAnyChooseBS" class="positionAnyChoose" direction="horizontal" v-if="this.selectTitle2 === '按位任选' && this.selectTitle1 === '大小' && this.$store.state.payWay.payType === 1">
            <van-checkbox @click="Btn10" name="xxxx" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">小小小小</van-checkbox>
            <van-checkbox @click="Btn10" name="bbbb" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">大大大大</van-checkbox>
            <van-checkbox @click="Btn10" name="xxxb" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">小小小大</van-checkbox>
            <van-checkbox @click="Btn10" name="bbbx" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">大大大小</van-checkbox>
            <van-checkbox @click="Btn10" name="xxbb" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">小小大大</van-checkbox>
            <van-checkbox @click="Btn10" name="bbxx" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">大大小小</van-checkbox>
            <van-checkbox @click="Btn10" name="xbbb" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">小大大大</van-checkbox>
            <van-checkbox @click="Btn10" name="bxxx" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">大小小小</van-checkbox>
            <van-checkbox @click="Btn10" name="xxbx" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">小小大小</van-checkbox>
            <van-checkbox @click="Btn10" name="bbxb" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">大大小大</van-checkbox>
            <van-checkbox @click="Btn10" name="xbxx" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">小大小小</van-checkbox>
            <van-checkbox @click="Btn10" name="bxbb" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">大小大大</van-checkbox>
            <van-checkbox @click="Btn10" name="xbxb" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">小大小大</van-checkbox>
            <van-checkbox @click="Btn10" name="bxbx" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">大小大小</van-checkbox>
            <van-checkbox @click="Btn10" name="xbbx" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">小大大小</van-checkbox>
            <van-checkbox @click="Btn10" name="bxxb" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">大小小大</van-checkbox>
          </van-checkbox-group>
          <van-checkbox-group v-model="posAnyChoosePN" class="positionAnyChoose" direction="horizontal" v-if="this.selectTitle2 === '按位任选' && this.selectTitle1 === '质合' && this.$store.state.payWay.payType === 1">
            <van-checkbox @click="Btn10" name="hhhh" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">合合合合</van-checkbox>
            <van-checkbox @click="Btn10" name="zzzz" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">质质质质</van-checkbox>
            <van-checkbox @click="Btn10" name="hhhz" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">合合合质</van-checkbox>
            <van-checkbox @click="Btn10" name="zzzh" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">质质质合</van-checkbox>
            <van-checkbox @click="Btn10" name="hhzz" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">合合质质</van-checkbox>
            <van-checkbox @click="Btn10" name="zzhh" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">质质合合</van-checkbox>
            <van-checkbox @click="Btn10" name="hzzz" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">合质质质</van-checkbox>
            <van-checkbox @click="Btn10" name="zhhh" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">质合合合</van-checkbox>
            <van-checkbox @click="Btn10" name="hhzh" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">合合质合</van-checkbox>
            <van-checkbox @click="Btn10" name="zzhz" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">质质合质</van-checkbox>
            <van-checkbox @click="Btn10" name="hzhh" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">合质合合</van-checkbox>
            <van-checkbox @click="Btn10" name="zhzz" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">质合质质</van-checkbox>
            <van-checkbox @click="Btn10" name="hzhz" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">合质合质</van-checkbox>
            <van-checkbox @click="Btn10" name="zhzh" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">质合质合</van-checkbox>
            <van-checkbox @click="Btn10" name="hzzh" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">合质质合</van-checkbox>
            <van-checkbox @click="Btn10" name="zhhz" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">质合合质</van-checkbox>
          </van-checkbox-group>
          <van-tabs v-model="active" v-if="this.selectTitle2 === '按位任选' && this.selectTitle1 === '大中小' && this.$store.state.payWay.payType === 1" line-height="2px" color="orange" line-width="60px">
            <van-tab title="千位=大" style="margin: 0">
              <van-checkbox-group v-model="posAnyChooseBMS" class="positionAnyChooseBMS" direction="horizontal" v-if="this.selectTitle2 === '按位任选' && this.selectTitle1 === '大中小'">
                <van-checkbox @click="Btn10" :name="ite.name" shape="square" v-for="(ite,index) in this.createPosAnyChoose('大')" :key="index" :checked-color="ite.color">{{ite.mes}}</van-checkbox>
              </van-checkbox-group>
            </van-tab>
            <van-tab title="千位=中" style="margin: 0">
              <van-checkbox-group v-model="posAnyChooseBMS" class="positionAnyChooseBMS" direction="horizontal" v-if="this.selectTitle2 === '按位任选' && this.selectTitle1 === '大中小'">
                <van-checkbox @click="Btn10" :name="ite.name" shape="square" v-for="(ite,index) in this.createPosAnyChoose('中')" :key="index" :checked-color="ite.color">{{ite.mes}}</van-checkbox>
              </van-checkbox-group>
            </van-tab>
            <van-tab title="千位=小" style="margin: 0;width: 100%">
              <van-checkbox-group v-model="posAnyChooseBMS" class="positionAnyChooseBMS" direction="horizontal" v-if="this.selectTitle2 === '按位任选' && this.selectTitle1 === '大中小'">
                <van-checkbox @click="Btn10" :name="ite.name" shape="square" v-for="(ite,index) in this.createPosAnyChoose('小')" :key="index" :checked-color="ite.color">{{ite.mes}}</van-checkbox>
              </van-checkbox-group>
            </van-tab>
          </van-tabs>
          <van-tabs v-model="active" v-if="this.selectTitle2 === '按位任选' && this.selectTitle1 === '012路' && this.$store.state.payWay.payType === 1" line-height="2px" color="orange" line-width="60px">
            <van-tab title="千位=0路" style="margin: 0">
              <van-checkbox-group v-model="posAnyChoose012" class="positionAnyChooseBMS" direction="horizontal" v-if="this.selectTitle2 === '按位任选' && this.selectTitle1 === '012路'">
                <van-checkbox @click="Btn10" :name="ite.name" shape="square" v-for="(ite,index) in this.createPosAnyChoose('0')" :key="index" :checked-color="ite.color">{{ite.mes}}</van-checkbox>
              </van-checkbox-group>
            </van-tab>
            <van-tab title="千位=1路" style="margin: 0">
              <van-checkbox-group v-model="posAnyChoose012" class="positionAnyChooseBMS" direction="horizontal" v-if="this.selectTitle2 === '按位任选' && this.selectTitle1 === '012路'">
                <van-checkbox @click="Btn10" :name="ite.name" shape="square" v-for="(ite,index) in this.createPosAnyChoose('1')" :key="index" :checked-color="ite.color">{{ite.mes}}</van-checkbox>
              </van-checkbox-group>
            </van-tab>
            <van-tab title="千位=2路" style="margin: 0;width: 100%">
              <van-checkbox-group v-model="posAnyChoose012" class="positionAnyChooseBMS" direction="horizontal" v-if="this.selectTitle2 === '按位任选' && this.selectTitle1 === '012路'">
                <van-checkbox @click="Btn10" :name="ite.name" shape="square" v-for="(ite,index) in this.createPosAnyChoose('2')" :key="index" :checked-color="ite.color">{{ite.mes}}</van-checkbox>
              </van-checkbox-group>
            </van-tab>
          </van-tabs>
          <van-checkbox-group v-model="posAnyChooseDS" class="positionAnyChoose" direction="horizontal" v-if="this.selectTitle2 === '按位任选' && this.selectTitle1 === '单双（奇偶）' && this.$store.state.payWay.payType === 3">
            <van-checkbox @click="Btn10" :name="ite.name" shape="square" v-for="(ite,index) in this.createPosAnyChoose('单双（三定）')" :key="index" :checked-color="ite.color">{{ite.mes}}</van-checkbox>
          </van-checkbox-group>
          <van-checkbox-group v-model="posAnyChooseBS" class="positionAnyChoose" direction="horizontal" v-if="this.selectTitle2 === '按位任选' && this.selectTitle1 === '大小' && this.$store.state.payWay.payType === 3">
            <van-checkbox @click="Btn10" :name="ite.name" shape="square" v-for="(ite,index) in this.createPosAnyChoose('大小（三定）')" :key="index" :checked-color="ite.color">{{ite.mes}}</van-checkbox>
          </van-checkbox-group>
          <van-checkbox-group v-model="posAnyChoosePN" class="positionAnyChoose" direction="horizontal" v-if="this.selectTitle2 === '按位任选' && this.selectTitle1 === '质合' && this.$store.state.payWay.payType === 3">
            <van-checkbox @click="Btn10" :name="ite.name" shape="square" v-for="(ite,index) in this.createPosAnyChoose('质合（三定）')" :key="index" :checked-color="ite.color">{{ite.mes}}</van-checkbox>
          </van-checkbox-group>
          <van-tabs v-model="active" v-if="this.selectTitle2 === '按位任选' && this.selectTitle1 === '大中小' && this.$store.state.payWay.payType === 3" line-height="2px" color="orange" line-width="60px">
            <van-tab title="ABCx" style="margin: 0">
              <van-checkbox-group v-model="posAnyChooseBMS" class="positionAnyChooseBMS" direction="horizontal" v-if="this.selectTitle2 === '按位任选' && this.selectTitle1 === '大中小'">
                <van-checkbox @click="Btn10" :name="ite.name" shape="square" v-for="(ite,index) in this.createPosAnyChoose('大中小（三定）4')" :key="index" :checked-color="ite.color">{{ite.mes}}</van-checkbox>
              </van-checkbox-group>
            </van-tab>
            <van-tab title="ABxD" style="margin: 0">
              <van-checkbox-group v-model="posAnyChooseBMS" class="positionAnyChooseBMS" direction="horizontal" v-if="this.selectTitle2 === '按位任选' && this.selectTitle1 === '大中小'">
                <van-checkbox @click="Btn10" :name="ite.name" shape="square" v-for="(ite,index) in this.createPosAnyChoose('大中小（三定）3')" :key="index" :checked-color="ite.color">{{ite.mes}}</van-checkbox>
              </van-checkbox-group>
            </van-tab>
            <van-tab title="AxCD" style="margin: 0;width: 100%">
              <van-checkbox-group v-model="posAnyChooseBMS" class="positionAnyChooseBMS" direction="horizontal" v-if="this.selectTitle2 === '按位任选' && this.selectTitle1 === '大中小'">
                <van-checkbox @click="Btn10" :name="ite.name" shape="square" v-for="(ite,index) in this.createPosAnyChoose('大中小（三定）2')" :key="index" :checked-color="ite.color">{{ite.mes}}</van-checkbox>
              </van-checkbox-group>
            </van-tab>
            <van-tab title="xACD" style="margin: 0;width: 100%">
              <van-checkbox-group v-model="posAnyChooseBMS" class="positionAnyChooseBMS" direction="horizontal" v-if="this.selectTitle2 === '按位任选' && this.selectTitle1 === '大中小'">
                <van-checkbox @click="Btn10" :name="ite.name" shape="square" v-for="(ite,index) in this.createPosAnyChoose('大中小（三定）1')" :key="index" :checked-color="ite.color">{{ite.mes}}</van-checkbox>
              </van-checkbox-group>
            </van-tab>
          </van-tabs>
          <van-tabs v-model="active" v-if="this.selectTitle2 === '按位任选' && this.selectTitle1 === '012路' && this.$store.state.payWay.payType === 3" line-height="2px" color="orange" line-width="60px">
            <van-tab title="ABCx" style="margin: 0">
              <van-checkbox-group v-model="posAnyChoose012" class="positionAnyChooseBMS" direction="horizontal" v-if="this.selectTitle2 === '按位任选' && this.selectTitle1 === '012路'">
                <van-checkbox @click="Btn10" :name="ite.name" shape="square" v-for="(ite,index) in this.createPosAnyChoose('012路（三定）4')" :key="index" :checked-color="ite.color">{{ite.mes}}</van-checkbox>
              </van-checkbox-group>
            </van-tab>
            <van-tab title="ABxD" style="margin: 0">
              <van-checkbox-group v-model="posAnyChoose012" class="positionAnyChooseBMS" direction="horizontal" v-if="this.selectTitle2 === '按位任选' && this.selectTitle1 === '012路'">
                <van-checkbox @click="Btn10" :name="ite.name" shape="square" v-for="(ite,index) in this.createPosAnyChoose('012路（三定）3')" :key="index" :checked-color="ite.color">{{ite.mes}}</van-checkbox>
              </van-checkbox-group>
            </van-tab>
            <van-tab title="AxCD" style="margin: 0;width: 100%">
              <van-checkbox-group v-model="posAnyChoose012" class="positionAnyChooseBMS" direction="horizontal" v-if="this.selectTitle2 === '按位任选' && this.selectTitle1 === '012路'">
                <van-checkbox @click="Btn10" :name="ite.name" shape="square" v-for="(ite,index) in this.createPosAnyChoose('012路（三定）2')" :key="index" :checked-color="ite.color">{{ite.mes}}</van-checkbox>
              </van-checkbox-group>
            </van-tab>
            <van-tab title="xACD" style="margin: 0;width: 100%">
              <van-checkbox-group v-model="posAnyChoose012" class="positionAnyChooseBMS" direction="horizontal" v-if="this.selectTitle2 === '按位任选' && this.selectTitle1 === '012路'">
                <van-checkbox @click="Btn10" :name="ite.name" shape="square" v-for="(ite,index) in this.createPosAnyChoose('012路（三定）1')" :key="index" :checked-color="ite.color">{{ite.mes}}</van-checkbox>
              </van-checkbox-group>
            </van-tab>
          </van-tabs>
          <van-checkbox-group v-model="posAnyChooseDS" class="positionAnyChoose" direction="horizontal" v-if="this.selectTitle2 === '按位任选' && this.selectTitle1 === '单双（奇偶）' && this.$store.state.payWay.payType === 5">
            <van-checkbox @click="Btn10" :name="ite.name" shape="square" v-for="(ite,index) in this.createPosAnyChoose('单双（二定）')" :key="index" :checked-color="ite.color">{{ite.mes}}</van-checkbox>
          </van-checkbox-group>
          <van-checkbox-group v-model="posAnyChooseBS" class="positionAnyChoose" direction="horizontal" v-if="this.selectTitle2 === '按位任选' && this.selectTitle1 === '大小' && this.$store.state.payWay.payType === 5">
            <van-checkbox @click="Btn10" :name="ite.name" shape="square" v-for="(ite,index) in this.createPosAnyChoose('大小（二定）')" :key="index" :checked-color="ite.color">{{ite.mes}}</van-checkbox>
          </van-checkbox-group>
          <van-checkbox-group v-model="posAnyChoosePN" class="positionAnyChoose" direction="horizontal" v-if="this.selectTitle2 === '按位任选' && this.selectTitle1 === '质合' && this.$store.state.payWay.payType === 5">
            <van-checkbox @click="Btn10" :name="ite.name" shape="square" v-for="(ite,index) in this.createPosAnyChoose('质合（二定）')" :key="index" :checked-color="ite.color">{{ite.mes}}</van-checkbox>
          </van-checkbox-group>
          <van-tabs v-model="active" v-if="this.selectTitle2 === '按位任选' && this.selectTitle1 === '大中小' && this.$store.state.payWay.payType === 5" line-height="2px" color="orange" line-width="40px" style="width: 100%">
            <van-tab title="ABxx" style="margin: 0;">
              <van-checkbox-group v-model="posAnyChooseBMS" class="positionAnyChooseBMS" direction="horizontal" v-if="this.selectTitle2 === '按位任选' && this.selectTitle1 === '大中小'">
                <van-checkbox @click="Btn10" :name="ite.name" shape="square" v-for="(ite,index) in this.createPosAnyChoose('大中小（二定）6')" :key="index" :checked-color="ite.color">{{ite.mes}}</van-checkbox>
              </van-checkbox-group>
            </van-tab>
            <van-tab title="AxCx" style="margin: 0;">
              <van-checkbox-group v-model="posAnyChooseBMS" class="positionAnyChooseBMS" direction="horizontal" v-if="this.selectTitle2 === '按位任选' && this.selectTitle1 === '大中小'">
                <van-checkbox @click="Btn10" :name="ite.name" shape="square" v-for="(ite,index) in this.createPosAnyChoose('大中小（二定）5')" :key="index" :checked-color="ite.color">{{ite.mes}}</van-checkbox>
              </van-checkbox-group>
            </van-tab>
            <van-tab title="AxxD" style="margin: 0;width: 100%">
              <van-checkbox-group v-model="posAnyChooseBMS" class="positionAnyChooseBMS" direction="horizontal" v-if="this.selectTitle2 === '按位任选' && this.selectTitle1 === '大中小'">
                <van-checkbox @click="Btn10" :name="ite.name" shape="square" v-for="(ite,index) in this.createPosAnyChoose('大中小（二定）4')" :key="index" :checked-color="ite.color">{{ite.mes}}</van-checkbox>
              </van-checkbox-group>
            </van-tab>
            <van-tab title="xBCx" style="margin: 0;width: 100%">
              <van-checkbox-group v-model="posAnyChooseBMS" class="positionAnyChooseBMS" direction="horizontal" v-if="this.selectTitle2 === '按位任选' && this.selectTitle1 === '大中小'">
                <van-checkbox @click="Btn10" :name="ite.name" shape="square" v-for="(ite,index) in this.createPosAnyChoose('大中小（二定）3')" :key="index" :checked-color="ite.color">{{ite.mes}}</van-checkbox>
              </van-checkbox-group>
            </van-tab>
            <van-tab title="xBxD" style="margin: 0;width: 100%">
              <van-checkbox-group v-model="posAnyChooseBMS" class="positionAnyChooseBMS" direction="horizontal" v-if="this.selectTitle2 === '按位任选' && this.selectTitle1 === '大中小'">
                <van-checkbox @click="Btn10" :name="ite.name" shape="square" v-for="(ite,index) in this.createPosAnyChoose('大中小（二定）2')" :key="index" :checked-color="ite.color">{{ite.mes}}</van-checkbox>
              </van-checkbox-group>
            </van-tab>
            <van-tab title="xxCD" style="margin: 0;width: 100%">
              <van-checkbox-group v-model="posAnyChooseBMS" class="positionAnyChooseBMS" direction="horizontal" v-if="this.selectTitle2 === '按位任选' && this.selectTitle1 === '大中小'">
                <van-checkbox @click="Btn10" :name="ite.name" shape="square" v-for="(ite,index) in this.createPosAnyChoose('大中小（二定）1')" :key="index" :checked-color="ite.color">{{ite.mes}}</van-checkbox>
              </van-checkbox-group>
            </van-tab>
          </van-tabs>
          <van-tabs v-model="active" v-if="this.selectTitle2 === '按位任选' && this.selectTitle1 === '012路' && this.$store.state.payWay.payType === 5" line-height="2px" color="orange" line-width="40px" style="width: 100%">
            <van-tab title="ABxx" style="margin: 0;">
              <van-checkbox-group v-model="posAnyChoose012" class="positionAnyChooseBMS" direction="horizontal" v-if="this.selectTitle2 === '按位任选' && this.selectTitle1 === '012路'">
                <van-checkbox @click="Btn10" :name="ite.name" shape="square" v-for="(ite,index) in this.createPosAnyChoose('012路（二定）6')" :key="index" :checked-color="ite.color">{{ite.mes}}</van-checkbox>
              </van-checkbox-group>
            </van-tab>
            <van-tab title="AxCx" style="margin: 0;">
              <van-checkbox-group v-model="posAnyChoose012" class="positionAnyChooseBMS" direction="horizontal" v-if="this.selectTitle2 === '按位任选' && this.selectTitle1 === '012路'">
                <van-checkbox @click="Btn10" :name="ite.name" shape="square" v-for="(ite,index) in this.createPosAnyChoose('012路（二定）5')" :key="index" :checked-color="ite.color">{{ite.mes}}</van-checkbox>
              </van-checkbox-group>
            </van-tab>
            <van-tab title="AxxD" style="margin: 0;width: 100%">
              <van-checkbox-group v-model="posAnyChoose012" class="positionAnyChooseBMS" direction="horizontal" v-if="this.selectTitle2 === '按位任选' && this.selectTitle1 === '012路'">
                <van-checkbox @click="Btn10" :name="ite.name" shape="square" v-for="(ite,index) in this.createPosAnyChoose('012路（二定）4')" :key="index" :checked-color="ite.color">{{ite.mes}}</van-checkbox>
              </van-checkbox-group>
            </van-tab>
            <van-tab title="xBCx" style="margin: 0;width: 100%">
              <van-checkbox-group v-model="posAnyChoose012" class="positionAnyChooseBMS" direction="horizontal" v-if="this.selectTitle2 === '按位任选' && this.selectTitle1 === '012路'">
                <van-checkbox @click="Btn10" :name="ite.name" shape="square" v-for="(ite,index) in this.createPosAnyChoose('012路（二定）3')" :key="index" :checked-color="ite.color">{{ite.mes}}</van-checkbox>
              </van-checkbox-group>
            </van-tab>
            <van-tab title="xBxD" style="margin: 0;width: 100%">
              <van-checkbox-group v-model="posAnyChoose012" class="positionAnyChooseBMS" direction="horizontal" v-if="this.selectTitle2 === '按位任选' && this.selectTitle1 === '012路'">
                <van-checkbox @click="Btn10" :name="ite.name" shape="square" v-for="(ite,index) in this.createPosAnyChoose('012路（二定）2')" :key="index" :checked-color="ite.color">{{ite.mes}}</van-checkbox>
              </van-checkbox-group>
            </van-tab>
            <van-tab title="xxCD" style="margin: 0;width: 100%">
              <van-checkbox-group v-model="posAnyChoose012" class="positionAnyChooseBMS" direction="horizontal" v-if="this.selectTitle2 === '按位任选' && this.selectTitle1 === '012路'">
                <van-checkbox @click="Btn10" :name="ite.name" shape="square" v-for="(ite,index) in this.createPosAnyChoose('012路（二定）1')" :key="index" :checked-color="ite.color">{{ite.mes}}</van-checkbox>
              </van-checkbox-group>
            </van-tab>
          </van-tabs>
          <van-checkbox-group v-model="appearRatio" class="multiplyWay" v-if="['单双（奇偶）','大小','质合'].includes(this.selectTitle1) && this.selectTitle2 === '出现比例' && [5,6].includes(this.$store.state.payWay.payType)">
            <van-checkbox name="20" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">2:0</span></van-checkbox>
            <van-checkbox name="02" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">0:2</span></van-checkbox>
            <van-checkbox name="11" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">1:1</span></van-checkbox>
          </van-checkbox-group>
          <van-checkbox-group v-model="appearRatio" class="multiplyWay" v-if="['大中小','012路'].includes(this.selectTitle1) && this.selectTitle2 === '出现比例' && [5,6].includes(this.$store.state.payWay.payType)">
            <van-checkbox name="002" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">0:0:2</span></van-checkbox>
            <van-checkbox name="011" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">0:1:1</span></van-checkbox>
            <van-checkbox name="020" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">0:2:0</span></van-checkbox>
            <van-checkbox name="101" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">1:0:1</span></van-checkbox>
            <van-checkbox name="110" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">1:1:0</span></van-checkbox>
            <van-checkbox name="200" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">2:0:0</span></van-checkbox>
          </van-checkbox-group>
          <van-checkbox-group v-model="appearRatio" class="multiplyWay" v-if="['单双（奇偶）','大小','质合'].includes(this.selectTitle1) && this.selectTitle2 === '出现比例' && [3,4].includes(this.$store.state.payWay.payType)">
            <van-checkbox name="30" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">3:0</span></van-checkbox>
            <van-checkbox name="03" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">0:3</span></van-checkbox>
            <van-checkbox name="21" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">2:1</span></van-checkbox>
            <van-checkbox name="12" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">1:2</span></van-checkbox>
          </van-checkbox-group>
          <van-checkbox-group v-model="appearRatio" class="multiplyWay" v-if="['大中小','012路'].includes(this.selectTitle1) && this.selectTitle2 === '出现比例' && [3,4].includes(this.$store.state.payWay.payType)">
            <van-checkbox name="003" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">0:0:3</span></van-checkbox>
            <van-checkbox name="012" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">0:1:2</span></van-checkbox>
            <van-checkbox name="021" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">0:2:1</span></van-checkbox>
            <van-checkbox name="030" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">0:3:0</span></van-checkbox>
            <van-checkbox name="102" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">1:0:2</span></van-checkbox>
            <van-checkbox name="111" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">1:1:1</span></van-checkbox>
            <van-checkbox name="120" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">1:2:0</span></van-checkbox>
            <van-checkbox name="201" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">2:0:1</span></van-checkbox>
            <van-checkbox name="210" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">2:1:0</span></van-checkbox>
            <van-checkbox name="300" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">3:0:0</span></van-checkbox>
          </van-checkbox-group>
          <van-checkbox-group v-model="appearRatio" class="multiplyWay" v-if="['单双（奇偶）','大小','质合'].includes(this.selectTitle1) && this.selectTitle2 === '出现比例' && [1,2].includes(this.$store.state.payWay.payType)">
            <van-checkbox name="40" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">4:0</span></van-checkbox>
            <van-checkbox name="04" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">0:4</span></van-checkbox>
            <van-checkbox name="31" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">3:1</span></van-checkbox>
            <van-checkbox name="13" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">1:3</span></van-checkbox>
            <van-checkbox name="22" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">2:2</span></van-checkbox>
          </van-checkbox-group>
          <van-checkbox-group v-model="appearRatio" class="multiplyWay" v-if="['大中小','012路'].includes(this.selectTitle1) && this.selectTitle2 === '出现比例' && [1,2].includes(this.$store.state.payWay.payType)">
            <van-checkbox name="004" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">0:0:4</span></van-checkbox>
            <van-checkbox name="013" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">0:1:3</span></van-checkbox>
            <van-checkbox name="022" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">0:2:2</span></van-checkbox>
            <van-checkbox name="031" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">0:3:1</span></van-checkbox>
            <van-checkbox name="040" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">0:4:0</span></van-checkbox>
            <van-checkbox name="103" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">1:0:3</span></van-checkbox>
            <van-checkbox name="112" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">1:1:2</span></van-checkbox>
            <van-checkbox name="121" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">1:2:1</span></van-checkbox>
            <van-checkbox name="130" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">1:3:0</span></van-checkbox>
            <van-checkbox name="202" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">2:0:2</span></van-checkbox>
            <van-checkbox name="211" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">2:1:1</span></van-checkbox>
            <van-checkbox name="220" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">2:2:0</span></van-checkbox>
            <van-checkbox name="301" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">3:0:1</span></van-checkbox>
            <van-checkbox name="310" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">3:1:0</span></van-checkbox>
            <van-checkbox name="400" @click="Btn11" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">4:0:0</span></van-checkbox>
          </van-checkbox-group>
          <van-checkbox-group v-model="breakGroup">
            <van-checkbox name="1" @click="Btn12" v-if="this.selectTitle1 === '单双（奇偶）' && this.selectTitle2 === '断组'" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">单双不同时出现</span></van-checkbox>
            <van-checkbox name="2" @click="Btn12" v-if="this.selectTitle1 === '大小' && this.selectTitle2 === '断组'" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">大小不同时出现</span></van-checkbox>
            <van-checkbox name="3" @click="Btn12" v-if="this.selectTitle1 === '质合' && this.selectTitle2 === '断组'" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">质合不同时出现</span></van-checkbox>
            <van-checkbox name="4" @click="Btn12" v-if="this.selectTitle1 === '大中小' && this.selectTitle2 === '断组'" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">大中小不同时出现</span></van-checkbox>
            <van-checkbox name="5" @click="Btn12" v-if="this.selectTitle1 === '012路' && this.selectTitle2 === '断组'" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">012路不同时出现</span></van-checkbox>
          </van-checkbox-group>
          <p class="mesBox">{{condMesText}}</p>
        </div>
        <div v-if="this.selectTitle1 === '定位置' && this.selectTitle2 === '自由选码'">
          <h5 style="display:inline-block;font-size: 13px;margin: 10px 10px;">请选择号码</h5>
          <button :class="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'btnExclude' : 'btnStyle'"
                  @click="excludeOrSave(0,'isFreeCode')">排除</button>
          <button :class="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 1 ? 'btnSave' : 'btnStyle'"
                  @click="excludeOrSave(1,'isFreeCode')">保留</button>
          <div style="margin-top: 20px;width: 100%;height: 90px;">
            <div style="margin: 0;width: 15%;height: 100%;display: inline-block;text-align: center;">
              <span>千位</span>
            </div>
            <div style="margin: 0;width: 85%;height: 100%;display: inline-block;float: right">
              <ol class="freeCodeOl">
                <li ref="check" v-for="(ite,index) in this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val" :key="index" @click="Btn13(index,0)">{{index}}</li>
              </ol>
            </div>
          </div>
          <div style="margin: 0;width: 100%;height: 90px;">
            <div style="margin: 0;width: 15%;height: 100%;display: inline-block;text-align: center;">
              <span>百位</span>
            </div>
            <div style="margin: 0;width: 85%;height: 100%;display: inline-block;float: right">
              <ol class="freeCodeOl">
                <li ref="check1" v-for="(ite,index) in this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val1" :key="index" @click="Btn13(index,1)">{{index}}</li>
              </ol>
            </div>
          </div>
          <div style="margin: 0;width: 100%;height: 90px;">
            <div style="margin: 0;width: 15%;height: 100%;display: inline-block;text-align: center;">
              <span>十位</span>
            </div>
            <div style="margin: 0;width: 85%;height: 100%;display: inline-block;float: right">
              <ol class="freeCodeOl">
                <li ref="check2" v-for="(ite,index) in this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val2" :key="index" @click="Btn13(index,2)">{{index}}</li>
              </ol>
            </div>
          </div>
          <div style="margin: 0;width: 100%;height: 90px;">
            <div style="margin: 0;width: 15%;height: 100%;display: inline-block;text-align: center;">
              <span>个位</span>
            </div>
            <div style="margin: 0;width: 85%;height: 100%;display: inline-block;float: right">
              <ol class="freeCodeOl">
                <li ref="check3" v-for="(ite,index) in this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val3" :key="index" @click="Btn13(index,3)">{{index}}</li>
              </ol>
            </div>
          </div>
          <p class="mesBox">{{condMesText}}</p>
        </div>
        <div v-if="this.selectTitle1 === '定位置' && this.selectTitle2 === '批量选码'">
          <h5 style="display:inline-block;font-size: 13px;margin: 10px 10px;">请选择号码</h5>
          <button :class="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'btnExclude' : 'btnStyle'"
                  @click="excludeOrSave(0,'checkBox')">排除</button>
          <button :class="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 1 ? 'btnSave' : 'btnStyle'"
                  @click="excludeOrSave(1,'checkBox')">保留</button>
          <div style="margin-top: 20px;width: 100%;height: 90px;">
            <div style="margin: 0;width: 15%;height: 100%;display: inline-block;text-align: center;">
              <span>千位</span>
            </div>
            <div style="margin: 0;width: 85%;height: 100%;display: inline-block;float: right">
              <van-radio-group class="freeCodeRadio" v-model="thousandDanShuangRadio" direction="horizontal">
                <van-radio name="1" @click="Btn14" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">单</van-radio>
                <van-radio name="2" @click="Btn14" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">双</van-radio>
              </van-radio-group>
              <van-radio-group class="freeCodeRadio" v-model="thousandDaXiaoRadio" direction="horizontal">
                <van-radio name="1" @click="Btn14" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">大</van-radio>
                <van-radio name="2" @click="Btn14" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">小</van-radio>
              </van-radio-group>
              <van-radio-group class="freeCodeRadio" v-model="thousand012Radio" direction="horizontal">
                <van-radio name="1" @click="Btn14" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">0路</van-radio>
                <van-radio name="2" @click="Btn14" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">1路</van-radio>
                <van-radio name="3" @click="Btn14" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">2路</van-radio>
              </van-radio-group>
            </div>
          </div>
          <div style="margin: 0;width: 100%;height: 90px;">
            <div style="margin: 0;width: 15%;height: 100%;display: inline-block;text-align: center;">
              <span>百位</span>
            </div>
            <div style="margin: 0;width: 85%;height: 100%;display: inline-block;float: right">
              <van-radio-group class="freeCodeRadio" v-model="hundredDanShuangRadio" direction="horizontal">
                <van-radio name="1" @click="Btn14" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">单</van-radio>
                <van-radio name="2" @click="Btn14" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">双</van-radio>
              </van-radio-group>
              <van-radio-group class="freeCodeRadio" v-model="hundredDaXiaoRadio" direction="horizontal">
                <van-radio name="1" @click="Btn14" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">大</van-radio>
                <van-radio name="2" @click="Btn14" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">小</van-radio>
              </van-radio-group>
              <van-radio-group class="freeCodeRadio" v-model="hundred012Radio" direction="horizontal">
                <van-radio name="1" @click="Btn14" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">0路</van-radio>
                <van-radio name="2" @click="Btn14" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">1路</van-radio>
                <van-radio name="3" @click="Btn14" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">2路</van-radio>
              </van-radio-group>
            </div>
          </div>
          <div style="margin: 0;width: 100%;height: 90px;">
            <div style="margin: 0;width: 15%;height: 100%;display: inline-block;text-align: center;">
              <span>十位</span>
            </div>
            <div style="margin: 0;width: 85%;height: 100%;display: inline-block;float: right">
              <van-radio-group class="freeCodeRadio" v-model="tenDanShuangRadio" direction="horizontal">
                <van-radio name="1" @click="Btn14" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">单</van-radio>
                <van-radio name="2" @click="Btn14" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">双</van-radio>
              </van-radio-group>
              <van-radio-group class="freeCodeRadio" v-model="tenDaXiaoRadio" direction="horizontal">
                <van-radio name="1" @click="Btn14" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">大</van-radio>
                <van-radio name="2" @click="Btn14" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">小</van-radio>
              </van-radio-group>
              <van-radio-group class="freeCodeRadio" v-model="ten012Radio" direction="horizontal">
                <van-radio name="1" @click="Btn14" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">0路</van-radio>
                <van-radio name="2" @click="Btn14" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">1路</van-radio>
                <van-radio name="3" @click="Btn14" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">2路</van-radio>
              </van-radio-group>
            </div>
          </div>
          <div style="margin: 0;width: 100%;height: 90px;">
            <div style="margin: 0;width: 15%;height: 100%;display: inline-block;text-align: center;">
              <span>个位</span>
            </div>
            <div style="margin: 0;width: 85%;height: 100%;display: inline-block;float: right">
              <van-radio-group class="freeCodeRadio" v-model="pieceDanShuangRadio" direction="horizontal">
                <van-radio name="1" @click="Btn14" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">单</van-radio>
                <van-radio name="2" @click="Btn14" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">双</van-radio>
              </van-radio-group>
              <van-radio-group class="freeCodeRadio" v-model="pieceDaXiaoRadio" direction="horizontal">
                <van-radio name="1" @click="Btn14" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">大</van-radio>
                <van-radio name="2" @click="Btn14" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">小</van-radio>
              </van-radio-group>
              <van-radio-group class="freeCodeRadio" v-model="piece012Radio" direction="horizontal">
                <van-radio name="1" @click="Btn14" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">0路</van-radio>
                <van-radio name="2" @click="Btn14" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">1路</van-radio>
                <van-radio name="3" @click="Btn14" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'">2路</van-radio>
              </van-radio-group>
            </div>
          </div>
          <p class="mesBox">{{condMesText}}</p>
        </div>
        <div v-if="this.selectTitle1 === '定位置' && this.selectTitle2 === '乘号位置'">
          <h5 style="display:inline-block;font-size: 13px;margin: 10px 10px;">请选择X所在位置</h5>
          <van-checkbox-group v-model="positionX">
            <van-checkbox :disabled="this.position1" name="1" @click="Btn15()" shape="square"><span style="font-size: 15px">千位</span></van-checkbox>
            <van-checkbox :disabled="this.position2" name="2" @click="Btn15()" shape="square"><span style="font-size: 15px">百位</span></van-checkbox>
            <van-checkbox :disabled="this.position3" name="3" @click="Btn15()" shape="square"><span style="font-size: 15px">十位</span></van-checkbox>
            <van-checkbox :disabled="this.position4" name="4" @click="Btn15()" shape="square"><span style="font-size: 15px">个位</span></van-checkbox>
          </van-checkbox-group>
          <p class="mesBox">{{condMesText}}</p>
        </div>
        <div v-if="this.selectTitle1 === '入数个数'">
          <h5 style="display:inline-block;font-size: 15px;margin: 10px 10px;">请选择重复类型</h5>
          <button :class="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'btnExclude' : 'btnStyle'"
                  @click="excludeOrSave(0,'checkBox')">排除</button>
          <button :class="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 1 ? 'btnSave' : 'btnStyle'"
                  @click="excludeOrSave(1,'checkBox')">保留</button>
          <van-checkbox-group v-model="inNumber">
            <van-checkbox name="1" @click="Btn16()" v-if="this.$store.state.payWay.payType in [1,2,3,4,5,6,7]" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">1个</span><span style="font-size: 15px">&emsp;所有号码相同</span></van-checkbox>
            <van-checkbox name="2" @click="Btn16()" v-if="this.$store.state.payWay.payType in [1,2,3,4,5,6,7]" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">2个</span><span style="font-size: 15px">&emsp;只含2个不同的号码</span></van-checkbox>
            <van-checkbox name="3" @click="Btn16()" v-if="this.$store.state.payWay.payType in [1,2,3,4,7]" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">3个</span><span style="font-size: 15px">&emsp;3个号码均不同</span></van-checkbox>
            <van-checkbox name="4" @click="Btn16()" v-if="this.$store.state.payWay.payType in [1,2,7]" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">4个</span><span style="font-size: 15px">&emsp;4个号码均不同</span></van-checkbox>
          </van-checkbox-group>
          <p class="mesBox">{{condMesText}}</p>
        </div>
        <div v-if="this.selectTitle1 === 'AC值'">
          <h5 style="display:inline-block;">请选择号码</h5>
          <button :class="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'btnExclude' : 'btnStyle'"
                  @click="excludeOrSave(0)">排除</button>
          <button :class="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 1 ? 'btnSave' : 'btnStyle'"
                  @click="excludeOrSave(1)">保留</button>
          <ol>
            <li ref="check" v-for="(ite,index) in this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val" :key="index" @click="Btn1(index,16)">{{index + 1}}</li>
          </ol>
          <p class="mesBox">{{condMesText}}</p>
        </div>
        <div v-if="this.selectTitle1 === '位积' || this.selectTitle1 === '反位积'">
          <h5 style="display:inline-block;">请选择号码</h5>
          <button :class="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'btnExclude' : 'btnStyle'"
                  @click="excludeOrSave(0)">排除</button>
          <button :class="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 1 ? 'btnSave' : 'btnStyle'"
                  @click="excludeOrSave(1)">保留</button>
          <ol v-if="this.selectTitle2 === '合值'">
            <li ref="check" v-for="(ite,index) in this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val" :key="index" @click="Btn1(index,17)">{{index}}</li>
          </ol>
          <ol v-if="this.selectTitle2 === '和值'" class="freeCodeOl">
            <li ref="check" v-for="(ite,index) in this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val" :key="index" @click="Btn1(index,18)">{{index}}</li>
          </ol>
          <p class="mesBox">{{condMesText}}</p>
        </div>
        <div v-if="this.selectTitle1 === '凹凸升降'">
          <h5 style="display:inline-block;font-size: 10px;margin: 10px 10px;">请选择类型</h5>
          <button :class="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'btnExclude' : 'btnStyle'"
                  @click="excludeOrSave(0,'checkBox')">排除</button>
          <button :class="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 1 ? 'btnSave' : 'btnStyle'"
                  @click="excludeOrSave(1,'checkBox')">保留</button>
          <van-checkbox-group v-model="aoTu">
            <van-checkbox name="1" @click="Btn17()" v-if="this.$store.state.payWay.payType === 1" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">凹下型</span><span style="font-size: 15px">&emsp;中间2个数字均小于头尾</span></van-checkbox>
            <van-checkbox name="2" @click="Btn17()" v-if="this.$store.state.payWay.payType === 1" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">凸起型</span><span style="font-size: 15px">&emsp;中间2个数字均大于头尾</span></van-checkbox>
            <van-checkbox name="3" @click="Btn17()" v-if="this.$store.state.payWay.payType === 1" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">上升型</span><span style="font-size: 15px">&emsp;从左到右依次变大</span></van-checkbox>
            <van-checkbox name="4" @click="Btn17()" v-if="this.$store.state.payWay.payType === 1" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">下降型</span><span style="font-size: 15px">&emsp;从左到右依次变小</span></van-checkbox>
            <van-checkbox name="5" @click="Btn17()" v-if="this.$store.state.payWay.payType === 1" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">平行型</span><span style="font-size: 15px">&emsp;四个数字完全相等</span></van-checkbox>
            <van-checkbox name="6" @click="Btn17()" v-if="this.$store.state.payWay.payType === 3" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">凹下型</span><span style="font-size: 15px">&emsp;中间1个数字均小于头尾</span></van-checkbox>
            <van-checkbox name="7" @click="Btn17()" v-if="this.$store.state.payWay.payType === 3" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">凸起型</span><span style="font-size: 15px">&emsp;中间1个数字均大于头尾</span></van-checkbox>
            <van-checkbox name="8" @click="Btn17()" v-if="this.$store.state.payWay.payType === 3" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">上升型</span><span style="font-size: 15px">&emsp;从左到右依次变大</span></van-checkbox>
            <van-checkbox name="9" @click="Btn17()" v-if="this.$store.state.payWay.payType === 3" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">下降型</span><span style="font-size: 15px">&emsp;从左到右依次变小</span></van-checkbox>
            <van-checkbox name="10" @click="Btn17()" v-if="this.$store.state.payWay.payType === 3" shape="square" :checked-color="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green'"><span style="font-weight: 600;font-size: 20px">平行型</span><span style="font-size: 15px">&emsp;三个数字完全相等</span></van-checkbox>
          </van-checkbox-group>
          <p class="mesBox">{{condMesText}}</p>
        </div>
        <div v-if="this.selectTitle1 === '减式'">
          <h5 style="display:inline-block;font-size: 10px;margin: 10px 10px;">请选择位置，合值</h5>
          <button :class="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'btnExclude' : 'btnStyle'"
                  @click="excludeOrSave(0,'decWay')">排除</button>
          <button :class="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 1 ? 'btnSave' : 'btnStyle'"
                  @click="excludeOrSave(1,'decWay')">保留</button>
          <div style="width: 100%;height: 20%;margin-top: 15px;">
            <div style="width: 40%;height: 100%;float: left;margin: 0;display: inline-block">
              <button @click="Btn18('A','add')" ref="addA" style="background-color: white;color: black;border-width: 1px;width: 20%;height: 80%;font-size: 20px;margin: 0px 2px;border-color:grey;">A</button>
              <button @click="Btn18('B','add')" ref="addB" style="background-color: white;color: black;border-width: 1px;width: 20%;height: 80%;font-size: 20px;margin: 0px 2px;border-color:grey;">B</button>
              <button @click="Btn18('C','add')" ref="addC" style="background-color: white;color: black;border-width: 1px;width: 20%;height: 80%;font-size: 20px;margin: 0px 2px;border-color:grey;">C</button>
              <button @click="Btn18('D','add')" ref="addD" style="background-color: white;border-width: 1px;width: 20%;height: 80%;font-size: 20px;margin: 0px 2px;border-color:grey;">D</button>
            </div>
            <div style="width: 20%;height: 100%;margin: 0;float: left;text-align: center">
              <span style="margin: 0;font-size: 20px">减去</span>
            </div>
            <div style="width: 40%;height: 100%;float: right;margin: 0">
              <button @click="Btn18('A','dec')" ref="decA" style="background-color: white;color: black;border-width: 1px;width: 20%;height: 80%;font-size: 20px;margin: 0px 2px;border-color:grey;">A</button>
              <button @click="Btn18('B','dec')" ref="decB" style="background-color: white;color: black;border-width: 1px;width: 20%;height: 80%;font-size: 20px;margin: 0px 2px;border-color:grey;">B</button>
              <button @click="Btn18('C','dec')" ref="decC" style="background-color: white;color: black;border-width: 1px;width: 20%;height: 80%;font-size: 20px;margin: 0px 2px;border-color:grey;">C</button>
              <button @click="Btn18('D','dec')" ref="decD" style="background-color: white;color: black;border-width: 1px;width: 20%;height: 80%;font-size: 20px;margin: 0px 2px;border-color:grey;">D</button>
            </div>
          </div>
          <ol style="margin-top: 60px">
            <li ref="check" v-for="(ite,index) in this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val" :key="index" @click="Btn18('','',index)">{{index}}</li>
          </ol>
          <p class="mesBox">{{condMesText}}</p>
        </div>
        <div v-if="this.selectTitle1 === '全转（全倒）'">
          <h5 style="display:inline-block;font-size: 10px;margin: 10px 10px;">点击下方数字键盘，输入数字</h5>
          <div style="width: 100%;height: 40%;display: inline-block;">
            <van-button type="default" v-for="(item,index) in [0,1,2,3,4,5,6,7,8,9]" :key="index" style="display: inline-block;
            float: left;
            margin: 3px 10px;
            border-radius: 50%;
            width: 36px;height: 36px;
            background-color: white;
            border-color: gray;
            border-width: 1px;
            padding: 0;" @click="Btn19(index)">{{item}}</van-button>
          </div>
          <p class="mesBox">{{condMesText}}</p>
        </div>
        <div v-if="this.selectTitle1 === '开奖历史'">
          <h5 style="display:inline-block;font-size: 10px;margin: 10px 10px;">请设置开奖历史区间</h5>
          <button :class="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'btnExclude' : 'btnStyle'"
                  @click="excludeOrSave(0,'history')">排除</button>
          <button :class="this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 1 ? 'btnSave' : 'btnStyle'"
                  @click="excludeOrSave(1,'history')">保留</button>
          <div style="width: 100%;height: 50%;margin-top: 20px">
            <button ref="t10" @click="Btn20('t10')" style="width: 20%;height: 30%;padding: 0;margin: 10px 5px;font-size: 15px;background-color: white;border-color: grey;border-width: 1px">近10期</button>
            <button ref="t20" @click="Btn20('t20')" style="width: 20%;height: 30%;padding: 0;margin: 10px 5px;font-size: 15px;background-color: white;border-color: grey;border-width: 1px">近20期</button>
            <button ref="t50" @click="Btn20('t50')" style="width: 20%;height: 30%;padding: 0;margin: 10px 5px;font-size: 15px;background-color: white;border-color: grey;border-width: 1px">近50期</button>
            <button ref="t100" @click="Btn20('t100')" style="width: 20%;height: 30%;padding: 0;margin: 10px 5px;font-size: 15px;background-color: white;border-color: grey;border-width: 1px">近100期</button>
            <br/>
            <a style="width: 40%;height: 30%;font-size: 15px;margin-right: 10px;margin-left: 5px;color: grey;border:solid;border-width: 1px;padding: 5px 8px;">{{this.timeNumberStart}}</a>
            <a style="width: 40%;height: 30%;font-size: 15px;margin-right: 5px;margin-left: 1px;color: grey;padding: 5px 1px;">~</a>
            <a style="width: 40%;height: 30%;font-size: 15px;margin-right: 10px;margin-left: 5px;color: grey;border:solid;border-width: 1px;padding: 5px 8px;">{{this.timeNumberEnd}}</a>
          </div>
          <p class="mesBox">{{condMesText}}</p>
        </div>
      </div>
      <div>
        <van-button type="danger" style="background-color: #7a7a7a" @click="clearBtn">清除</van-button>
        <van-button type="danger" style="background-color: red" @click="updateCondition">确定</van-button>
      </div>
    </van-popup>
    <div class="conditionNum">
      <van-popup v-model="conditionShow" position="bottom" :style="{ maxHeight: '85%' }" v-if="this.$store.state.payWay.payType === 1" @close="closeCond">
        <div>
          <div class="lapDev">
            <button :class="this.$store.state.payWay.conditionFourDec[0].isOverlap === 1 ? 'chooseClass' : 'noChooseClass'" @click="overLapClick(1)">交集</button>
            <button :class="this.$store.state.payWay.conditionFourDec[0].isOverlap === 2 ? 'chooseClass' : 'noChooseClass'" @click="overLapClick(2)">并集</button>
          </div>
          <van-button @click="clearCondition">清除</van-button>
          <van-button @click="closeCond">关闭</van-button>
        </div>
        <p>单击条件可以进行修改条件内容</p>
        <template v-for="(item) in this.$store.state.payWay.conditionFourDec">
          <template v-for="(ite,index) in item.btn">
            <div class="condMes" :key="item.name+ite.name+index" v-if="ite.isTrue === 1 || ite.isTrue === 2">
              <van-icon name="passed" :class="ite.isTrue === 1 ? 'iconCheck' : 'iconNoCheck'" @click="condSaveOrExclude(item.name,ite.name,index,ite.isTrue)"/>
              <div @click="condSelect(item.name,ite.name,'update',index)">
                <p class="condTitle">{{item.name}}</p>
                <p class="btnTitle">{{ite.name}}</p>
                <p class="condNumber">{{ite.isSave === 0 ? '排除' : '保留'}} {{ite.mes}}</p>
              </div>
              <van-button type="default" @click="deleteCond(item.name,ite.name,index)">删除</van-button>
            </div>
          </template>
        </template>
        <div class="padDiv"></div>
      </van-popup>
      <van-popup v-model="conditionShow" position="bottom" :style="{ maxHeight: '85%' }" v-if="this.$store.state.payWay.payType === 2" @close="closeCond">
        <div>
          <div class="lapDev">
            <button :class="this.$store.state.payWay.conditionFourShow[0].isOverlap === 1 ? 'chooseClass' : 'noChooseClass'" @click="overLapClick(1)">交集</button>
            <button :class="this.$store.state.payWay.conditionFourShow[0].isOverlap === 2 ? 'chooseClass' : 'noChooseClass'" @click="overLapClick(2)">并集</button>
          </div>
          <van-button @click="clearCondition">清除</van-button>
          <van-button @click="closeCond">关闭</van-button>
        </div>
        <p>单击条件可以进行修改条件内容</p>
        <template v-for="(item) in this.$store.state.payWay.conditionFourShow">
          <template v-for="(ite,index) in item.btn">
            <div class="condMes" :key="item.name+ite.name+index" v-if="ite.isTrue === 1 || ite.isTrue === 2">
              <van-icon name="passed" :class="ite.isTrue === 1 ? 'iconCheck' : 'iconNoCheck'" @click="condSaveOrExclude(item.name,ite.name,index,ite.isTrue)"/>
              <div @click="condSelect(item.name,ite.name,'update',index)">
                <p class="condTitle">{{item.name}}</p>
                <p class="btnTitle">{{ite.name}}</p>
                <p class="condNumber">{{ite.isSave === 0 ? '排除' : '保留'}} {{ite.mes}}</p>
              </div>
              <van-button type="default" @click="deleteCond(item.name,ite.name,index)">删除</van-button>
            </div>
          </template>
        </template>
        <div class="padDiv"></div>
      </van-popup>
      <van-popup v-model="conditionShow" position="bottom" :style="{ maxHeight: '85%' }" v-if="this.$store.state.payWay.payType === 3" @close="closeCond">
        <div>
          <div class="lapDev">
            <button :class="this.$store.state.payWay.conditionThreeDec[0].isOverlap === 1 ? 'chooseClass' : 'noChooseClass'" @click="overLapClick(1)">交集</button>
            <button :class="this.$store.state.payWay.conditionThreeDec[0].isOverlap === 2 ? 'chooseClass' : 'noChooseClass'" @click="overLapClick(2)">并集</button>
          </div>
          <van-button @click="clearCondition">清除</van-button>
          <van-button @click="closeCond">关闭</van-button>
        </div>
        <p>单击条件可以进行修改条件内容</p>
        <template v-for="(item) in this.$store.state.payWay.conditionThreeDec">
          <template v-for="(ite,index) in item.btn">
            <div class="condMes" :key="item.name+ite.name+index" v-if="ite.isTrue === 1 || ite.isTrue === 2">
              <van-icon name="passed" :class="ite.isTrue === 1 ? 'iconCheck' : 'iconNoCheck'" @click="condSaveOrExclude(item.name,ite.name,index,ite.isTrue)"/>
              <div @click="condSelect(item.name,ite.name,'update',index)">
                <p class="condTitle">{{item.name}}</p>
                <p class="btnTitle">{{ite.name}}</p>
                <p class="condNumber">{{ite.isSave === 0 ? '排除' : '保留'}} {{ite.mes}}</p>
              </div>
              <van-button type="default" @click="deleteCond(item.name,ite.name,index)">删除</van-button>
            </div>
          </template>
        </template>
        <div class="padDiv"></div>
      </van-popup>
      <van-popup v-model="conditionShow" position="bottom" :style="{ maxHeight: '85%' }" v-if="this.$store.state.payWay.payType === 4" @close="closeCond">
        <div>
          <div class="lapDev">
            <button :class="this.$store.state.payWay.conditionThreeShow[0].isOverlap === 1 ? 'chooseClass' : 'noChooseClass'" @click="overLapClick(1)">交集</button>
            <button :class="this.$store.state.payWay.conditionThreeShow[0].isOverlap === 2 ? 'chooseClass' : 'noChooseClass'" @click="overLapClick(2)">并集</button>
          </div>
          <van-button @click="clearCondition">清除</van-button>
          <van-button @click="closeCond">关闭</van-button>
        </div>
        <p>单击条件可以进行修改条件内容</p>
        <template v-for="(item) in this.$store.state.payWay.conditionThreeShow">
          <template v-for="(ite,index) in item.btn">
            <div class="condMes" :key="item.name+ite.name+index" v-if="ite.isTrue === 1 || ite.isTrue === 2">
              <van-icon name="passed" :class="ite.isTrue === 1 ? 'iconCheck' : 'iconNoCheck'" @click="condSaveOrExclude(item.name,ite.name,index,ite.isTrue)"/>
              <div @click="condSelect(item.name,ite.name,'update',index)">
                <p class="condTitle">{{item.name}}</p>
                <p class="btnTitle">{{ite.name}}</p>
                <p class="condNumber">{{ite.isSave === 0 ? '排除' : '保留'}} {{ite.mes}}</p>
              </div>
              <van-button type="default" @click="deleteCond(item.name,ite.name,index)">删除</van-button>
            </div>
          </template>
        </template>
        <div class="padDiv"></div>
      </van-popup>
      <van-popup v-model="conditionShow" position="bottom" :style="{ maxHeight: '85%' }" v-if="this.$store.state.payWay.payType === 5" @close="closeCond">
        <div>
          <div class="lapDev">
            <button :class="this.$store.state.payWay.conditionTwoDec[0].isOverlap === 1 ? 'chooseClass' : 'noChooseClass'" @click="overLapClick(1)">交集</button>
            <button :class="this.$store.state.payWay.conditionTwoDec[0].isOverlap === 2 ? 'chooseClass' : 'noChooseClass'" @click="overLapClick(2)">并集</button>
          </div>
          <van-button @click="clearCondition">清除</van-button>
          <van-button @click="closeCond">关闭</van-button>
        </div>
        <p>单击条件可以进行修改条件内容</p>
        <template v-for="(item) in this.$store.state.payWay.conditionTwoDec">
          <template v-for="(ite,index) in item.btn">
            <div class="condMes" :key="item.name+ite.name+index" v-if="ite.isTrue === 1 || ite.isTrue === 2">
              <van-icon name="passed" :class="ite.isTrue === 1 ? 'iconCheck' : 'iconNoCheck'" @click="condSaveOrExclude(item.name,ite.name,index,ite.isTrue)"/>
              <div @click="condSelect(item.name,ite.name,'update',index)">
                <p class="condTitle">{{item.name}}</p>
                <p class="btnTitle">{{ite.name}}</p>
                <p class="condNumber">{{ite.isSave === 0 ? '排除' : '保留'}} {{ite.mes}}</p>
              </div>
              <van-button type="default" @click="deleteCond(item.name,ite.name,index)">删除</van-button>
            </div>
          </template>
        </template>
        <div class="padDiv"></div>
      </van-popup>
      <van-popup v-model="conditionShow" position="bottom" :style="{ maxHeight: '85%' }" v-if="this.$store.state.payWay.payType === 6" @close="closeCond">
        <div>
          <div class="lapDev">
            <button :class="this.$store.state.payWay.conditionTwoShow[0].isOverlap === 1 ? 'chooseClass' : 'noChooseClass'" @click="overLapClick(1)">交集</button>
            <button :class="this.$store.state.payWay.conditionTwoShow[0].isOverlap === 2 ? 'chooseClass' : 'noChooseClass'" @click="overLapClick(2)">并集</button>
          </div>
          <van-button @click="clearCondition">清除</van-button>
          <van-button @click="closeCond">关闭</van-button>
        </div>
        <p>单击条件可以进行修改条件内容</p>
        <template v-for="(item) in this.$store.state.payWay.conditionTwoShow">
          <template v-for="(ite,index) in item.btn">
            <div class="condMes" :key="item.name+ite.name+index" v-if="ite.isTrue === 1 || ite.isTrue === 2">
              <van-icon name="passed" :class="ite.isTrue === 1 ? 'iconCheck' : 'iconNoCheck'" @click="condSaveOrExclude(item.name,ite.name,index,ite.isTrue)"/>
              <div @click="condSelect(item.name,ite.name,'update',index)">
                <p class="condTitle">{{item.name}}</p>
                <p class="btnTitle">{{ite.name}}</p>
                <p class="condNumber">{{ite.isSave === 0 ? '排除' : '保留'}} {{ite.mes}}</p>
              </div>
              <van-button type="default" @click="deleteCond(item.name,ite.name,index)">删除</van-button>
            </div>
          </template>
        </template>
        <div class="padDiv"></div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import vHeader from "@/components/header.vue";
import isWeixin from "@/utils/utils";
import {List, Toast} from "vant";
export default {
  components: {
    vHeader,
  },
  data(){
    return{
      active: 1,
      title: this.$route.meta.title,
      activeKey: 0,
      menuList: [],  //菜单列表
      selectTitle1: '',  //底部弹框标题
      selectTitle2: '',   //底部弹框按钮名称
      selectShow: false,
      condList: [],  //所选条件数据列表
      condMes: [],  //所选号码列表
      condMesText: '',  //所选号码展示
      conditionShow: false,
      continueNumber: [], //连号玩法checkbox
      repeatNumber: [], //重数玩法checkbox
      multiplyNumber: [], //乘积玩法checkbox
      coupleNumber: [], //对数玩法checkbox
      haveNumber: [], //有数玩法checkbox
      numberSum: [], //两数合,三数合checkbox
      // 按位快选
      thousandRadio: 0,
      hundredRadio: 0,
      tenRadio: 0,
      pieceRadio: 0,
      // 按位任选
      posAnyChooseDS: [], //单双
      posAnyChooseBS: [], //大小
      posAnyChoosePN: [], //质合
      posAnyChooseBMS: [], //大中小
      posAnyChoose012: [], //012
      //出现比例
      appearRatio: [],
      //断组
      breakGroup: [],
      //判断修改还是新增 0新增 1修改
      addOrUpdate: 0,
      //更新按钮的下标
      updateIndex: 0,
      //批量选码按钮
      //千位 单双 大小 012
      thousandDanShuangRadio: 0,
      thousandDaXiaoRadio: 0,
      thousand012Radio: 0,
      //百位 单双 大小 012
      hundredDanShuangRadio: 0,
      hundredDaXiaoRadio: 0,
      hundred012Radio: 0,
      //十位 单双 大小 012
      tenDanShuangRadio: 0,
      tenDaXiaoRadio: 0,
      ten012Radio: 0,
      //个位 单双 大小 012
      pieceDanShuangRadio: 0,
      pieceDaXiaoRadio: 0,
      piece012Radio: 0,
      //乘号位置
      positionX: [],
      position1: false,
      position2: false,
      position3: false,
      position4: false,
      //入数个数
      inNumber: [],
      //凹凸升降
      aoTu: [],
      //减式
      add: [],
      dec: [],
      //开始期号
      timeNumberStart: '开始期号',
      timeNumberEnd: '结束期号',
      //开奖历史数据
      historyData: {},
      //当亲滚动条高度
      nowScroll: 0,
    }
  },
  computed:{
    groupMes(){
      let groupMes = {
        groupAll: 0,
        mes: '',
        groupSelect: 0,
      };
      switch (this.$store.state.payWay.payType) {
        case 1:
          groupMes.groupAll = 10000;
          groupMes.mes = '四定大底';
          groupMes.groupSelect = this.$store.state.payWay.fourDecComputed.length;
          break;
        case 2:
          groupMes.groupAll = 715;
          groupMes.mes = '四现大底';
          groupMes.groupSelect = this.$store.state.payWay.fourShowComputed.length;
          break;
        case 3:
          groupMes.groupAll = 4000;
          groupMes.mes = '三定大底';
          groupMes.groupSelect = this.$store.state.payWay.threeDecComputed.length;
          break;
        case 4:
          groupMes.groupAll = 220;
          groupMes.mes = '三现大底';
          groupMes.groupSelect = this.$store.state.payWay.threeShowComputed.length;
          break;
        case 5:
          groupMes.groupAll = 600;
          groupMes.mes = '二定大底';
          groupMes.groupSelect = this.$store.state.payWay.twoDecComputed.length;
          break;
        case 6:
          groupMes.groupAll = 55;
          groupMes.mes = '二现大底';
          groupMes.groupSelect = this.$store.state.payWay.twoShowComputed.length;
          break;
      }
      return groupMes;
    },
    //计算所选条件数
    condSelectNumber(){
      let num = 0;
      let list = [];
      switch (this.$store.state.payWay.payType) {
        case 1:
          list = this.$store.state.payWay.conditionFourDec;
          break;
        case 2:
          list = this.$store.state.payWay.conditionFourShow;
          break;
        case 3:
          list = this.$store.state.payWay.conditionThreeDec;
          break;
        case 4:
          list = this.$store.state.payWay.conditionThreeShow;
          break;
        case 5:
          list = this.$store.state.payWay.conditionTwoDec;
          break;
        case 6:
          list = this.$store.state.payWay.conditionTwoShow;
          break;
      }
      for (let i=1;i<list.length;i++){
        let select = list[i].btn.filter(item => (item.isTrue === 1));
        num = num + select.length;
      }
      return num;
    },
    //计算所选总共条件数
    conditionNumber(){
      let num = 0;
      let list = [];
      switch (this.$store.state.payWay.payType) {
        case 1:
          list = this.$store.state.payWay.conditionFourDec;
          break;
        case 2:
          list = this.$store.state.payWay.conditionFourShow;
          break;
        case 3:
          list = this.$store.state.payWay.conditionThreeDec;
          break;
        case 4:
          list = this.$store.state.payWay.conditionThreeShow;
          break;
        case 5:
          list = this.$store.state.payWay.conditionTwoDec;
          break;
        case 6:
          list = this.$store.state.payWay.conditionTwoShow;
          break;
      }
      for (let i=1;i<list.length;i++){
        let select = list[i].btn.filter(item => (item.isTrue != 0));
        num = num + select.length;
      }
      return num;
    },
  },
  created() {
    let par = this.$route.params.key;
    if (par !=undefined){
      this.$store.commit('updatePayType',par);
    }
    switch (this.$store.state.payWay.payType) {
      case 1:
        this.condList = this.$store.state.payWay.condFourDec;
        break;
      case 2:
        this.condList = this.$store.state.payWay.condFourShow;
        break;
      case 3:
        this.condList = this.$store.state.payWay.condThreeDec;
        break;
      case 4:
        this.condList = this.$store.state.payWay.condThreeShow;
        break;
      case 5:
        this.condList = this.$store.state.payWay.condTwoDec;
        break;
      case 6:
        this.condList = this.$store.state.payWay.condTwoShow;
        break;
    }

  },
  mounted:function(){
    //判断网页是什么打开
    let isWeiXin = isWeixin()
    if(isWeiXin){
      this.showHeader = false
    }
    if(this.showHeader==false){
      document.title = ''
    }
    window.addEventListener("scroll",this.handleScroll,true)

    //根据参数判断菜单
    switch (this.$store.state.payWay.payType){
      case 1:
        this.menuList = this.$store.state.payWay.menuFourDec;
        this.title = '四定添加条件';
        break;
      case 2:
        this.menuList = this.$store.state.payWay.menuFourShow;
        this.title = '四现添加条件';
        break;
      case 3:
        this.menuList = this.$store.state.payWay.menuThreeDec;
        this.title = '三定添加条件';
        break;
      case 4:
        this.menuList = this.$store.state.payWay.menuThreeShow;
        this.title = '三现添加条件';
        break;
      case 5:
        this.menuList = this.$store.state.payWay.menuTwoDec;
        this.title = '二定添加条件';
        break;
      case 6:
        this.menuList = this.$store.state.payWay.menuTwoShow;
        this.title = '二现添加条件';
        break;
    }
    this.setAllNum();
    this.numComputedSelect();
    switch (this.$store.state.payWay.payType) {
      case 1:
        this.condList = this.deepClone(this.$store.state.payWay.condFourDecInit);
        break;
      case 2:
        this.condList = this.deepClone(this.$store.state.payWay.condFourShowInit);
        break;
      case 3:
        this.condList = this.deepClone(this.$store.state.payWay.condThreeDecInit);
        break;
      case 4:
        this.condList = this.deepClone(this.$store.state.payWay.condThreeShowInit);
        break;
      case 5:
        this.condList = this.deepClone(this.$store.state.payWay.condTwoDecInit);
        break;
      case 6:
        this.condList = this.deepClone(this.$store.state.payWay.condTwoShowInit);
        break;
    }
  },
  methods:{
    handleScroll(){
      let scrollTop = window.document.querySelector('.right_box').scrollTop;
      // this.menuList.forEach((item,index) =>{
      //   let offsetTop = window.document.querySelector('#'+item.name).offsetTop;
      //   if (offsetTop === scrollTop){
      //     this.activeKey = index;
      //   }
      // })
      if (this.nowScroll < scrollTop){
        let offsetTopSum = 0;
        for (let i=0;i<=this.activeKey;i++){
          offsetTopSum = offsetTopSum + window.document.querySelector('#'+this.menuList[i].name).offsetHeight;
        }
        if (scrollTop > offsetTopSum){
          this.activeKey = this.activeKey + 1;
        }
      }
      if (this.nowScroll > scrollTop){
        let offsetTopSum = 0;
        for (let i=0;i<=this.activeKey - 1;i++){
          offsetTopSum = offsetTopSum + window.document.querySelector('#'+this.menuList[i].name).offsetHeight;
        }
        if (scrollTop + 50 < offsetTopSum){
          this.activeKey = this.activeKey - 1;
        }
      }
      this.nowScroll = scrollTop;
      // console.log("滚动条",scrollTop,"高度",offsetTopSum)
      // console.log("热门高度",window.document.querySelector('#热门').offsetHeight)
    },
    overLapClick(cond){
      this.$store.commit('updateOverLap',cond);
      this.setAllNum();
      this.numComputedSelect();
    },
    radioClick(){
      console.log("按钮",this.thousandRadio);
    },
    //按位任选按钮文字生成
    createPosAnyChoose(title){
      let textList = ['大','中','小'];
      let textList2 = ['0','1','2'];
      let textList3 = [['双双双','单单单'],['双单单','单双双'],['双单双','单双单']];
      let textList4 = [['小小小','大大大'],['小大大','大小小'],['小大小','大小大']];
      let textList5 = [['合合合','质质质'],['合质质','质合合'],['合质合','质合质']];
      let textList6 = [['双双','单单'],['双单','单双']];
      let textList7 = [['小小','大大'],['小大','大小']];
      let textList8 = [['合合','质质'],['合质','质合']];
      let returnList = [];
      let color = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave === 0 ? 'red' : 'green';
      let nameMap = new Map([
        ['大','b'],
        ['中','m'],
        ['小','s']
      ]);
      let nameMap2 = new Map([
        ['单','s'],
        ['双','d'],
      ]);
      let nameMap3 = new Map([
        ['小','x'],
        ['大','b'],
      ]);
      let nameMap4 = new Map([
        ['合','h'],
        ['质','z'],
      ]);
      switch (title) {
        case '大':{
          let mes = '';
          let name = '';
          for (let i=0;i<textList.length;i++){
            for (let j=0;j<textList.length;j++){
              for (let k=0;k<textList.length;k++){
                mes = '大' + textList[i] + textList[j] + textList[k];
                name = 'b' + nameMap.get(textList[i]) + nameMap.get(textList[j]) + nameMap.get(textList[k]);
                let obj = {
                  mes: mes,
                  name: name,
                  color: color
                }
                returnList.push(obj);
              }
            }
          }
          break;
        }
        case '中':{
          let mes = '';
          let name = '';
          for (let i=0;i<textList.length;i++){
            for (let j=0;j<textList.length;j++){
              for (let k=0;k<textList.length;k++){
                mes = '中' + textList[i] + textList[j] + textList[k];
                name = 'm' + nameMap.get(textList[i]) + nameMap.get(textList[j]) + nameMap.get(textList[k]);
                let obj = {
                  mes: mes,
                  name: name,
                  color: color
                }
                returnList.push(obj);
              }
            }
          }
          break;
        }
        case '小':{
          let mes = '';
          let name = '';
          for (let i=0;i<textList.length;i++){
            for (let j=0;j<textList.length;j++){
              for (let k=0;k<textList.length;k++){
                mes = '小' + textList[i]  + textList[j] + textList[k];
                name = 's' + nameMap.get(textList[i]) + nameMap.get(textList[j]) + nameMap.get(textList[k]);
                let obj = {
                  mes: mes,
                  name: name,
                  color: color
                }
                returnList.push(obj);
              }
            }
          }
          break;
        }
        case '0':{
          let mes = '';
          for (let i=0;i<textList2.length;i++){
            for (let j=0;j<textList2.length;j++){
              for (let k=0;k<textList2.length;k++){
                mes = '0' + textList2[i] + textList2[j] + textList2[k];
                let obj = {
                  mes: mes,
                  name: mes,
                  color: color
                }
                returnList.push(obj);
              }
            }
          }
          break;
        }
        case '1':{
          let mes = '';
          for (let i=0;i<textList2.length;i++){
            for (let j=0;j<textList2.length;j++){
              for (let k=0;k<textList2.length;k++){
                mes = '1' + textList2[i] + textList2[j] + textList2[k];
                let obj = {
                  mes: mes,
                  name: mes,
                  color: color
                }
                returnList.push(obj);
              }
            }
          }
          break;
        }
        case '2':{
          let mes = '';
          for (let i=0;i<textList2.length;i++){
            for (let j=0;j<textList2.length;j++){
              for (let k=0;k<textList2.length;k++){
                mes = '2' + textList2[i] + textList2[j] + textList2[k];
                let obj = {
                  mes: mes,
                  name: mes,
                  color: color
                }
                returnList.push(obj);
              }
            }
          }
          break;
        }
        case '单双（三定）':{
          let putMes = '';
          let name = '';
          for (let i=0;i<textList3.length;i++){
            for (let j=1;j<5;j++){
              switch (j) {
                case 1:{
                  putMes = textList3[i][0] + 'X';
                  name = nameMap2.get(textList3[i][0].toString().charAt(0)) + nameMap2.get(textList3[i][0].toString().charAt(1)) + nameMap2.get(textList3[i][0].toString().charAt(2)) + 'X';
                  let obj = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj);
                  putMes = textList3[i][1] + 'X';
                  name = nameMap2.get(textList3[i][1].toString().charAt(0)) + nameMap2.get(textList3[i][1].toString().charAt(1)) + nameMap2.get(textList3[i][1].toString().charAt(2)) + 'X';
                  let obj2 = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj2);
                  break;
                }
                case 2:{
                  putMes = textList3[i][0].toString().charAt(0) + textList3[i][0].toString().charAt(1) + 'X' + textList3[i][0].toString().charAt(2);
                  name = nameMap2.get(textList3[i][0].toString().charAt(0)) + nameMap2.get(textList3[i][0].toString().charAt(1)) + 'X' + nameMap2.get(textList3[i][0].toString().charAt(2));
                  let obj = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj);
                  putMes = textList3[i][1].toString().charAt(0) + textList3[i][1].toString().charAt(1) + 'X' + textList3[i][1].toString().charAt(2);
                  name = nameMap2.get(textList3[i][1].toString().charAt(0)) + nameMap2.get(textList3[i][1].toString().charAt(1)) + 'X' + nameMap2.get(textList3[i][1].toString().charAt(2));
                  let obj2 = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj2);
                  break;
                }
                case 3:{
                  putMes = textList3[i][0].toString().charAt(0) + 'X' + textList3[i][0].toString().charAt(1) + textList3[i][0].toString().charAt(2);
                  name = nameMap2.get(textList3[i][0].toString().charAt(0)) + 'X' + nameMap2.get(textList3[i][0].toString().charAt(1)) + nameMap2.get(textList3[i][0].toString().charAt(2));
                  let obj = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj);
                  putMes = textList3[i][1].toString().charAt(0) + 'X' + textList3[i][1].toString().charAt(1) + textList3[i][1].toString().charAt(2);
                  name = nameMap2.get(textList3[i][1].toString().charAt(0)) + 'X' + nameMap2.get(textList3[i][1].toString().charAt(1)) + nameMap2.get(textList3[i][1].toString().charAt(2));
                  let obj2 = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj2);
                  break;
                }
                case 4:{
                  putMes = 'X' + textList3[i][0].toString().charAt(0) + textList3[i][0].toString().charAt(1) + textList3[i][0].toString().charAt(2);
                  name = 'X' + nameMap2.get(textList3[i][0].toString().charAt(0)) + nameMap2.get(textList3[i][0].toString().charAt(1)) + nameMap2.get(textList3[i][0].toString().charAt(2));
                  let obj = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj);
                  putMes = 'X' + textList3[i][1].toString().charAt(0) + textList3[i][1].toString().charAt(1) + textList3[i][1].toString().charAt(2);
                  name = 'X' + nameMap2.get(textList3[i][1].toString().charAt(0)) + nameMap2.get(textList3[i][1].toString().charAt(1)) + nameMap2.get(textList3[i][1].toString().charAt(2));
                  let obj2 = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj2);
                  break;
                }
              }
            }
          }
          break;
        }
        case '大小（三定）':{
          let putMes = '';
          let name = '';
          for (let i=0;i<textList4.length;i++){
            for (let j=1;j<5;j++){
              switch (j) {
                case 1:{
                  putMes = textList4[i][0] + 'X';
                  name = nameMap3.get(textList4[i][0].toString().charAt(0)) + nameMap3.get(textList4[i][0].toString().charAt(1)) + nameMap3.get(textList4[i][0].toString().charAt(2)) + 'X';
                  let obj = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj);
                  putMes = textList4[i][1] + 'X';
                  name = nameMap3.get(textList4[i][1].toString().charAt(0)) + nameMap3.get(textList4[i][1].toString().charAt(1)) + nameMap3.get(textList4[i][1].toString().charAt(2)) + 'X';
                  let obj2 = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj2);
                  break;
                }
                case 2:{
                  putMes = textList4[i][0].toString().charAt(0) + textList4[i][0].toString().charAt(1) + 'X' + textList4[i][0].toString().charAt(2);
                  name = nameMap3.get(textList4[i][0].toString().charAt(0)) + nameMap3.get(textList4[i][0].toString().charAt(1)) + 'X' + nameMap3.get(textList4[i][0].toString().charAt(2));
                  let obj = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj);
                  putMes = textList4[i][1].toString().charAt(0) + textList4[i][1].toString().charAt(1) + 'X' + textList4[i][1].toString().charAt(2);
                  name = nameMap3.get(textList4[i][1].toString().charAt(0)) + nameMap3.get(textList4[i][1].toString().charAt(1)) + 'X' + nameMap3.get(textList4[i][1].toString().charAt(2));
                  let obj2 = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj2);
                  break;
                }
                case 3:{
                  putMes = textList4[i][0].toString().charAt(0) + 'X' + textList4[i][0].toString().charAt(1) + textList4[i][0].toString().charAt(2);
                  name = nameMap3.get(textList4[i][0].toString().charAt(0)) + 'X' + nameMap3.get(textList4[i][0].toString().charAt(1)) + nameMap3.get(textList4[i][0].toString().charAt(2));
                  let obj = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj);
                  putMes = textList4[i][1].toString().charAt(0) + 'X' + textList4[i][1].toString().charAt(1) + textList4[i][1].toString().charAt(2);
                  name = nameMap3.get(textList4[i][1].toString().charAt(0)) + 'X' + nameMap3.get(textList4[i][1].toString().charAt(1)) + nameMap3.get(textList4[i][1].toString().charAt(2));
                  let obj2 = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj2);
                  break;
                }
                case 4:{
                  putMes = 'X' + textList4[i][0].toString().charAt(0) + textList4[i][0].toString().charAt(1) + textList4[i][0].toString().charAt(2);
                  name = 'X' + nameMap3.get(textList4[i][0].toString().charAt(0)) + nameMap3.get(textList4[i][0].toString().charAt(1)) + nameMap3.get(textList4[i][0].toString().charAt(2));
                  let obj = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj);
                  putMes = 'X' + textList4[i][1].toString().charAt(0) + textList4[i][1].toString().charAt(1) + textList4[i][1].toString().charAt(2);
                  name = 'X' + nameMap3.get(textList4[i][1].toString().charAt(0)) + nameMap3.get(textList4[i][1].toString().charAt(1)) + nameMap3.get(textList4[i][1].toString().charAt(2));
                  let obj2 = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj2);
                  break;
                }
              }
            }
          }
          break;
        }
        case '质合（三定）':{
          let putMes = '';
          let name = '';
          for (let i=0;i<textList5.length;i++){
            for (let j=1;j<5;j++){
              switch (j) {
                case 1:{
                  putMes = textList5[i][0] + 'X';
                  name = nameMap4.get(textList5[i][0].toString().charAt(0)) + nameMap4.get(textList5[i][0].toString().charAt(1)) + nameMap4.get(textList5[i][0].toString().charAt(2)) + 'X';
                  let obj = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj);
                  putMes = textList5[i][1] + 'X';
                  name = nameMap4.get(textList5[i][1].toString().charAt(0)) + nameMap4.get(textList5[i][1].toString().charAt(1)) + nameMap4.get(textList5[i][1].toString().charAt(2)) + 'X';
                  let obj2 = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj2);
                  break;
                }
                case 2:{
                  putMes = textList5[i][0].toString().charAt(0) + textList5[i][0].toString().charAt(1) + 'X' + textList5[i][0].toString().charAt(2);
                  name = nameMap4.get(textList5[i][0].toString().charAt(0)) + nameMap4.get(textList5[i][0].toString().charAt(1)) + 'X' + nameMap4.get(textList5[i][0].toString().charAt(2));
                  let obj = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj);
                  putMes = textList5[i][1].toString().charAt(0) + textList5[i][1].toString().charAt(1) + 'X' + textList5[i][1].toString().charAt(2);
                  name = nameMap4.get(textList5[i][1].toString().charAt(0)) + nameMap4.get(textList5[i][1].toString().charAt(1)) + 'X' + nameMap4.get(textList5[i][1].toString().charAt(2));
                  let obj2 = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj2);
                  break;
                }
                case 3:{
                  putMes = textList5[i][0].toString().charAt(0) + 'X' + textList5[i][0].toString().charAt(1) + textList5[i][0].toString().charAt(2);
                  name = nameMap4.get(textList5[i][0].toString().charAt(0)) + 'X' + nameMap4.get(textList5[i][0].toString().charAt(1)) + nameMap4.get(textList5[i][0].toString().charAt(2));
                  let obj = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj);
                  putMes = textList5[i][1].toString().charAt(0) + 'X' + textList5[i][1].toString().charAt(1) + textList5[i][1].toString().charAt(2);
                  name = nameMap4.get(textList5[i][1].toString().charAt(0)) + 'X' + nameMap4.get(textList5[i][1].toString().charAt(1)) + nameMap4.get(textList5[i][1].toString().charAt(2));
                  let obj2 = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj2);
                  break;
                }
                case 4:{
                  putMes = 'X' + textList5[i][0].toString().charAt(0) + textList5[i][0].toString().charAt(1) + textList5[i][0].toString().charAt(2);
                  name = 'X' + nameMap4.get(textList5[i][0].toString().charAt(0)) + nameMap4.get(textList5[i][0].toString().charAt(1)) + nameMap4.get(textList5[i][0].toString().charAt(2));
                  let obj = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj);
                  putMes = 'X' + textList5[i][1].toString().charAt(0) + textList5[i][1].toString().charAt(1) + textList5[i][1].toString().charAt(2);
                  name = 'X' + nameMap4.get(textList5[i][1].toString().charAt(0)) + nameMap4.get(textList5[i][1].toString().charAt(1)) + nameMap4.get(textList5[i][1].toString().charAt(2));
                  let obj2 = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj2);
                  break;
                }
              }
            }
          }
          break;
        }
        case '大中小（三定）4':{
          let mes = '';
          let name = '';
          for (let i=0;i<textList.length;i++){
            for (let j=0;j<textList.length;j++){
              for (let k=0;k<textList.length;k++){
                mes = textList[i] + textList[j] + textList[k] + 'X';
                name = nameMap.get(textList[i]) + nameMap.get(textList[j]) + nameMap.get(textList[k]) + 'X';
                let obj = {
                  mes: mes,
                  name: name,
                  color: color
                }
                returnList.push(obj);
              }
            }
          }
          break;
        }
        case '大中小（三定）3':{
          let mes = '';
          let name = '';
          for (let i=0;i<textList.length;i++){
            for (let j=0;j<textList.length;j++){
              for (let k=0;k<textList.length;k++){
                mes = textList[i] + textList[j] + 'X' + textList[k];
                name = nameMap.get(textList[i]) + nameMap.get(textList[j]) + 'X' + nameMap.get(textList[k]);
                let obj = {
                  mes: mes,
                  name: name,
                  color: color
                }
                returnList.push(obj);
              }
            }
          }
          break;
        }
        case '大中小（三定）2':{
          let mes = '';
          let name = '';
          for (let i=0;i<textList.length;i++){
            for (let j=0;j<textList.length;j++){
              for (let k=0;k<textList.length;k++){
                mes = textList[i] + 'X' + textList[j] + textList[k];
                name = nameMap.get(textList[i]) + 'X' + nameMap.get(textList[j]) + nameMap.get(textList[k]);
                let obj = {
                  mes: mes,
                  name: name,
                  color: color
                }
                returnList.push(obj);
              }
            }
          }
          break;
        }
        case '大中小（三定）1':{
          let mes = '';
          let name = '';
          for (let i=0;i<textList.length;i++){
            for (let j=0;j<textList.length;j++){
              for (let k=0;k<textList.length;k++){
                mes = 'X' + textList[i] + textList[j] + textList[k];
                name = 'X' + nameMap.get(textList[i]) + nameMap.get(textList[j]) + nameMap.get(textList[k]);
                let obj = {
                  mes: mes,
                  name: name,
                  color: color
                }
                returnList.push(obj);
              }
            }
          }
          break;
        }
        case '012路（三定）4':{
          let mes = '';
          for (let i=0;i<textList2.length;i++){
            for (let j=0;j<textList2.length;j++){
              for (let k=0;k<textList2.length;k++){
                mes = textList2[i] + textList2[j] + textList2[k] + 'X';
                let obj = {
                  mes: mes,
                  name: mes,
                  color: color
                }
                returnList.push(obj);
              }
            }
          }
          break;
        }
        case '012路（三定）3':{
          let mes = '';
          for (let i=0;i<textList2.length;i++){
            for (let j=0;j<textList2.length;j++){
              for (let k=0;k<textList2.length;k++){
                mes = textList2[i] + textList2[j] + 'X' + textList2[k];
                let obj = {
                  mes: mes,
                  name: mes,
                  color: color
                }
                returnList.push(obj);
              }
            }
          }
          break;
        }
        case '012路（三定）2':{
          let mes = '';
          for (let i=0;i<textList2.length;i++){
            for (let j=0;j<textList2.length;j++){
              for (let k=0;k<textList2.length;k++){
                mes = textList2[i] + 'X' + textList2[j] + textList2[k];
                let obj = {
                  mes: mes,
                  name: mes,
                  color: color
                }
                returnList.push(obj);
              }
            }
          }
          break;
        }
        case '012路（三定）1':{
          let mes = '';
          for (let i=0;i<textList2.length;i++){
            for (let j=0;j<textList2.length;j++){
              for (let k=0;k<textList2.length;k++){
                mes = 'X' + textList2[i] + textList2[j] + textList2[k];
                let obj = {
                  mes: mes,
                  name: mes,
                  color: color
                }
                returnList.push(obj);
              }
            }
          }
          break;
        }
        case '单双（二定）':{
          let putMes = '';
          let name = '';
          for (let i=0;i<textList6.length;i++){
            for (let j=1;j<7;j++){
              switch (j) {
                case 1:{
                  putMes = textList6[i][0] + 'XX';
                  name = nameMap2.get(textList6[i][0].toString().charAt(0)) + nameMap2.get(textList6[i][0].toString().charAt(1)) + 'XX';
                  let obj = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj);
                  putMes = textList6[i][1] + 'XX';
                  name = nameMap2.get(textList6[i][1].toString().charAt(0)) + nameMap2.get(textList6[i][1].toString().charAt(1)) + 'XX';
                  let obj2 = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj2);
                  break;
                }
                case 2:{
                  putMes = textList6[i][0].toString().charAt(0) + 'XX' + textList6[i][0].toString().charAt(1);
                  name = nameMap2.get(textList6[i][0].toString().charAt(0)) + 'XX' + nameMap2.get(textList6[i][0].toString().charAt(1));
                  let obj = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj);
                  putMes = textList6[i][1].toString().charAt(0) + 'XX' + textList6[i][1].toString().charAt(1);
                  name = nameMap2.get(textList6[i][1].toString().charAt(0)) + 'XX' + nameMap2.get(textList6[i][1].toString().charAt(1));
                  let obj2 = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj2);
                  break;
                }
                case 3:{
                  putMes = 'XX' + textList6[i][0].toString().charAt(0) + textList6[i][0].toString().charAt(1)
                  name = 'XX' + nameMap2.get(textList6[i][0].toString().charAt(0)) + nameMap2.get(textList6[i][0].toString().charAt(1));
                  let obj = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj);
                  putMes = 'XX' + textList6[i][1].toString().charAt(0) + textList6[i][1].toString().charAt(1);
                  name = 'XX' + nameMap2.get(textList6[i][1].toString().charAt(0)) + nameMap2.get(textList6[i][1].toString().charAt(1));
                  let obj2 = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj2);
                  break;
                }
                case 4:{
                  putMes = 'X' + textList6[i][0].toString().charAt(0) + 'X' + textList6[i][0].toString().charAt(1);
                  name = 'X' + nameMap2.get(textList6[i][0].toString().charAt(0)) + 'X' + nameMap2.get(textList6[i][0].toString().charAt(1));
                  let obj = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj);
                  putMes = 'X' + textList6[i][1].toString().charAt(0) + 'X' + textList6[i][1].toString().charAt(1);
                  name = 'X' + nameMap2.get(textList6[i][1].toString().charAt(0)) + 'X' + nameMap2.get(textList6[i][1].toString().charAt(1));
                  let obj2 = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj2);
                  break;
                }
                case 5:{
                  putMes = 'X' + textList6[i][0].toString().charAt(0) + textList6[i][0].toString().charAt(1) + 'X';
                  name = 'X' + nameMap2.get(textList6[i][0].toString().charAt(0)) + nameMap2.get(textList6[i][0].toString().charAt(1)) + 'X';
                  let obj = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj);
                  putMes = 'X' + textList6[i][1].toString().charAt(0) + textList6[i][1].toString().charAt(1) + 'X';
                  name = 'X' + nameMap2.get(textList6[i][1].toString().charAt(0)) + nameMap2.get(textList6[i][1].toString().charAt(1)) + 'X';
                  let obj2 = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj2);
                  break;
                }
                case 6:{
                  putMes = textList6[i][0].toString().charAt(0) + 'X' + textList6[i][0].toString().charAt(1) + 'X';
                  name = nameMap2.get(textList6[i][0].toString().charAt(0)) + 'X' + nameMap2.get(textList6[i][0].toString().charAt(1)) + 'X';
                  let obj = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj);
                  putMes = textList6[i][1].toString().charAt(0) + 'X' + textList6[i][1].toString().charAt(1) + 'X';
                  name = nameMap2.get(textList6[i][1].toString().charAt(0)) + 'X' + nameMap2.get(textList6[i][1].toString().charAt(1)) + 'X';
                  let obj2 = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj2);
                  break;
                }
              }
            }
          }
          break;
        }
        case '大小（二定）':{
          let putMes = '';
          let name = '';
          for (let i=0;i<textList7.length;i++){
            for (let j=1;j<7;j++){
              switch (j) {
                case 1:{
                  putMes = textList7[i][0] + 'XX';
                  name = nameMap3.get(textList7[i][0].toString().charAt(0)) + nameMap3.get(textList7[i][0].toString().charAt(1)) + 'XX';
                  let obj = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj);
                  putMes = textList7[i][1] + 'XX';
                  name = nameMap3.get(textList7[i][1].toString().charAt(0)) + nameMap3.get(textList7[i][1].toString().charAt(1)) + 'XX';
                  let obj2 = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj2);
                  break;
                }
                case 2:{
                  putMes = textList7[i][0].toString().charAt(0) + 'XX' + textList7[i][0].toString().charAt(1);
                  name = nameMap3.get(textList7[i][0].toString().charAt(0)) + 'XX' + nameMap3.get(textList7[i][0].toString().charAt(1));
                  let obj = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj);
                  putMes = textList7[i][1].toString().charAt(0) + 'XX' + textList7[i][1].toString().charAt(1);
                  name = nameMap3.get(textList7[i][1].toString().charAt(0)) + 'XX' + nameMap3.get(textList7[i][1].toString().charAt(1));
                  let obj2 = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj2);
                  break;
                }
                case 3:{
                  putMes = 'XX' + textList7[i][0].toString().charAt(0) + textList7[i][0].toString().charAt(1)
                  name = 'XX' + nameMap3.get(textList7[i][0].toString().charAt(0)) + nameMap3.get(textList7[i][0].toString().charAt(1));
                  let obj = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj);
                  putMes = 'XX' + textList7[i][1].toString().charAt(0) + textList7[i][1].toString().charAt(1);
                  name = 'XX' + nameMap3.get(textList7[i][1].toString().charAt(0)) + nameMap3.get(textList7[i][1].toString().charAt(1));
                  let obj2 = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj2);
                  break;
                }
                case 4:{
                  putMes = 'X' + textList7[i][0].toString().charAt(0) + 'X' + textList7[i][0].toString().charAt(1);
                  name = 'X' + nameMap3.get(textList7[i][0].toString().charAt(0)) + 'X' + nameMap3.get(textList7[i][0].toString().charAt(1));
                  let obj = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj);
                  putMes = 'X' + textList7[i][1].toString().charAt(0) + 'X' + textList7[i][1].toString().charAt(1);
                  name = 'X' + nameMap3.get(textList7[i][1].toString().charAt(0)) + 'X' + nameMap3.get(textList7[i][1].toString().charAt(1));
                  let obj2 = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj2);
                  break;
                }
                case 5:{
                  putMes = 'X' + textList7[i][0].toString().charAt(0) + textList7[i][0].toString().charAt(1) + 'X';
                  name = 'X' + nameMap3.get(textList7[i][0].toString().charAt(0)) + nameMap3.get(textList7[i][0].toString().charAt(1)) + 'X';
                  let obj = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj);
                  putMes = 'X' + textList7[i][1].toString().charAt(0) + textList7[i][1].toString().charAt(1) + 'X';
                  name = 'X' + nameMap3.get(textList7[i][1].toString().charAt(0)) + nameMap3.get(textList7[i][1].toString().charAt(1)) + 'X';
                  let obj2 = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj2);
                  break;
                }
                case 6:{
                  putMes = textList7[i][0].toString().charAt(0) + 'X' + textList7[i][0].toString().charAt(1) + 'X';
                  name = nameMap3.get(textList7[i][0].toString().charAt(0)) + 'X' + nameMap3.get(textList7[i][0].toString().charAt(1)) + 'X';
                  let obj = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj);
                  putMes = textList7[i][1].toString().charAt(0) + 'X' + textList7[i][1].toString().charAt(1) + 'X';
                  name = nameMap3.get(textList7[i][1].toString().charAt(0)) + 'X' + nameMap3.get(textList7[i][1].toString().charAt(1)) + 'X';
                  let obj2 = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj2);
                  break;
                }
              }
            }
          }
          break;
        }
        case '质合（二定）':{
          let putMes = '';
          let name = '';
          for (let i=0;i<textList8.length;i++){
            for (let j=1;j<7;j++){
              switch (j) {
                case 1:{
                  putMes = textList8[i][0] + 'XX';
                  name = nameMap4.get(textList8[i][0].toString().charAt(0)) + nameMap4.get(textList8[i][0].toString().charAt(1)) + 'XX';
                  let obj = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj);
                  putMes = textList8[i][1] + 'XX';
                  name = nameMap4.get(textList8[i][1].toString().charAt(0)) + nameMap4.get(textList8[i][1].toString().charAt(1)) + 'XX';
                  let obj2 = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj2);
                  break;
                }
                case 2:{
                  putMes = textList8[i][0].toString().charAt(0) + 'XX' + textList8[i][0].toString().charAt(1);
                  name = nameMap4.get(textList8[i][0].toString().charAt(0)) + 'XX' + nameMap4.get(textList8[i][0].toString().charAt(1));
                  let obj = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj);
                  putMes = textList8[i][1].toString().charAt(0) + 'XX' + textList8[i][1].toString().charAt(1);
                  name = nameMap4.get(textList8[i][1].toString().charAt(0)) + 'XX' + nameMap4.get(textList8[i][1].toString().charAt(1));
                  let obj2 = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj2);
                  break;
                }
                case 3:{
                  putMes = 'XX' + textList8[i][0].toString().charAt(0) + textList8[i][0].toString().charAt(1)
                  name = 'XX' + nameMap4.get(textList8[i][0].toString().charAt(0)) + nameMap4.get(textList8[i][0].toString().charAt(1));
                  let obj = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj);
                  putMes = 'XX' + textList8[i][1].toString().charAt(0) + textList8[i][1].toString().charAt(1);
                  name = 'XX' + nameMap4.get(textList8[i][1].toString().charAt(0)) + nameMap4.get(textList8[i][1].toString().charAt(1));
                  let obj2 = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj2);
                  break;
                }
                case 4:{
                  putMes = 'X' + textList8[i][0].toString().charAt(0) + 'X' + textList8[i][0].toString().charAt(1);
                  name = 'X' + nameMap4.get(textList8[i][0].toString().charAt(0)) + 'X' + nameMap4.get(textList8[i][0].toString().charAt(1));
                  let obj = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj);
                  putMes = 'X' + textList8[i][1].toString().charAt(0) + 'X' + textList8[i][1].toString().charAt(1);
                  name = 'X' + nameMap4.get(textList8[i][1].toString().charAt(0)) + 'X' + nameMap4.get(textList8[i][1].toString().charAt(1));
                  let obj2 = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj2);
                  break;
                }
                case 5:{
                  putMes = 'X' + textList8[i][0].toString().charAt(0) + textList8[i][0].toString().charAt(1) + 'X';
                  name = 'X' + nameMap4.get(textList8[i][0].toString().charAt(0)) + nameMap4.get(textList8[i][0].toString().charAt(1)) + 'X';
                  let obj = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj);
                  putMes = 'X' + textList8[i][1].toString().charAt(0) + textList8[i][1].toString().charAt(1) + 'X';
                  name = 'X' + nameMap4.get(textList8[i][1].toString().charAt(0)) + nameMap4.get(textList8[i][1].toString().charAt(1)) + 'X';
                  let obj2 = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj2);
                  break;
                }
                case 6:{
                  putMes = textList8[i][0].toString().charAt(0) + 'X' + textList8[i][0].toString().charAt(1) + 'X';
                  name = nameMap4.get(textList8[i][0].toString().charAt(0)) + 'X' + nameMap4.get(textList8[i][0].toString().charAt(1)) + 'X';
                  let obj = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj);
                  putMes = textList8[i][1].toString().charAt(0) + 'X' + textList8[i][1].toString().charAt(1) + 'X';
                  name = nameMap4.get(textList8[i][1].toString().charAt(0)) + 'X' + nameMap4.get(textList8[i][1].toString().charAt(1)) + 'X';
                  let obj2 = {
                    mes: putMes,
                    name: name,
                    color: color,
                  }
                  returnList.push(obj2);
                  break;
                }
              }
            }
          }
          break;
        }
        case '大中小（二定）6':{
          let mes = '';
          let name = '';
          for (let i=0;i<textList.length;i++){
            for (let j=0;j<textList.length;j++){
              mes = textList[i] + textList[j] + 'XX';
              name = nameMap.get(textList[i]) + nameMap.get(textList[j]) + 'XX';
              let obj = {
                mes: mes,
                name: name,
                color: color
              }
              returnList.push(obj);
            }
          }
          break;
        }
        case '大中小（二定）5':{
          let mes = '';
          let name = '';
          for (let i=0;i<textList.length;i++){
            for (let j=0;j<textList.length;j++){
              mes = textList[i] + 'X' + textList[j] + 'X';
              name = nameMap.get(textList[i]) + 'X' + nameMap.get(textList[j]) + 'X';
              let obj = {
                mes: mes,
                name: name,
                color: color
              }
              returnList.push(obj);
            }
          }
          break;
        }
        case '大中小（二定）4':{
          let mes = '';
          let name = '';
          for (let i=0;i<textList.length;i++){
            for (let j=0;j<textList.length;j++){
              mes = textList[i] + 'XX' + textList[j];
              name = nameMap.get(textList[i]) + 'XX' + nameMap.get(textList[j]);
              let obj = {
                mes: mes,
                name: name,
                color: color
              }
              returnList.push(obj);
            }
          }
          break;
        }
        case '大中小（二定）3':{
          let mes = '';
          let name = '';
          for (let i=0;i<textList.length;i++){
            for (let j=0;j<textList.length;j++){
              mes = 'X' + textList[i] + textList[j] + 'X';
              name = 'X' + nameMap.get(textList[i]) + nameMap.get(textList[j]) + 'X';
              let obj = {
                mes: mes,
                name: name,
                color: color
              }
              returnList.push(obj);
            }
          }
          break;
        }
        case '大中小（二定）2':{
          let mes = '';
          let name = '';
          for (let i=0;i<textList.length;i++){
            for (let j=0;j<textList.length;j++){
              mes = 'X' + textList[i] + 'X' + textList[j];
              name = 'X' + nameMap.get(textList[i]) + 'X' + nameMap.get(textList[j]);
              let obj = {
                mes: mes,
                name: name,
                color: color
              }
              returnList.push(obj);
            }
          }
          break;
        }
        case '大中小（二定）1':{
          let mes = '';
          let name = '';
          for (let i=0;i<textList.length;i++){
            for (let j=0;j<textList.length;j++){
              mes = 'XX' + textList[i] + textList[j];
              name = 'XX' + nameMap.get(textList[i]) + nameMap.get(textList[j]);
              let obj = {
                mes: mes,
                name: name,
                color: color
              }
              returnList.push(obj);
            }
          }
          break;
        }
        case '012路（二定）6':{
          let mes = '';
          for (let i=0;i<textList2.length;i++){
            for (let j=0;j<textList2.length;j++){
              mes = textList2[i] + textList2[j] + 'XX';
              let obj = {
                mes: mes,
                name: mes,
                color: color
              }
              returnList.push(obj);
            }
          }
          break;
        }
        case '012路（二定）5':{
          let mes = '';
          for (let i=0;i<textList2.length;i++){
            for (let j=0;j<textList2.length;j++){
              mes = textList2[i] + 'X' + textList2[j] + 'X';
              let obj = {
                mes: mes,
                name: mes,
                color: color
              }
              returnList.push(obj);
            }
          }
          break;
        }
        case '012路（二定）4':{
          let mes = '';
          for (let i=0;i<textList2.length;i++){
            for (let j=0;j<textList2.length;j++){
              mes = textList2[i] + 'XX' + textList2[j];
              let obj = {
                mes: mes,
                name: mes,
                color: color
              }
              returnList.push(obj);
            }
          }
          break;
        }
        case '012路（二定）3':{
          let mes = '';
          for (let i=0;i<textList2.length;i++){
            for (let j=0;j<textList2.length;j++){
              mes = 'X' + textList2[i] + textList2[j] + 'X';
              let obj = {
                mes: mes,
                name: mes,
                color: color
              }
              returnList.push(obj);
            }
          }
          break;
        }
        case '012路（二定）2':{
          let mes = '';
          for (let i=0;i<textList2.length;i++){
            for (let j=0;j<textList2.length;j++){
              mes = 'X' + textList2[i] + 'X' + textList2[j];
              let obj = {
                mes: mes,
                name: mes,
                color: color
              }
              returnList.push(obj);
            }
          }
          break;
        }
        case '012路（二定）1':{
          let mes = '';
          for (let i=0;i<textList2.length;i++){
            for (let j=0;j<textList2.length;j++){
              mes = 'XX' + textList2[i] + textList2[j];
              let obj = {
                mes: mes,
                name: mes,
                color: color
              }
              returnList.push(obj);
            }
          }
          break;
        }
      }
      return returnList;
    },
    //已经开发好的功能
    returnStyle(name){
      let menu = ['合值（合分）','排除码（死数）','必出码（铁率）','连号','重数','中肚有数','头尾有数','极值','跨度','合值+跨度','合值-跨度','余数和','连号（兄弟号）','和值','两数乘积','三数乘积','四数乘积','对数','有数','两数合','三数合','单双（奇偶）','大小','质合','大中小','012路','定位置','入数个数','AC值','位积','反位积','配号码','凹凸升降','全转（全倒）','减式','开奖历史']
      if (menu.includes(name)){
        return 'returnStyle';
      }
    },
    //大底数据重新赋值
    setAllNum(){
      switch (this.$store.state.payWay.payType) {
        case 1:
          this.$store.commit('numComputed',this.deepClone(this.$store.state.payWay.fourDecNum));
          break;
        case 2:
          this.$store.commit('numComputed',this.deepClone(this.$store.state.payWay.fourShowNum));
          break;
        case 3:
          this.$store.commit('numComputed',this.deepClone(this.$store.state.payWay.threeDecNum));
          break;
        case 4:
          this.$store.commit('numComputed',this.deepClone(this.$store.state.payWay.threeShowNum));
          break;
        case 5:
          this.$store.commit('numComputed',this.deepClone(this.$store.state.payWay.twoDecNum));
          break;
        case 6:
          this.$store.commit('numComputed',this.deepClone(this.$store.state.payWay.twoShowNum));
          break;
      }
    },
    deepClone(arr){
      return JSON.parse(JSON.stringify(arr));
    },
    //条件添加和保留
    condSaveOrExclude(name,btnName,index,isTrue){
      switch (this.$store.state.payWay.payType){
        case 1:
          this.condComput(name,btnName,index,isTrue,this.$store.state.payWay.conditionFourDec)
          break;
        case 2:
          this.condComput(name,btnName,index,isTrue,this.$store.state.payWay.conditionFourShow)
          break;
        case 3:
          this.condComput(name,btnName,index,isTrue,this.$store.state.payWay.conditionThreeDec)
          break;
        case 4:
          this.condComput(name,btnName,index,isTrue,this.$store.state.payWay.conditionThreeShow)
          break;
        case 5:
          this.condComput(name,btnName,index,isTrue,this.$store.state.payWay.conditionTwoDec)
          break;
        case 6:
          this.condComput(name,btnName,index,isTrue,this.$store.state.payWay.conditionTwoShow)
          break;
      }
      this.setAllNum();
      this.numComputedSelect();
    },
    condComput(name,btnName,index,isTrue,list){
      if (isTrue === 1){
        let obj = list.find(item =>(item.name === name)).btn[index];
        obj.isTrue = 2;
        this.$store.commit('updateCondition3', {title:name, index:index, obj:this.deepClone(obj)});
      }else {
        let obj = list.find(item =>(item.name === name)).btn[index];
        obj.isTrue = 1;
        this.$store.commit('updateCondition3', {title:name, index:index, obj:this.deepClone(obj)});
      }
    },
    //展示条件框
    showCondition(){
      if (this.conditionShow){
        this.closeCond();
      }else {
        this.conditionShow = true;
      }
    },
    //删除条件框
    closeCond(){
      this.conditionShow = false;
      this.clickCancel();
    },
    //删除条件按钮
    deleteCond(title,btnName,index){
      this.$store.commit('deletedCondition', {title:title,index:index});
      this.setAllNum();
      this.numComputedSelect();
      this.$forceUpdate();
    },
    //清除所有条件按钮
    clearCondition(){
      this.$store.commit('clearCondition');
      this.addOrUpdate = 0;
      this.updateIndex = 0;
      this.setAllNum();
      this.numComputedSelect();
      this.conditionShow = false;
    },
    updateCondition(){
      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isTrue = 1;
      this.condList.find(item =>(item.name === this.selectTitle1)).isTrue = 1;
      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).mes = this.condMesText;
      switch (this.selectTitle1) {
        case "有数":{
          let condCheckBox = false;
          let condNum = false;
          let numList = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val;
          if (this.selectTitle2 === '3个位置' || this.selectTitle2 === '2个位置'){
            if (this.haveNumber.length !== 0){
              condCheckBox = true;
            }
            for (let i=0;i<numList.length;i++){
              if (numList[i]){
                condNum = true;
                break;
              }
            }
          }
          if (this.selectTitle2 === '4个位置'){
            condCheckBox = true;
            for (let i=0;i<numList.length;i++){
              if (numList[i]){
                condNum = true;
                break;
              }
            }
          }
          if (!condNum){
            this.$toast("请选择号码");
            return;
          }
          if (!condCheckBox){
            this.$toast("请选择方案");
            return;
          }
        }

      }
      let obj = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2));
      let objTitle = this.condList.find(item =>(item.name === this.selectTitle1));
      let pushObj = {
        name: objTitle.name,
        isTrue: objTitle.isTrue,
        btn: [obj]
      }
      //let index = this.condList.find(item =>(item.name === this.selectTitle1)).btn.map(item => item).indexOf(obj)
      //this.$store.commit('updateCondition2', {title:this.selectTitle1, index:index, obj:this.deepClone(obj)});
      if (this.addOrUpdate === 0){
        this.$store.commit('addCondition', {title:this.selectTitle1,obj:this.deepClone(pushObj)});
      }else {
        this.$store.commit('updateCondition3', {title:this.selectTitle1,index:this.updateIndex,obj:this.deepClone(obj)});
      }
      this.selectShow = false;
      this.setAllNum();
      this.numComputedSelect();
      this.clickCancel();
    },
    toShowNumber(){
      let par = this.$store.state.payWay.payType;
      this.$router.push({name:'showNumIndex',params:{key:par}});
    },
    clickScroll(name){
      document.getElementById(name).scrollIntoView();
    },
    //开奖历史数据获取
    async getHistoryData(){
      if (this.selectTitle2 === '七星彩'){
        const res = await this.$HTTP.post(this, 'API_LOTTERY_LISTS_SPACE', { lotteryKind: 7, pageSize: '100', sortBy: "lottery_date desc" }, {}, true);
        this.historyData = res.data || [];
      }
      if (this.selectTitle2 === '排列五'){
        const res = await this.$HTTP.post(this, 'API_LOTTERY_LISTS_SPACE', { lotteryKind: 5, pageSize: '100', sortBy: "lottery_date desc" }, {}, true);
        this.historyData = res.data || [];
      }
    },
    condSelect(titleName,btnName,addOrUpdate,index){
      this.selectTitle1 = titleName;
      this.selectTitle2 = btnName;
      if (this.selectTitle1 === '开奖历史'){
        this.getHistoryData();
      }
      if (addOrUpdate === 'update'){
        this.addOrUpdate = 1;
        let obj = {};
        switch (this.$store.state.payWay.payType) {
          case 1:
            obj = this.deepClone(this.$store.state.payWay.conditionFourDec.find(item =>(item.name === titleName)).btn[index]);
            break;
          case 2:
            obj = this.deepClone(this.$store.state.payWay.conditionFourShow.find(item =>(item.name === titleName)).btn[index]);
            break;
          case 3:
            obj = this.deepClone(this.$store.state.payWay.conditionThreeDec.find(item =>(item.name === titleName)).btn[index]);
            break;
          case 4:
            obj = this.deepClone(this.$store.state.payWay.conditionThreeShow.find(item =>(item.name === titleName)).btn[index]);
            break;
          case 5:
            obj = this.deepClone(this.$store.state.payWay.conditionTwoDec.find(item =>(item.name === titleName)).btn[index]);
            break;
          case 6:
            obj = this.deepClone(this.$store.state.payWay.conditionTwoShow.find(item =>(item.name === titleName)).btn[index]);
            break;
        }
        switch (titleName) {
          case '开奖历史':
            switch (obj.val) {
              case 't10':
                this.timeNumberStart = this.historyData[9].periodText.toString();
                this.timeNumberEnd = this.historyData[0].periodText.toString();
                break;
              case 't20':
                this.timeNumberStart = this.historyData[19].periodText.toString();
                this.timeNumberEnd = this.historyData[0].periodText.toString();
                break;
              case 't50':
                this.timeNumberStart = this.historyData[49].periodText.toString();
                this.timeNumberEnd = this.historyData[0].periodText.toString();
                break;
              case 't100':
                this.timeNumberStart = this.historyData[99].periodText.toString();
                this.timeNumberEnd = this.historyData[0].periodText.toString();
                break;
            }
            break;
          case '减式':
            this.add = obj.add;
            this.dec = obj.dec;
            break;
          case '对数':
            this.coupleNumber = obj.val;
            break;
          case '连号（兄弟号）':
            this.continueNumber = obj.val;
            break;
          case '凹凸升降':
            this.aoTu = obj.val;
            break;
          case '重数':
            this.repeatNumber = obj.val;
            break;
          case '两数乘积':
            this.multiplyNumber = obj.multiplyWay;
            break;
          case '三数乘积':
            this.multiplyNumber = obj.multiplyWay;
            break;
          case '有数':
            if (btnName === '3个位置' || btnName === '2个位置'){
              this.haveNumber = obj.haveNumber;
            }
            break;
          case '两数合':
            if (btnName === '定位置'){
              this.numberSum = obj.numberSum;
            }
            break;
          case '三数合':
            if (btnName === '定位置'){
              this.numberSum = obj.numberSum;
            }
            break;
          case '单双（奇偶）':
            if (btnName === '按位快选'){
              this.thousandRadio = obj.val[0];
              this.hundredRadio = obj.val[1];
              this.tenRadio = obj.val[2];
              this.pieceRadio = obj.val[3];
            }else if (btnName === '按位任选'){
              this.posAnyChooseDS = obj.val;
            }else if (btnName === '出现比例'){
              this.appearRatio = obj.val;
            }else if (btnName === '断组'){
              this.breakGroup = obj.val;
            }
            break;
          case '大小':
            if (btnName === '按位快选'){
              this.thousandRadio = obj.val[0];
              this.hundredRadio = obj.val[1];
              this.tenRadio = obj.val[2];
              this.pieceRadio = obj.val[3];
            }else if (btnName === '按位任选'){
              this.posAnyChooseBS = obj.val;
            }else if (btnName === '出现比例'){
              this.appearRatio = obj.val;
            }else if (btnName === '断组'){
              this.breakGroup = obj.val;
            }
            break;
          case '质合':
            if (btnName === '按位快选'){
              this.thousandRadio = obj.val[0];
              this.hundredRadio = obj.val[1];
              this.tenRadio = obj.val[2];
              this.pieceRadio = obj.val[3];
            }else if (btnName === '按位任选'){
              this.posAnyChoosePN = obj.val;
            }else if (btnName === '出现比例'){
              this.appearRatio = obj.val;
            }else if (btnName === '断组'){
              this.breakGroup = obj.val;
            }
            break;
          case '大中小':
            if (btnName === '按位快选'){
              this.thousandRadio = obj.val[0];
              this.hundredRadio = obj.val[1];
              this.tenRadio = obj.val[2];
              this.pieceRadio = obj.val[3];
            }else if (btnName === '按位任选'){
              this.posAnyChooseBMS = obj.val;
            }else if (btnName === '出现比例'){
              this.appearRatio = obj.val;
            }else if (btnName === '断组'){
              this.breakGroup = obj.val;
            }
            break;
          case '012路':
            if (btnName === '按位快选'){
              this.thousandRadio = obj.val[0];
              this.hundredRadio = obj.val[1];
              this.tenRadio = obj.val[2];
              this.pieceRadio = obj.val[3];
            }else if (btnName === '按位任选'){
              this.posAnyChoose012 = obj.val;
            }else if (btnName === '出现比例'){
              this.appearRatio = obj.val;
            }else if (btnName === '断组'){
              this.breakGroup = obj.val;
            }
            break;
          case '定位置':
            if (btnName === '批量选码'){
              this.thousandDanShuangRadio = obj.thousandRadio[0];
              this.thousandDaXiaoRadio = obj.thousandRadio[1];
              this.thousand012Radio = obj.thousandRadio[2];

              this.hundredDanShuangRadio = obj.hundredRadio[0];
              this.hundredDaXiaoRadio = obj.hundredRadio[1];
              this.hundred012Radio = obj.hundredRadio[2];

              this.tenDanShuangRadio = obj.tenRadio[0];
              this.tenDaXiaoRadio = obj.tenRadio[1];
              this.ten012Radio = obj.tenRadio[2];

              this.pieceDanShuangRadio = obj.pieceRadio[0];
              this.pieceDaXiaoRadio = obj.pieceRadio[1];
              this.piece012Radio = obj.pieceRadio[2];
            }
            if (btnName === '乘号位置'){
              this.positionX = obj.val;
            }
            break;
          case '入数个数':
            this.inNumber = obj.val;
            break;
        }
        let btnIndex =  this.condList.find(item =>(item.name === titleName)).btn.indexOf(this.condList.find(item =>(item.name === titleName)).btn.find(item => (item.name === btnName)));
        this.condList.find(item =>(item.name === titleName)).btn.splice(btnIndex,1,obj);
        this.updateIndex = index;
        this.condMesText = obj.mes;
      }
      this.selectShow = true;
      if (addOrUpdate === 'update'){
        if (['合值（合分）','排除码（死数）','必出码（铁率）','中肚有数','头尾有数','极值','跨度','合值+跨度','合值-跨度','余数和','和值','两数乘积','三数乘积','四数乘积','两数合','三数合','AC值','反位积','位积','减式'].includes(titleName)){
          this.$nextTick(() => {
            this.reviseStyle();
          })
        }
        if (['自由选码'].includes(btnName)){
          this.$nextTick(() => {
            this.reviseStyle('isFreeCode');
          })
        }
        if (['乘号位置'].includes(btnName)){
          this.disablePositionX();
        }
        if (['减式'].includes(titleName)){
          this.$nextTick(() => {
            this.decWayStyle();
          })
        }
        if (['开奖历史'].includes(titleName)){
          this.$nextTick(() => {
            this.historySelectStyle();
          })
        }
      }
    },
    //取消按钮
    clickCancel(){
      this.clearMethod();
      this.selectTitle1 = '';
      this.selectTitle2 = '';
      this.selectShow = false;
      this.addOrUpdate = 0;
    },
    //数字按钮样式修改
    reviseStyle(isFreeCode){
      let noCheck = [];
      if (isFreeCode !== undefined){
        switch (this.$store.state.payWay.payType) {
          case 3:{
            let isCheckList = [];
            let val = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val;
            let val1 = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val1;
            let val2 = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val2;
            let val3 = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val3;
            for (let i=0;i<val.length;i++){
              if (val[i]){
                isCheckList.push(1);
                break;
              }
            }
            for (let i=0;i<val1.length;i++){
              if (val1[i]){
                isCheckList.push(2);
                break;
              }
            }
            for (let i=0;i<val2.length;i++){
              if (val2[i]){
                isCheckList.push(3);
                break;
              }
            }
            for (let i=0;i<val3.length;i++){
              if (val3[i]){
                isCheckList.push(4);
                break;
              }
            }
            if (isCheckList.length<3){
              break;
            } else {
              let x = [1,2,3,4,].filter((item) =>{
                if (!isCheckList.includes(item)){
                  return item;
                }
              })
              noCheck = x;
            }
            break;
          }
          case 5:{
            let isCheckList = [];
            let val = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val;
            let val1 = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val1;
            let val2 = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val2;
            let val3 = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val3;
            for (let i=0;i<val.length;i++){
              if (val[i]){
                isCheckList.push(1);
                break;
              }
            }
            for (let i=0;i<val1.length;i++){
              if (val1[i]){
                isCheckList.push(2);
                break;
              }
            }
            for (let i=0;i<val2.length;i++){
              if (val2[i]){
                isCheckList.push(3);
                break;
              }
            }
            for (let i=0;i<val3.length;i++){
              if (val3[i]){
                isCheckList.push(4);
                break;
              }
            }
            if (isCheckList.length<2){
              break;
            } else {
              let x = [1,2,3,4,].filter((item) =>{
                if (!isCheckList.includes(item)){
                  return item;
                }
              })
              noCheck = x;
            }
            break;
          }
        }
      }
      let check = this.$refs.check;
      let x = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave;
      if (x === 1){
        let list = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val;
        for (let i=0;i<list.length;i++){
          if (list[i]){
            check[i].style.backgroundColor = "green";
            check[i].style.color = "white";
            check[i].style.borderColor = 'green';
            check[i].style.pointerEvents = 'auto';
          }else {
            check[i].style.backgroundColor = 'white';
            check[i].style.color = "black";
            check[i].style.borderColor = 'gray';
            check[i].style.pointerEvents = 'auto';
          }
        }
      }else {
        let list = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val;
        for (let i=0;i<list.length;i++){
          if (list[i]){
            check[i].style.backgroundColor = "red";
            check[i].style.color = "white";
            check[i].style.borderColor = 'red';
            check[i].style.pointerEvents = 'auto';
          }else {
            check[i].style.backgroundColor = 'white';
            check[i].style.color = "black";
            check[i].style.borderColor = 'gray';
            check[i].style.pointerEvents = 'auto';
          }
        }
      }
      if (isFreeCode !== undefined){
        let check1 = this.$refs.check1;
        let x1 = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave;
        if (x1 === 1){
          let list = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val1;
          for (let i=0;i<list.length;i++){
            if (list[i]){
              check1[i].style.backgroundColor = "green";
              check1[i].style.color = "white";
              check1[i].style.borderColor = 'green';
              check1[i].style.pointerEvents = 'auto';
            }else {
              check1[i].style.backgroundColor = 'white';
              check1[i].style.color = "black";
              check1[i].style.borderColor = 'gray';
              check1[i].style.pointerEvents = 'auto';
            }
          }
        }else {
          let list = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val1;
          for (let i=0;i<list.length;i++){
            if (list[i]){
              check1[i].style.backgroundColor = "red";
              check1[i].style.color = "white";
              check1[i].style.borderColor = 'red';
              check1[i].style.pointerEvents = 'auto';
            }else {
              check1[i].style.backgroundColor = 'white';
              check1[i].style.color = "black";
              check1[i].style.borderColor = 'gray';
              check1[i].style.pointerEvents = 'auto';
            }
          }
        }

        let check2 = this.$refs.check2;
        let x2 = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave;
        if (x2 === 1){
          let list = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val2;
          for (let i=0;i<list.length;i++){
            if (list[i]){
              check2[i].style.backgroundColor = "green";
              check2[i].style.color = "white";
              check2[i].style.borderColor = 'green';
              check2[i].style.pointerEvents = 'auto';
            }else {
              check2[i].style.backgroundColor = 'white';
              check2[i].style.color = "black";
              check2[i].style.borderColor = 'gray';
              check2[i].style.pointerEvents = 'auto';
            }
          }
        }else {
          let list = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val2;
          for (let i=0;i<list.length;i++){
            if (list[i]){
              check2[i].style.backgroundColor = "red";
              check2[i].style.color = "white";
              check2[i].style.borderColor = 'red';
              check2[i].style.pointerEvents = 'auto';
            }else {
              check2[i].style.backgroundColor = 'white';
              check2[i].style.color = "black";
              check2[i].style.borderColor = 'gray';
              check2[i].style.pointerEvents = 'auto';
            }
          }
        }

        let check3 = this.$refs.check3;
        let x3 = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave;
        if (x3 === 1){
          let list = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val3;
          for (let i=0;i<list.length;i++){
            if (list[i]){
              check3[i].style.backgroundColor = "green";
              check3[i].style.color = "white";
              check3[i].style.borderColor = 'green';
              check3[i].style.pointerEvents = 'auto';
            }else {
              check3[i].style.backgroundColor = 'white';
              check3[i].style.color = "black";
              check3[i].style.borderColor = 'gray';
              check3[i].style.pointerEvents = 'auto';
            }
          }
        }else {
          let list = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val3;
          for (let i=0;i<list.length;i++){
            if (list[i]){
              check3[i].style.backgroundColor = "red";
              check3[i].style.color = "white";
              check3[i].style.borderColor = 'red';
              check3[i].style.pointerEvents = 'auto';
            }else {
              check3[i].style.backgroundColor = 'white';
              check3[i].style.color = "black";
              check3[i].style.borderColor = 'gray';
              check3[i].style.pointerEvents = 'auto';
            }
          }
        }
        if (noCheck.length !== 0){
          let val = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val;
          let val1 = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val1;
          let val2 = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val2;
          let val3 = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val3;
          noCheck.forEach((ite) =>{
            switch (ite) {
              case 1: {
                let check = this.$refs.check;
                for (let i=0;i<val.length;i++){
                  check[i].style.backgroundColor = 'white';
                  check[i].style.color = "gray";
                  check[i].style.borderColor = 'gray';
                  check[i].style.pointerEvents = 'none';
                }
                break;
              }
              case 2: {
                let check1 = this.$refs.check1;
                for (let i=0;i<val1.length;i++){
                  check1[i].style.backgroundColor = 'white';
                  check1[i].style.color = "gray";
                  check1[i].style.borderColor = 'gray';
                  check1[i].style.pointerEvents = 'none';
                }
                break;
              }
              case 3: {
                let check2 = this.$refs.check2;
                for (let i=0;i<val2.length;i++){
                  check2[i].style.backgroundColor = 'white';
                  check2[i].style.color = "gray";
                  check2[i].style.borderColor = 'gray';
                  check2[i].style.pointerEvents = 'none';
                }
                break;
              }
              case 4: {
                let check3 = this.$refs.check3;
                for (let i=0;i<val3.length;i++){
                  check3[i].style.backgroundColor = 'white';
                  check3[i].style.color = "gray";
                  check3[i].style.borderColor = 'gray';
                  check3[i].style.pointerEvents = 'none';
                }
                break;
              }
            }
          })
        }
      }
    },
    //减式按钮样式修改
    decWayStyle(){
      let addA = this.$refs.addA;
      let addB = this.$refs.addB;
      let addC = this.$refs.addC;
      let addD = this.$refs.addD;
      let decA = this.$refs.decA;
      let decB = this.$refs.decB;
      let decC = this.$refs.decC;
      let decD = this.$refs.decD;
      let x = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave;
      let addList = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).add;
      let decList = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).dec;
      if (x === 1){
        if (addList.includes('A')){
          addA.style.backgroundColor = "green";
          addA.style.color = "white";
        }else {
          addA.style.backgroundColor = "white";
          addA.style.color = "black";
        }
        if (addList.includes('B')){
          addB.style.backgroundColor = "green";
          addB.style.color = "white";
        }else {
          addB.style.backgroundColor = "white";
          addB.style.color = "black";
        }
        if (addList.includes('C')){
          addC.style.backgroundColor = "green";
          addC.style.color = "white";
        }else {
          addC.style.backgroundColor = "white";
          addC.style.color = "black";
        }
        if (addList.includes('D')){
          addD.style.backgroundColor = "green";
          addD.style.color = "white";
        }else {
          addD.style.backgroundColor = "white";
          addD.style.color = "black";
        }

        if (decList.includes('A')){
          decA.style.backgroundColor = "green";
          decA.style.color = "white";
        }else {
          decA.style.backgroundColor = "white";
          decA.style.color = "black";
        }
        if (decList.includes('B')){
          decB.style.backgroundColor = "green";
          decB.style.color = "white"
        }else {
          decB.style.backgroundColor = "white";
          decB.style.color = "black";
        }
        if (decList.includes('C')){
          decC.style.backgroundColor = "green";
          decC.style.color = "white";
        }else {
          decC.style.backgroundColor = "white";
          decC.style.color = "black";
        }
        if (decList.includes('D')){
          decD.style.backgroundColor = "green";
          decD.style.color = "white";
        }else {
          decD.style.backgroundColor = "white";
          decD.style.color = "black";
        }
      }else {
        if (addList.includes('A')){
          addA.style.backgroundColor = "red";
          addA.style.color = "white";
        }else {
          addA.style.backgroundColor = "white";
          addA.style.color = "black";
        }
        if (addList.includes('B')){
          addB.style.backgroundColor = "red";
          addB.style.color = "white";
        }else {
          addB.style.backgroundColor = "white";
          addB.style.color = "black";
        }
        if (addList.includes('C')){
          addC.style.backgroundColor = "red";
          addC.style.color = "white";
        }else {
          addC.style.backgroundColor = "white";
          addC.style.color = "black";
        }
        if (addList.includes('D')){
          addD.style.backgroundColor = "red";
          addD.style.color = "white";
        }else {
          addD.style.backgroundColor = "white";
          addD.style.color = "black";
        }

        if (decList.includes('A')){
          decA.style.backgroundColor = "red";
          decA.style.color = "white";
        }else {
          decA.style.backgroundColor = "white";
          decA.style.color = "black";
        }
        if (decList.includes('B')){
          decB.style.backgroundColor = "red";
          decB.style.color = "white";
        }else {
          decB.style.backgroundColor = "white";
          decB.style.color = "black";
        }
        if (decList.includes('C')){
          decC.style.backgroundColor = "red";
          decC.style.color = "white";
        }else {
          decC.style.backgroundColor = "white";
          decC.style.color = "black";
        }
        if (decList.includes('D')){
          decD.style.backgroundColor = "red";
          decD.style.color = "white";
        }else {
          decD.style.backgroundColor = "white";
          decD.style.color = "black";
        }
      }
    },
    //历史查找样式修改
    historySelectStyle(){
      let x = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave;
      let t10 = this.$refs.t10;
      let t20 = this.$refs.t20;
      let t50 = this.$refs.t50;
      let t100 = this.$refs.t100;
      t10.style.backgroundColor = "white";
      t10.style.color = "black";
      t20.style.backgroundColor = "white";
      t20.style.color = "black";
      t50.style.backgroundColor = "white";
      t50.style.color = "black";
      t100.style.backgroundColor = "white";
      t100.style.color = "black";
      switch (this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val) {
        case 't10':
          if (x === 1){
            t10.style.backgroundColor = "green";
            t10.style.color = "white";
          }else {
            t10.style.backgroundColor = "red";
            t10.style.color = "white";
          }
          break;
        case 't20':
          if (x === 1){
            t20.style.backgroundColor = "green";
            t20.style.color = "white";
          }else {
            t20.style.backgroundColor = "red";
            t20.style.color = "white";
          }
          break;
        case 't50':
          if (x === 1){
            t50.style.backgroundColor = "green";
            t50.style.color = "white";
          }else {
            t50.style.backgroundColor = "red";
            t50.style.color = "white";
          }
          break;
        case 't100':
          if (x === 1){
            t100.style.backgroundColor = "green";
            t100.style.color = "white";
          }else {
            t100.style.backgroundColor = "red";
            t100.style.color = "white";
          }
          break;
      }
      this.$forceUpdate();
    },
    //清除方法
    clearMethod(){
      switch (this.$store.state.payWay.payType) {
        case 1:
          this.condList = this.deepClone(this.$store.state.payWay.condFourDecInit);
          break;
        case 2:
          this.condList = this.deepClone(this.$store.state.payWay.condFourShowInit);
          break;
        case 3:
          this.condList = this.deepClone(this.$store.state.payWay.condThreeDecInit);
          break;
        case 4:
          this.condList = this.deepClone(this.$store.state.payWay.condThreeShowInit);
          break;
        case 5:
          this.condList = this.deepClone(this.$store.state.payWay.condTwoDecInit);
          break;
        case 6:
          this.condList = this.deepClone(this.$store.state.payWay.condTwoShowInit);
          break;
      }
      switch (this.selectTitle1) {
        case '开奖历史':
          this.historySelectStyle();
          this.timeNumberStart = '开始期号';
          this.timeNumberEnd = '结束期号';
          this.condMesText = '';
          break;
        case '全转（全倒）':
          this.condMesText = '';
          break;
        case '减式':
          this.add = [];
          this.dec = [];
          this.condMesText = '';
          this.decWayStyle();
          this.reviseStyle();
          break;
        case '位积':
          this.condMesText = '';
          break;
        case '反位积':
          this.condMesText = '';
          break;
        case 'AC值':
          this.condMesText = '';
          break;
        case '入数个数':
          this.condMesText = '';
          this.inNumber = [];
          break;
        case '定位置':
          if (this.selectTitle2 === '自由选码'){
            this.condMesText = '';
            this.reviseStyle('isFreeCode');
          }
          if (this.selectTitle2 === '批量选码'){
            this.condMesText = '';
            this.thousandDanShuangRadio = 0;
            this.thousandDaXiaoRadio = 0;
            this.thousand012Radio = 0;
            this.hundredDanShuangRadio = 0;
            this.hundredDaXiaoRadio = 0;
            this.hundred012Radio = 0;
            this.tenDanShuangRadio = 0;
            this.tenDaXiaoRadio = 0;
            this.ten012Radio = 0;
            this.pieceDanShuangRadio = 0;
            this.pieceDaXiaoRadio = 0;
            this.piece012Radio = 0;
          }
          if (this.selectTitle2 === '乘号位置'){
            this.positionX = [];
            this.position1 = false;
            this.position2 = false;
            this.position3 = false;
            this.position4 = false;
            this.condMesText = '';
          }
          break;
        case '排除码（死数）':
          this.condMesText = '';
          this.condMes = [];
          break;
        case '合值（合分）':
          this.condMesText = '';
          this.condMes = [];
          this.reviseStyle();
          break;
        case '必出码（铁率）':
          this.condMesText = '';
          this.condMes = [];
          break;
        case '极值':
          this.condMesText = '';
          this.condMes = [];
          this.reviseStyle();
          break;
        case '和值':
          this.condMesText = '';
          this.condMes = [];
          this.reviseStyle();
          break;
        case '配号码':
          this.condMesText = '';
          this.condMes = [];
          break;
        case '头尾有数':
          this.condMesText = '';
          this.condMes = [];
          this.reviseStyle();
          break;
        case '中肚有数':
          this.condMesText = '';
          this.condMes = [];
          this.reviseStyle();
          break;
        case '跨度':
          this.condMesText = '';
          this.condMes = [];
          this.reviseStyle();
          break;
        case '合值+跨度':
          this.condMesText = '';
          this.condMes = [];
          this.reviseStyle();
          break;
        case '合值-跨度':
          this.condMesText = '';
          this.condMes = [];
          this.reviseStyle();
          break;
        case '余数和':
          this.condMesText = '';
          this.condMes = [];
          this.reviseStyle();
          break;
        case '连号（兄弟号）':
          this.condMesText = '';
          this.condMes = [];
          this.continueNumber = [];
          break;
        case '重数':
          this.condMesText = '';
          this.condMes = [];
          this.repeatNumber = [];
          break;
        case '凹凸升降':
          this.condMesText = '';
          this.condMes = [];
          this.aoTu = [];
          break;
        case '对数':
          this.condMesText = '';
          this.condMes = [];
          this.coupleNumber = [];
          break;
        case '两数乘积':
          this.condMesText = '';
          this.condMes = [];
          this.multiplyNumber = [];
          this.reviseStyle();
          break;
        case '三数乘积':
          this.condMesText = '';
          this.condMes = [];
          this.multiplyNumber = [];
          this.reviseStyle();
          break;
        case '两数合':
          this.condMesText = '';
          this.condMes = [];
          this.numberSum = [];
          this.reviseStyle();
          break;
        case '三数合':
          this.condMesText = '';
          this.condMes = [];
          this.numberSum = [];
          this.reviseStyle();
          break;
        case '四数乘积':
          this.condMesText = '';
          this.condMes = [];
          this.multiplyNumber = [];
          this.reviseStyle();
          break;
        case '有数':
          this.condMesText = '';
          this.condMes = [];
          this.haveNumber = [];
          this.reviseStyle();
          break;
        case '单双（奇偶）':
          this.condMesText = '';
          this.condMes = [];
          this.thousandRadio = 0;
          this.hundredRadio = 0;
          this.tenRadio = 0;
          this.pieceRadio = 0;
          this.posAnyChooseDS = [];
          this.appearRatio = [];
          this.breakGroup = [];
          break;
        case '大小':
          this.condMesText = '';
          this.condMes = [];
          this.thousandRadio = 0;
          this.hundredRadio = 0;
          this.tenRadio = 0;
          this.pieceRadio = 0;
          this.posAnyChooseBS = [];
          this.appearRatio = [];
          this.breakGroup = [];
          break;
        case '质合':
          this.condMesText = '';
          this.condMes = [];
          this.thousandRadio = 0;
          this.hundredRadio = 0;
          this.tenRadio = 0;
          this.pieceRadio = 0;
          this.posAnyChoosePN = [];
          this.appearRatio = [];
          this.breakGroup = [];
          break;
        case '大中小':
          this.condMesText = '';
          this.condMes = [];
          this.thousandRadio = 0;
          this.hundredRadio = 0;
          this.tenRadio = 0;
          this.pieceRadio = 0;
          this.posAnyChooseBMS = [];
          this.appearRatio = [];
          this.breakGroup = [];
          break;
        case '012路':
          this.condMesText = '';
          this.condMes = [];
          this.thousandRadio = 0;
          this.hundredRadio = 0;
          this.tenRadio = 0;
          this.pieceRadio = 0;
          this.posAnyChoose012 = [];
          this.appearRatio = [];
          this.breakGroup = [];
          break;
      }
      this.$forceUpdate();
    },
    //清除按钮
    clearBtn(){
      this.clearMethod();
    },
    //排除，保留按钮
    excludeOrSave(cond,checkBox,multipleBox){
      if (cond === 1){
        this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave = 1;
      }else {
        this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).isSave = 0;
      }
      if (checkBox === undefined || checkBox === ''){
        this.reviseStyle();
      }
      if (checkBox === 'isFreeCode'){
        this.reviseStyle('isFreeCode');
      }
      if (checkBox === 'decWay'){
        this.decWayStyle();
        this.reviseStyle();
      }
      if (checkBox === 'history'){
        this.historySelectStyle();
      }
      if (multipleBox !== undefined){
        this.reviseStyle();
      }
      this.$forceUpdate();
    },
    //排除码,必出码（铁率）,合值（合分）,极值,和值,头尾有数,中肚有数,跨度,合值+跨度,合值-跨度,余数和,乘积,AC值
    Btn1(index,cond){
      let selectCond = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val[index];
      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val[index] = !selectCond;
      if ([3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18].find(item =>(item === cond)) != undefined)
      {
        this.reviseStyle();
      }
      this.condMes = [];
      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val.forEach((item,index) =>{
        if (item){
          if (this.selectTitle1 === '合值+跨度')
          {
            if (this.$store.state.payWay.payType === 5 || this.$store.state.payWay.payType === 6)
            {
              this.condMes.push(index*2);
            }
            else {
              this.condMes.push(index);
            }
          }else if (this.selectTitle1 === '合值-跨度'){
            if (this.$store.state.payWay.payType === 5 || this.$store.state.payWay.payType === 6)
            {
              this.condMes.push(index*2-8);
            }
            else {
              this.condMes.push(index-9);
            }
          }else if (this.selectTitle1 === 'AC值'){
            this.condMes.push(index+1);
          }else {
            this.condMes.push(index);
          }
        }
      })
      this.condMesText = this.condMes.join(',');
      this.$forceUpdate();
    },
    //配号码
    Btn2(group,groupIndex){
      let selectCond = this.condList.find(item =>(item.name === '配号码')).btn.find(item =>(item.name === '设置')).group[group][groupIndex];
      this.condList.find(item =>(item.name === '配号码')).btn.find(item =>(item.name === '设置')).group[group][groupIndex] = !selectCond;
      let groupLength = this.condList.find(item =>(item.name === '配号码')).btn.find(item =>(item.name === '设置')).group.length;
      if (!selectCond){
        if (this.condMes.length === 0){
          switch (groupLength) {
            case 2:
              this.condMes = [[],[]];
              break;
            case 3:
              this.condMes = [[],[],[]];
              break;
            case 4:
              this.condMes = [[],[],[],[]];
              break;
          }
          this.condMes[group].push(groupIndex);
        }else {
          this.condMes[group].push(groupIndex);
        }
      }else {
        let index = this.condMes[group].findIndex(x => x === groupIndex);
        this.condMes[group].splice(index,1);
      }
      this.condMesText = '';
      switch (groupLength) {
        case 2:
          this.condMesText = this.condMes[0].join('') + ',配' + this.condMes[1].join('');
          break;
        case 3:
          this.condMesText = this.condMes[0].join('') + ',配' + this.condMes[1].join('') + ',配' + this.condMes[2].join('');
          break;
        case 4:
          this.condMesText = this.condMes[0].join('') + ',配' + this.condMes[1].join('') + ',配' + this.condMes[2].join('') + ',配' + this.condMes[3].join('');
          break;
      }
      this.$forceUpdate();
    },
    //连号
    Btn3(){
      this.condMes = [];
      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val = this.deepClone(this.continueNumber);
      for (let i=0;i<this.continueNumber.length;i++){
        switch (this.continueNumber[i]) {
          case '2':
            this.condMes.push("二连号");
            break;
          case '3':
            this.condMes.push("三连号");
            break;
          case '4':
            this.condMes.push("四连号");
            break;
        }
      }
      this.condMesText = this.condMes.join(',');
    },
    //重数
    Btn4(){
      this.condMes = [];
      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val = this.deepClone(this.repeatNumber);
      for (let i=0;i<this.repeatNumber.length;i++){
        switch (this.repeatNumber[i]) {
          case '2':
            this.condMes.push("双重");
            break;
          case '3':
            this.condMes.push("三重");
            break;
          case '4':
            this.condMes.push("四重");
            break;
          case '22':
            this.condMes.push("双双重");
            break;
        }
      }
      this.condMesText = this.condMes.join(',');
    },
    //乘积
    Btn5(index){
      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).multiplyWay = this.deepClone(this.multiplyNumber);
      let selectCond = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val[index];
      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val[index] = !selectCond;
      this.reviseStyle();
      this.condMes = [];
      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val.forEach((item,index) =>{
        if (item){
          this.condMes.push(index);
        }
      })
      let multipleList = [];
      for (let i=0;i<this.multiplyNumber.length;i++){
        switch (this.multiplyNumber[i]) {
          case 'ab':
            multipleList.push("A*B");
            break;
          case 'ac':
            multipleList.push("A*C");
            break;
          case 'ad':
            multipleList.push("A*D");
            break;
          case 'bc':
            multipleList.push("B*C");
            break;
          case 'bd':
            multipleList.push("B*D");
            break;
          case 'cd':
            multipleList.push("C*D");
            break;
          case 'abc':
            multipleList.push("A*B*C");
            break;
          case 'abd':
            multipleList.push("A*B*D");
            break;
          case 'acd':
            multipleList.push("A*C*D");
            break;
          case 'bcd':
            multipleList.push("B*C*D");
            break;
        }
      }
      if (multipleList.length !== 0){
        this.condMesText = multipleList.join("/") + ": " + this.condMes.join(',');
      }else {
        this.condMesText = this.condMes.join(',');
      }
      this.$forceUpdate();
    },
    //对数
    Btn6(){
      this.condMes = [];
      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val = this.deepClone(this.coupleNumber);
      for (let i=0;i<this.coupleNumber.length;i++){
        switch (this.coupleNumber[i]) {
          case '1':
            this.condMes.push("至少包含1组");
            break;
          case '2':
            this.condMes.push("至少包含2组");
            break;
        }
      }
      this.condMesText = this.condMes.join(',');
    },
    //有数
    Btn7(index){
      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).haveNumber = this.deepClone(this.haveNumber);
      let selectCond = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val[index];
      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val[index] = !selectCond;
      this.reviseStyle();
      this.condMes = [];
      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val.forEach((item,index) =>{
        if (item){
          this.condMes.push(index);
        }
      })
      let haveList = [];
      for (let i=0;i<this.haveNumber.length;i++){
        switch (this.haveNumber[i]) {
          case 'ab':
            haveList.push("A*B");
            break;
          case 'ac':
            haveList.push("A*C");
            break;
          case 'ad':
            haveList.push("A*D");
            break;
          case 'bc':
            haveList.push("B*C");
            break;
          case 'bd':
            haveList.push("B*D");
            break;
          case 'cd':
            haveList.push("C*D");
            break;
          case 'abc':
            haveList.push("A*B*C");
            break;
          case 'abd':
            haveList.push("A*B*D");
            break;
          case 'acd':
            haveList.push("A*C*D");
            break;
          case 'bcd':
            haveList.push("B*C*D");
            break;
        }
      }
      if (haveList.length !== 0){
        this.condMesText = haveList.join("/") + ": " + this.condMes.join(',');
      }else {
        this.condMesText = this.condMes.join(',');
      }
      this.$forceUpdate();
    },
    //两数合，三数合
    Btn8(index){
      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).numberSum = this.deepClone(this.numberSum);
      let selectCond = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val[index];
      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val[index] = !selectCond;
      this.reviseStyle();
      this.condMes = [];
      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val.forEach((item,index) =>{
        if (item){
          this.condMes.push(index);
        }
      })
      let numberSumList = [];
      for (let i=0;i<this.numberSum.length;i++){
        switch (this.numberSum[i]) {
          case 'ab':
            numberSumList.push("A*B");
            break;
          case 'ac':
            numberSumList.push("A*C");
            break;
          case 'ad':
            numberSumList.push("A*D");
            break;
          case 'bc':
            numberSumList.push("B*C");
            break;
          case 'bd':
            numberSumList.push("B*D");
            break;
          case 'cd':
            numberSumList.push("C*D");
            break;
          case 'abc':
            numberSumList.push("A*B*C");
            break;
          case 'abd':
            numberSumList.push("A*B*D");
            break;
          case 'acd':
            numberSumList.push("A*C*D");
            break;
          case 'bcd':
            numberSumList.push("B*C*D");
            break;
        }
      }
      if (numberSumList.length !== 0){
        this.condMesText = numberSumList.join("/") + ": " + this.condMes.join(',');
      }else {
        this.condMesText = this.condMes.join(',');
      }
      this.$forceUpdate();
    },
    //按位快选
    Btn9(){
      this.condMes = [];
      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val[0] = this.thousandRadio;
      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val[1] = this.hundredRadio;
      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val[2] = this.tenRadio;
      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val[3] = this.pieceRadio;
      let btnList = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val;
      switch (this.selectTitle1) {
        case '单双（奇偶）':
          btnList.forEach((item,index) =>{
            switch (index) {
              case 0:
                if (parseInt(item) === 1){
                  this.condMes.push("千位:单");
                }else if (parseInt(item) === 2){
                  this.condMes.push("千位:双");
                }
                break;
              case 1:
                if (parseInt(item) === 1){
                  this.condMes.push("百位:单");
                }else if (parseInt(item) === 2){
                  this.condMes.push("百位:双");
                }
                break;
              case 2:
                if (parseInt(item) === 1){
                  this.condMes.push("十位:单");
                }else if (parseInt(item) === 2){
                  this.condMes.push("十位:双");
                }
                break;
              case 3:
                if (parseInt(item) === 1){
                  this.condMes.push("个位:单");
                }else if (parseInt(item) === 2){
                  this.condMes.push("个位:双");
                }
                break;
            }
          })
          break;
        case '大小':
          btnList.forEach((item,index) =>{
            switch (index) {
              case 0:
                if (parseInt(item) === 1){
                  this.condMes.push("千位:大");
                }else if (parseInt(item) === 2){
                  this.condMes.push("千位:小");
                }
                break;
              case 1:
                if (parseInt(item) === 1){
                  this.condMes.push("百位:大");
                }else if (parseInt(item) === 2){
                  this.condMes.push("百位:小");
                }
                break;
              case 2:
                if (parseInt(item) === 1){
                  this.condMes.push("十位:大");
                }else if (parseInt(item) === 2){
                  this.condMes.push("十位:小");
                }
                break;
              case 3:
                if (parseInt(item) === 1){
                  this.condMes.push("个位:大");
                }else if (parseInt(item) === 2){
                  this.condMes.push("个位:小");
                }
                break;
            }
          })
          break;
        case '质合':
          btnList.forEach((item,index) =>{
            switch (index) {
              case 0:
                if (parseInt(item) === 1){
                  this.condMes.push("千位:质");
                }else if (parseInt(item) === 2){
                  this.condMes.push("千位:合");
                }
                break;
              case 1:
                if (parseInt(item) === 1){
                  this.condMes.push("百位:质");
                }else if (parseInt(item) === 2){
                  this.condMes.push("百位:合");
                }
                break;
              case 2:
                if (parseInt(item) === 1){
                  this.condMes.push("十位:质");
                }else if (parseInt(item) === 2){
                  this.condMes.push("十位:合");
                }
                break;
              case 3:
                if (parseInt(item) === 1){
                  this.condMes.push("个位:质");
                }else if (parseInt(item) === 2){
                  this.condMes.push("个位:合");
                }
                break;
            }
          })
          break;
        case '大中小':
          btnList.forEach((item,index) =>{
            switch (index) {
              case 0:
                if (parseInt(item) === 1){
                  this.condMes.push("千位:大");
                }else if (parseInt(item) === 2){
                  this.condMes.push("千位:小");
                }else if (parseInt(item) === 3){
                  this.condMes.push("千位:中");
                }
                break;
              case 1:
                if (parseInt(item) === 1){
                  this.condMes.push("百位:大");
                }else if (parseInt(item) === 2){
                  this.condMes.push("百位:小");
                }else if (parseInt(item) === 3){
                  this.condMes.push("百位:中");
                }
                break;
              case 2:
                if (parseInt(item) === 1){
                  this.condMes.push("十位:大");
                }else if (parseInt(item) === 2){
                  this.condMes.push("十位:小");
                }else if (parseInt(item) === 3){
                  this.condMes.push("十位:中");
                }
                break;
              case 3:
                if (parseInt(item) === 1){
                  this.condMes.push("个位:大");
                }else if (parseInt(item) === 2){
                  this.condMes.push("个位:小");
                }else if (parseInt(item) === 3){
                  this.condMes.push("个位:中");
                }
                break;
            }
          })
          break;
        case '012路':
          btnList.forEach((item,index) =>{
            switch (index) {
              case 0:
                if (parseInt(item) === 1){
                  this.condMes.push("千位:0路");
                }else if (parseInt(item) === 2){
                  this.condMes.push("千位:1路");
                }else if (parseInt(item) === 3){
                  this.condMes.push("千位:2路");
                }
                break;
              case 1:
                if (parseInt(item) === 1){
                  this.condMes.push("百位:0路");
                }else if (parseInt(item) === 2){
                  this.condMes.push("百位:1路");
                }else if (parseInt(item) === 3){
                  this.condMes.push("百位:2路");
                }
                break;
              case 2:
                if (parseInt(item) === 1){
                  this.condMes.push("十位:0路");
                }else if (parseInt(item) === 2){
                  this.condMes.push("十位:1路");
                }else if (parseInt(item) === 3){
                  this.condMes.push("十位:2路");
                }
                break;
              case 3:
                if (parseInt(item) === 1){
                  this.condMes.push("个位:0路");
                }else if (parseInt(item) === 2){
                  this.condMes.push("个位:1路");
                }else if (parseInt(item) === 3){
                  this.condMes.push("个位:2路");
                }
                break;
            }
          })
          break;
      }
      this.condMesText = this.condMes.join(',');
    },
    //按位任选
    Btn10(){
      this.condMes = [];
      switch (this.selectTitle1) {
        case '单双（奇偶）':{
          this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val = this.posAnyChooseDS;
          let dsMap = new Map([
              ['s','单'],
              ['d','双'],
              ['X','X']
          ]);
          this.posAnyChooseDS.forEach((item) =>{
            this.condMes.push(dsMap.get(item.toString().charAt(0)) + dsMap.get(item.toString().charAt(1)) + dsMap.get(item.toString().charAt(2)) + dsMap.get(item.toString().charAt(3)));
          })
          break;
        }
        case '大小':{
          this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val = this.posAnyChooseBS;
          let dsMap = new Map([
            ['x','小'],
            ['b','大'],
            ['X','X']
          ]);
          this.posAnyChooseBS.forEach((item) =>{
            this.condMes.push(dsMap.get(item.toString().charAt(0)) + dsMap.get(item.toString().charAt(1)) + dsMap.get(item.toString().charAt(2)) + dsMap.get(item.toString().charAt(3)));
          })
          break;
        }
        case '质合':{
          this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val = this.posAnyChoosePN;
          let dsMap = new Map([
            ['z','质'],
            ['h','合'],
            ['X','X']
          ]);
          this.posAnyChoosePN.forEach((item) =>{
            this.condMes.push(dsMap.get(item.toString().charAt(0)) + dsMap.get(item.toString().charAt(1)) + dsMap.get(item.toString().charAt(2)) + dsMap.get(item.toString().charAt(3)));
          })
          break;
        }
        case '大中小':{
          this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val = this.posAnyChooseBMS;
          let dsMap = new Map([
            ['b','大'],
            ['m','中'],
            ['s','小'],
            ['X','X']
          ]);
          this.posAnyChooseBMS.forEach((item) =>{
            this.condMes.push(dsMap.get(item.toString().charAt(0)) + dsMap.get(item.toString().charAt(1)) + dsMap.get(item.toString().charAt(2)) + dsMap.get(item.toString().charAt(3)));
          })
          break;
        }
        case '012路':{
          this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val = this.posAnyChoose012;
          this.posAnyChoose012.forEach((item) =>{
            this.condMes.push(item.toString().charAt(0) + item.toString().charAt(1) + item.toString().charAt(2) + item.toString().charAt(3));
          })
          break;
        }
      }
      this.condMesText = this.condMes.join(',');
    },
    //出现比例
    Btn11(){
      this.condMes = [];
      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val = this.appearRatio;
      this.appearRatio.forEach((item) =>{
        this.condMes.push(item.split('').join(':'));
      })
      this.condMesText = this.condMes.join(',');
    },
    //断组
    Btn12(){
      this.condMes = [];
      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val = this.deepClone(this.breakGroup);
      for (let i=0;i<this.breakGroup.length;i++){
        switch (this.breakGroup[i]) {
          case '1':
            this.condMes.push("单双不同时出现");
            break;
          case '2':
            this.condMes.push("大小不同时出现");
            break;
          case '3':
            this.condMes.push("质合不同时出现");
            break;
          case '4':
            this.condMes.push("大中小不同时出现");
            break;
          case '5':
            this.condMes.push("012路不同时出现");
            break;
        }
      }
      this.condMesText = this.condMes.join(',');
    },
    //自由选码
    Btn13(index,num){
      let selectCond = false;
      switch (num) {
        case 0:
          selectCond = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val[index];
          this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val[index] = !selectCond;
          break;
        case 1:
          selectCond = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val1[index];
          this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val1[index] = !selectCond;
          break;
        case 2:
          selectCond = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val2[index];
          this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val2[index] = !selectCond;
          break;
        case 3:
          selectCond = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val3[index];
          this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val3[index] = !selectCond;
          break;
      }
      this.reviseStyle('isFreeCode');
      let position1 = [];
      let position2 = [];
      let position3 = [];
      let position4 = [];
      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val.forEach((item,index) =>{
        if (item){
          if (position1.length === 0){
            position1.push("千位:");
            position1.push(index);
          }else {
            position1.push(index);
          }
        }
      })
      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val1.forEach((item,index) =>{
        if (item){
          if (position2.length === 0){
            position2.push("百位:");
            position2.push(index);
          }else {
            position2.push(index);
          }
        }
      })
      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val2.forEach((item,index) =>{
        if (item){
          if (position3.length === 0){
            position3.push("十位:");
            position3.push(index);
          }else {
            position3.push(index);
          }
        }
      })
      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val3.forEach((item,index) =>{
        if (item){
          if (position4.length === 0){
            position4.push("个位:");
            position4.push(index);
          }else {
            position4.push(index);
          }
        }
      })
      this.condMesText = position1.join('') + " " + position2.join('') + " " + position3.join('') + " " + position4.join('');
      this.$forceUpdate();
    },
    //批量选码
    Btn14(){
      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).thousandRadio[0] = this.thousandDanShuangRadio;
      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).thousandRadio[1] = this.thousandDaXiaoRadio;
      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).thousandRadio[2] = this.thousand012Radio;

      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).hundredRadio[0] = this.hundredDanShuangRadio;
      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).hundredRadio[1] = this.hundredDaXiaoRadio;
      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).hundredRadio[2] = this.hundred012Radio;

      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).tenRadio[0] = this.tenDanShuangRadio;
      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).tenRadio[1] = this.tenDaXiaoRadio;
      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).tenRadio[2] = this.ten012Radio;

      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).pieceRadio[0] = this.pieceDanShuangRadio;
      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).pieceRadio[1] = this.pieceDaXiaoRadio;
      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).pieceRadio[2] = this.piece012Radio;

      let thousand = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).thousandRadio;
      let hundred = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).hundredRadio;
      let ten = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).tenRadio;
      let piece = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).pieceRadio;
      let thousandList = [];
      let hundredList = [];
      let tenList = [];
      let pieceList = [];
      for (let i=0;i<3;i++){
        switch (thousand[i]) {
          case '1':
            if (thousandList.length === 0){
              thousandList.push("千位:");
            }
            if (i === 0){
              thousandList.push("单");
            }
            if (i === 1){
              thousandList.push("大");
            }
            if (i === 2){
              thousandList.push("0路");
            }
            break;
          case '2':
            if (thousandList.length === 0){
              thousandList.push("千位:");
            }
            if (i === 0){
              thousandList.push("双");
            }
            if (i === 1){
              thousandList.push("小");
            }
            if (i === 2){
              thousandList.push("1路");
            }
            break;
          case '3':
            if (thousandList.length === 0){
              thousandList.push("千位");
            }
            if (i === 2){
              thousandList.push("2路");
            }
            break;
        }
        switch (hundred[i]) {
          case '1':
            if (hundredList.length === 0){
              hundredList.push("百位:");
            }
            if (i === 0){
              hundredList.push("单");
            }
            if (i === 1){
              hundredList.push("大");
            }
            if (i === 2){
              hundredList.push("0路");
            }
            break;
          case '2':
            if (hundredList.length === 0){
              hundredList.push("百位:");
            }
            if (i === 0){
              hundredList.push("双");
            }
            if (i === 1){
              hundredList.push("小");
            }
            if (i === 2){
              hundredList.push("1路");
            }
            break;
          case '3':
            if (hundredList.length === 0){
              hundredList.push("百位:");
            }
            if (i === 2){
              hundredList.push("2路");
            }
            break;
        }
        switch (ten[i]) {
          case '1':
            if (tenList.length === 0){
              tenList.push("十位:");
            }
            if (i === 0){
              tenList.push("单");
            }
            if (i === 1){
              tenList.push("大");
            }
            if (i === 2){
              tenList.push("0路");
            }
            break;
          case '2':
            if (tenList.length === 0){
              tenList.push("十位:");
            }
            if (i === 0){
              tenList.push("双");
            }
            if (i === 1){
              tenList.push("小");
            }
            if (i === 2){
              tenList.push("1路");
            }
            break;
          case '3':
            if (tenList.length === 0){
              tenList.push("十位:");
            }
            if (i === 2){
              tenList.push("2路");
            }
            break;
        }
        switch (piece[i]) {
          case '1':
            if (pieceList.length === 0){
              pieceList.push("个位:");
            }
            if (i === 0){
              pieceList.push("单");
            }
            if (i === 1){
              pieceList.push("大");
            }
            if (i === 2){
              pieceList.push("0路");
            }
            break;
          case '2':
            if (pieceList.length === 0){
              pieceList.push("个位:");
            }
            if (i === 0){
              pieceList.push("双");
            }
            if (i === 1){
              pieceList.push("小");
            }
            if (i === 2){
              pieceList.push("1路");
            }
            break;
          case '3':
            if (pieceList.length === 0){
              pieceList.push("个位:");
            }
            if (i === 2){
              pieceList.push("2路");
            }
            break;
        }
      }
      this.condMesText = thousandList.join('') + '  ' + hundredList.join('') + '  ' + tenList.join('') + '  ' + pieceList.join('');
    },
    //乘号位置
    Btn15(){
      this.condMes = [];
      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val = this.deepClone(this.positionX);
      for (let i=0;i<this.positionX.length;i++){
        switch (this.positionX[i]) {
          case '1':
            this.condMes.push("千位X");
            break;
          case '2':
            this.condMes.push("百位X");
            break;
          case '3':
            this.condMes.push("十位X");
            break;
          case '4':
            this.condMes.push("个位X");
            break;
        }
      }
      this.disablePositionX();
      this.condMesText = this.condMes.join(',');
    },
    //入数个数
    Btn16(){
      this.condMes = [];
      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val = this.deepClone(this.inNumber);
      for (let i=0;i<this.inNumber.length;i++){
        switch (this.inNumber[i]) {
          case '1':
            this.condMes.push("1个");
            break;
          case '2':
            this.condMes.push("2个");
            break;
          case '3':
            this.condMes.push("3个");
            break;
          case '4':
            this.condMes.push("4个");
            break;
        }
      }
      this.condMesText = this.condMes.join(',');
    },
    //凹凸升降
    Btn17(){
      this.condMes = [];
      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val = this.deepClone(this.aoTu);
      for (let i=0;i<this.aoTu.length;i++){
        switch (this.aoTu[i]) {
          case '1':
            this.condMes.push("凹下型");
            break;
          case '2':
            this.condMes.push("凸起型");
            break;
          case '3':
            this.condMes.push("上升型");
            break;
          case '4':
            this.condMes.push("下降型");
            break;
          case '5':
            this.condMes.push("平行型");
            break;
          case '6':
            this.condMes.push("凹下型");
            break;
          case '7':
            this.condMes.push("凸起型");
            break;
          case '8':
            this.condMes.push("上升型");
            break;
          case '9':
            this.condMes.push("下降型");
            break;
          case '10':
            this.condMes.push("平行型");
            break;
        }
      }
      this.condMesText = this.condMes.join(',');
    },
    //减式
    Btn18(condNum,way,index){
      let chooseNum = [];
      let chooseWay = '';
      if (index === '' || index === undefined){
        switch (way) {
          case 'add':
            switch (condNum) {
              case 'A':
                if (this.add.includes('A')){
                  this.add.splice(this.add.indexOf('A'),1);
                }else {
                  this.add.push('A');
                }
                break;
              case 'B':
                if (this.add.includes('B')){
                  this.add.splice(this.add.indexOf('B'),1);
                }else {
                  this.add.push('B');
                }
                break;
              case 'C':
                if (this.add.includes('C')){
                  this.add.splice(this.add.indexOf('C'),1);
                }else {
                  this.add.push('C');
                }
                break;
              case 'D':
                if (this.add.includes('D')){
                  this.add.splice(this.add.indexOf('D'),1);
                }else {
                  this.add.push('D');
                }
                break;
            }
            break;
          case 'dec':
            switch (condNum) {
              case 'A':
                if (this.dec.includes('A')){
                  this.dec.splice(this.dec.indexOf('A'),1);
                }else {
                  this.dec.push('A');
                }
                break;
              case 'B':
                if (this.dec.includes('B')){
                  this.dec.splice(this.dec.indexOf('B'),1);
                }else {
                  this.dec.push('B');
                }
                break;
              case 'C':
                if (this.dec.includes('C')){
                  this.dec.splice(this.dec.indexOf('C'),1);
                }else {
                  this.dec.push('C');
                }
                break;
              case 'D':
                if (this.dec.includes('D')){
                  this.dec.splice(this.dec.indexOf('D'),1);
                }else {
                  this.dec.push('D');
                }
                break;
            }
            break;
        }
        this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).add = this.add;
        this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).dec = this.dec;
        this.decWayStyle();
      }else {
        let selectCond = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val[index];
        this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val[index] = !selectCond;
        this.reviseStyle();
      }
      chooseWay = this.add.join('+') + '-' + this.dec.join('-');
      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val.forEach((item,index) =>{
        if (item){
          chooseNum.push(index);
        }
      })
      this.condMesText = chooseWay + ' ' + '合' + chooseNum.join(',');
      this.$forceUpdate();
    },
    //全倒
    Btn19(index){
      if (this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val.length === 10){
        Toast('最多选10个');
        return;
      }else {
        this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val.push(index);
      }
      this.condMesText = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val.sort().join('');
    },
    //历史查找
    Btn20(condStr){
      this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val = condStr;
      switch (this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val) {
        case 't10':
          this.timeNumberStart = this.historyData[9].periodText.toString();
          this.timeNumberEnd = this.historyData[0].periodText.toString();
          this.condMesText = '第' + this.timeNumberStart + '~' + this.timeNumberEnd;
          for (let i=0;i<10;i++){
            this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).listNum.push(this.historyData[i].lotteryContent.toString().substr(0,4));
          }
          break;
        case 't20':
          this.timeNumberStart = this.historyData[19].periodText.toString();
          this.timeNumberEnd = this.historyData[0].periodText.toString();
          this.condMesText = '第' + this.timeNumberStart + '~' + this.timeNumberEnd;
          for (let i=0;i<20;i++){
            this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).listNum.push(this.historyData[i].lotteryContent.toString().substr(0,4));
          }
          break;
        case 't50':
          this.timeNumberStart = this.historyData[49].periodText.toString();
          this.timeNumberEnd = this.historyData[0].periodText.toString();
          this.condMesText = '第' + this.timeNumberStart + '~' + this.timeNumberEnd;
          for (let i=0;i<50;i++){
            this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).listNum.push(this.historyData[i].lotteryContent.toString().substr(0,4));
          }
          break;
        case 't100':
          this.timeNumberStart = this.historyData[99].periodText.toString();
          this.timeNumberEnd = this.historyData[0].periodText.toString();
          this.condMesText = '第' + this.timeNumberStart + '~' + this.timeNumberEnd;
          for (let i=0;i<100;i++){
            this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).listNum.push(this.historyData[i].lotteryContent.toString().substr(0,4));
          }
          break;
      }
      this.historySelectStyle();
    },
    //禁用X
    disablePositionX(){
      this.position1 = false;
      this.position2 = false;
      this.position3 = false;
      this.position4 = false;
      let val = this.condList.find(item =>(item.name === this.selectTitle1)).btn.find(item => (item.name === this.selectTitle2)).val;
      switch (this.$store.state.payWay.payType) {
        case 3:{
          if (val.length === 1){
            let x = ['1','2','3','4',].filter((item) =>{
              if (!val[0].includes(item)){
                return item;
              }
            })
            x.forEach((ite) =>{
              switch (ite) {
                case '1':
                  this.position1 = true;
                  break;
                case '2':
                  this.position2 = true;
                  break;
                case '3':
                  this.position3 = true;
                  break;
                case '4':
                  this.position4 = true;
                  break;
              }
            })
          }
          break;
        }
        case 5:{
          if (val.length === 2){
            let x = ['1','2','3','4',].filter((item) =>{
              if (!val.includes(item)){
                return item;
              }
            })
            x.forEach((ite) =>{
              switch (ite) {
                case '1':
                  this.position1 = true;
                  break;
                case '2':
                  this.position2 = true;
                  break;
                case '3':
                  this.position3 = true;
                  break;
                case '4':
                  this.position4 = true;
                  break;
              }
            })
          }
          break;
        }
      }
    },
    //去重
    deduplicationMethod(list1,list2){
      return Array.from(new Set(list1.concat(list2)));
    },
    //计算功能
    numComputedSelect(){
      switch (this.$store.state.payWay.payType) {
        case 1:
          this.numComputed(this.$store.state.payWay.fourDecComputed,this.$store.state.payWay.conditionFourDec,this.$store.state.payWay.conditionFourDec[0].isOverlap);
          break;
        case 2:
          this.numComputed(this.$store.state.payWay.fourShowComputed,this.$store.state.payWay.conditionFourShow,this.$store.state.payWay.conditionFourShow[0].isOverlap);
          break;
        case 3:
          this.numComputed(this.$store.state.payWay.threeDecComputed,this.$store.state.payWay.conditionThreeDec,this.$store.state.payWay.conditionThreeDec[0].isOverlap);
          break;
        case 4:
          this.numComputed(this.$store.state.payWay.threeShowComputed,this.$store.state.payWay.conditionThreeShow,this.$store.state.payWay.conditionThreeShow[0].isOverlap);
          break;
        case 5:
          this.numComputed(this.$store.state.payWay.twoDecComputed,this.$store.state.payWay.conditionTwoDec,this.$store.state.payWay.conditionTwoDec[0].isOverlap);
          break;
        case 6:
          this.numComputed(this.$store.state.payWay.twoShowComputed,this.$store.state.payWay.conditionTwoShow,this.$store.state.payWay.conditionTwoShow[0].isOverlap);
          break;
      }
    },
    numComputed(list,condList,isOverlap){
      let numList = list;
      let overlap = [];
      let condListSelect = [];
      if (condList.length !== 0){
        condListSelect = condList.filter(item =>(item.isTrue === 1));
      }
      let num = [];
      let numChildren = [];
      for (let i=0;i<condListSelect.length;i++){
        switch (condListSelect[i].name) {
          case '合值（合分）':
            for (let j=0;j<condListSelect[i].btn.length;j++){
              if (condListSelect[i].btn[j].isTrue === 1){
                switch (condListSelect[i].btn[j].name) {
                  case '设置':
                    num = [];
                    condListSelect[i].btn[j].val.forEach((item,index) => {
                      if (item){
                        num.push(index);
                      }
                    });
                    if (isOverlap === 1){
                      numList = this.sumNumber(numList,num,condListSelect[i].btn[j].isSave);
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.sumNumber(numList,num,condListSelect[i].btn[j].isSave));
                    }
                    break;
                }
              }
            }
            break;
          case '单双（奇偶）':
            for (let j=0;j<condListSelect[i].btn.length;j++){
              if (condListSelect[i].btn[j].isTrue === 1){
                switch (condListSelect[i].btn[j].name) {
                  case '按位快选':
                    if (isOverlap === 1){
                      numList = this.positionQuickChose(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave,'单双（奇偶）');
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.positionQuickChose(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave,'单双（奇偶）'));
                    }
                    break;
                  case '按位任选':
                    if (isOverlap === 1){
                      numList = this.positionAnyChoose(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave,'单双（奇偶）');
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.positionAnyChoose(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave,'单双（奇偶）'));
                    }
                    break;
                  case '出现比例':
                    if (isOverlap === 1){
                      numList = this.apperRatioMethod(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave,'单双（奇偶）');
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.apperRatioMethod(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave,'单双（奇偶）'));
                    }
                    break;
                  case '断组':
                    if (isOverlap === 1){
                      numList = this.breakGroupMethod(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave,'单双（奇偶）');
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.breakGroupMethod(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave,'单双（奇偶）'));
                    }
                    break;
                }
              }
            }
            break;
          case '大小':
            for (let j=0;j<condListSelect[i].btn.length;j++){
              if (condListSelect[i].btn[j].isTrue === 1){
                switch (condListSelect[i].btn[j].name) {
                  case '按位快选':
                    if (isOverlap === 1){
                      numList = this.positionQuickChose(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave,'大小');
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.positionQuickChose(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave,'大小'));
                    }
                    break;
                  case '按位任选':
                    if (isOverlap === 1){
                      numList = this.positionAnyChoose(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave,'大小');
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.positionAnyChoose(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave,'大小'));
                    }
                    break;
                  case '出现比例':
                    if (isOverlap === 1){
                      numList = this.apperRatioMethod(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave,'大小');
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.apperRatioMethod(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave,'大小'));
                    }
                    break;
                  case '断组':
                    if (isOverlap === 1){
                      numList = this.breakGroupMethod(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave,'大小');
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.breakGroupMethod(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave,'大小'));
                    }
                    break;
                }
              }
            }
            break;
          case '质合':
            for (let j=0;j<condListSelect[i].btn.length;j++){
              if (condListSelect[i].btn[j].isTrue === 1){
                switch (condListSelect[i].btn[j].name) {
                  case '按位快选':
                    if (isOverlap === 1){
                      numList = this.positionQuickChose(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave,'质合');
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.positionQuickChose(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave,'质合'));
                    }
                    break;
                  case '按位任选':
                    if (isOverlap === 1){
                      numList = this.positionAnyChoose(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave,'质合');
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.positionAnyChoose(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave,'质合'));
                    }
                    break;
                  case '出现比例':
                    if (isOverlap === 1){
                      numList = this.apperRatioMethod(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave,'质合');
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.apperRatioMethod(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave,'质合'));
                    }
                    break;
                  case '断组':
                    if (isOverlap === 1){
                      numList = this.breakGroupMethod(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave,'质合');
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.breakGroupMethod(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave,'质合'));
                    }
                    break;
                }
              }
            }
            break;
          case '大中小':
            for (let j=0;j<condListSelect[i].btn.length;j++){
              if (condListSelect[i].btn[j].isTrue === 1){
                switch (condListSelect[i].btn[j].name) {
                  case '按位快选':
                    if (isOverlap === 1){
                      numList = this.positionQuickChose(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave,'大中小');
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.positionQuickChose(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave,'大中小'));
                    }
                    break;
                  case '按位任选':
                    if (isOverlap === 1){
                      numList = this.positionAnyChoose(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave,'大中小');
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.positionAnyChoose(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave,'大中小'));
                    }
                    break;
                  case '出现比例':
                    if (isOverlap === 1){
                      numList = this.apperRatioMethod(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave,'大中小');
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.apperRatioMethod(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave,'大中小'));
                    }
                    break;
                  case '断组':
                    if (isOverlap === 1){
                      numList = this.breakGroupMethod(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave,'大中小');
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.breakGroupMethod(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave,'大中小'));
                    }
                    break;
                }
              }
            }
            break;
          case '012路':
            for (let j=0;j<condListSelect[i].btn.length;j++){
              if (condListSelect[i].btn[j].isTrue === 1){
                switch (condListSelect[i].btn[j].name) {
                  case '按位快选':
                    if (isOverlap === 1){
                      numList = this.positionQuickChose(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave,'012路');
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.positionQuickChose(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave,'012路'));
                    }
                    break;
                  case '按位任选':
                    if (isOverlap === 1){
                      numList = this.positionAnyChoose(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave,'012路');
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.positionAnyChoose(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave,'012路'));
                    }
                    break;
                  case '出现比例':
                    if (isOverlap === 1){
                      numList = this.apperRatioMethod(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave,'012路');
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.apperRatioMethod(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave,'012路'));
                    }
                    break;
                  case '断组':
                    if (isOverlap === 1){
                      numList = this.breakGroupMethod(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave,'012路');
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.breakGroupMethod(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave,'012路'));
                    }
                    break;
                }
              }
            }
            break;
          case '对数':
            for (let j=0;j<condListSelect[i].btn.length;j++){
              if (condListSelect[i].btn[j].isTrue === 1){
                switch (condListSelect[i].btn[j].name) {
                  case '设置':
                    if (isOverlap === 1){
                      numList = this.coupleNumberMethod(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave);
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.coupleNumberMethod(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave));
                    }
                    break;
                }
              }
            }
            break;
          case '配号码':
            for (let j=0;j<condListSelect[i].btn.length;j++){
              if (condListSelect[i].btn[j].isTrue === 1){
                switch (condListSelect[i].btn[j].name) {
                  case '设置':
                    num = [];
                    condListSelect[i].btn[j].group.forEach(item =>{
                      numChildren = [];
                      item.forEach((ite,ind) =>{
                        if (ite){
                          numChildren.push(ind);
                        }
                      })
                      num.push(numChildren);
                    })
                    if (isOverlap === 1){
                      numList = this.mixNumber2(numList,num);
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.mixNumber2(numList,num));
                    }
                    break;
                }
              }
            }
            break;
          case '排除码（死数）':
            for (let j=0;j<condListSelect[i].btn.length;j++){
              if (condListSelect[i].btn[j].isTrue === 1){
                switch (condListSelect[i].btn[j].name) {
                  case '设置':
                    num = [];
                    condListSelect[i].btn[j].val.forEach((item,index) => {
                      if (item){
                        num.push(index);
                      }
                    });
                    if (isOverlap === 1){
                      numList = this.deadNumber(numList,num);
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.deadNumber(numList,num));
                    }
                    break;
                }
              }
            }
            break;
          case '必出码（铁率）':
            for (let j=0;j<condListSelect[i].btn.length;j++){
              if (condListSelect[i].btn[j].isTrue === 1){
                switch (condListSelect[i].btn[j].name) {
                  case '设置':
                    num = [];
                    condListSelect[i].btn[j].val.forEach((item,index) => {
                      if (item){
                        num.push(index);
                      }
                    });
                    if (isOverlap === 1){
                      numList = this.mustNumber(numList,num);
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.mustNumber(numList,num));
                    }
                    break;
                }
              }
            }
            break;
          case '重数':
            for (let j=0;j<condListSelect[i].btn.length;j++){
              if (condListSelect[i].btn[j].isTrue === 1){
                switch (condListSelect[i].btn[j].name) {
                  case '设置':
                    if (isOverlap === 1){
                      numList = this.repeatNumberCount(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave);
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.repeatNumberCount(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave));
                    }
                    break;
                }
              }
            }
            break;
          case '连号（兄弟号）':
            for (let j=0;j<condListSelect[i].btn.length;j++){
              if (condListSelect[i].btn[j].isTrue === 1){
                switch (condListSelect[i].btn[j].name) {
                  case '设置':
                    if (isOverlap === 1){
                      numList = this.continueNumberCount(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave);
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.continueNumberCount(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave));
                    }
                    break;
                }
              }
            }
            break;
          case '入数个数':
            for (let j=0;j<condListSelect[i].btn.length;j++){
              if (condListSelect[i].btn[j].isTrue === 1){
                switch (condListSelect[i].btn[j].name) {
                  case '设置':
                    if (isOverlap === 1){
                      numList = this.inNumberMethod(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave);
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.inNumberMethod(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave));
                    }
                    break;
                }
              }
            }
            break;
          case '极值':
            for (let j=0;j<condListSelect[i].btn.length;j++){
              if (condListSelect[i].btn[j].isTrue === 1){
                switch (condListSelect[i].btn[j].name) {
                  case '最大值':
                    num = [];
                    condListSelect[i].btn[j].val.forEach((item,index) => {
                      if (item){
                        num.push(index);
                      }
                    });
                    if (isOverlap === 1){
                      numList = this.maxNumber(numList,num,condListSelect[i].btn[j].isSave);
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.maxNumber(numList,num,condListSelect[i].btn[j].isSave));
                    }
                    break;
                  case '最小值':
                    num = [];
                    condListSelect[i].btn[j].val.forEach((item,index) => {
                      if (item){
                        num.push(index);
                      }
                    });
                    if (isOverlap === 1){
                      numList = this.minNumber(numList,num,condListSelect[i].btn[j].isSave);
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.minNumber(numList,num,condListSelect[i].btn[j].isSave));
                    }
                    break;
                }
              }
            }
            break;
          case '跨度':
            for (let j=0;j<condListSelect[i].btn.length;j++){
              if (condListSelect[i].btn[j].isTrue === 1){
                switch (condListSelect[i].btn[j].name) {
                  case '设置':
                    num = [];
                    condListSelect[i].btn[j].val.forEach((item,index) => {
                      if (item){
                        num.push(index);
                      }
                    });
                    if (isOverlap === 1){
                      numList = this.spanNumber(numList,num,condListSelect[i].btn[j].isSave);
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.spanNumber(numList,num,condListSelect[i].btn[j].isSave));
                    }
                    break;
                }
              }
            }
            break;
          case '和值':
            for (let j=0;j<condListSelect[i].btn.length;j++){
              if (condListSelect[i].btn[j].isTrue === 1){
                switch (condListSelect[i].btn[j].name) {
                  case '设置':
                    num = [];
                    condListSelect[i].btn[j].val.forEach((item,index) => {
                      if (item){
                        num.push(index);
                      }
                    });
                    if (isOverlap === 1){
                      numList = this.sumAllNumber(numList,num,condListSelect[i].btn[j].isSave);
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.sumAllNumber(numList,num,condListSelect[i].btn[j].isSave));
                    }
                    break;
                }
              }
            }
            break;
          case '合值+跨度':
            for (let j=0;j<condListSelect[i].btn.length;j++){
              if (condListSelect[i].btn[j].isTrue === 1){
                switch (condListSelect[i].btn[j].name) {
                  case '设置':
                    num = condListSelect[i].btn[j].mes.split(',');
                    if (isOverlap === 1){
                      numList = this.spanAddSum(numList,num,condListSelect[i].btn[j].isSave);
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.spanAddSum(numList,num,condListSelect[i].btn[j].isSave));
                    }
                    break;
                }
              }
            }
            break;
          case '合值-跨度':
            for (let j=0;j<condListSelect[i].btn.length;j++){
              if (condListSelect[i].btn[j].isTrue === 1){
                switch (condListSelect[i].btn[j].name) {
                  case '设置':
                    num = condListSelect[i].btn[j].mes.split(',');
                    if (isOverlap === 1){
                      numList = this.sumSubSpan(numList,num,condListSelect[i].btn[j].isSave);
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.sumSubSpan(numList,num,condListSelect[i].btn[j].isSave));
                    }
                    break;
                }
              }
            }
            break;
          case '两数乘积':
            for (let j=0;j<condListSelect[i].btn.length;j++){
              if (condListSelect[i].btn[j].isTrue === 1){
                switch (condListSelect[i].btn[j].name) {
                  case '合值':
                    if ([1,3,5].find((item) => (item === this.$store.state.payWay.payType)) !== undefined){
                      num = [];
                      condListSelect[i].btn[j].val.forEach((item,index) => {
                        if (item){
                          num.push(index);
                        }
                      });
                      if (isOverlap === 1){
                        numList = this.multipleMethod(numList,num,condListSelect[i].btn[j].isSave,condListSelect[i].btn[j].multiplyWay);
                      }else if (isOverlap === 2){
                        overlap = this.deduplicationMethod(overlap,this.multipleMethod(numList,num,condListSelect[i].btn[j].isSave,condListSelect[i].btn[j].multiplyWay));
                      }
                    }else {
                      num = [];
                      condListSelect[i].btn[j].val.forEach((item,index) => {
                        if (item){
                          num.push(index);
                        }
                      });
                      if (isOverlap === 1){
                        numList = this.multipleMethod(numList,num,condListSelect[i].btn[j].isSave,'');
                      }else if (isOverlap === 2){
                        overlap = this.deduplicationMethod(overlap,this.multipleMethod(numList,num,condListSelect[i].btn[j].isSave,''));
                      }
                    }
                    break;
                }
              }
            }
            break;
          case '三数乘积':
            for (let j=0;j<condListSelect[i].btn.length;j++){
              if (condListSelect[i].btn[j].isTrue === 1){
                switch (condListSelect[i].btn[j].name) {
                  case '合值':
                    if ([1,3].find((item) => (item === this.$store.state.payWay.payType)) !== undefined){
                      num = [];
                      condListSelect[i].btn[j].val.forEach((item,index) => {
                        if (item){
                          num.push(index);
                        }
                      });
                      if (isOverlap === 1){
                        numList = this.multipleMethod(numList,num,condListSelect[i].btn[j].isSave,condListSelect[i].btn[j].multiplyWay);
                      }else if (isOverlap === 2){
                        overlap = this.deduplicationMethod(overlap,this.multipleMethod(numList,num,condListSelect[i].btn[j].isSave,condListSelect[i].btn[j].multiplyWay));
                      }
                    }else {
                      num = [];
                      condListSelect[i].btn[j].val.forEach((item,index) => {
                        if (item){
                          num.push(index);
                        }
                      });
                      if (isOverlap === 1){
                        numList = this.multipleMethod(numList,num,condListSelect[i].btn[j].isSave,'');
                      }else if (isOverlap === 2){
                        overlap = this.deduplicationMethod(overlap,this.multipleMethod(numList,num,condListSelect[i].btn[j].isSave,''));
                      }
                    }
                    break;
                }
              }
            }
            break;
          case '四数乘积':
            for (let j=0;j<condListSelect[i].btn.length;j++){
              if (condListSelect[i].btn[j].isTrue === 1){
                switch (condListSelect[i].btn[j].name) {
                  case '合值':
                    num = [];
                    condListSelect[i].btn[j].val.forEach((item,index) => {
                      if (item){
                        num.push(index);
                      }
                    });
                    if (isOverlap === 1){
                      numList = this.multipleMethod(numList,num,condListSelect[i].btn[j].isSave,'');
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.multipleMethod(numList,num,condListSelect[i].btn[j].isSave,''));
                    }
                    break;
                }
              }
            }
            break;
          case '有数':
            for (let j=0;j<condListSelect[i].btn.length;j++){
              if (condListSelect[i].btn[j].isTrue === 1){
                switch (condListSelect[i].btn[j].name) {
                  case '4个位置':
                    num = [];
                    condListSelect[i].btn[j].val.forEach((item,index) => {
                      if (item){
                        num.push(index);
                      }
                    });
                    if (isOverlap === 1){
                      numList = this.haveNumberMethod(numList,num,condListSelect[i].btn[j].isSave,'');
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.haveNumberMethod(numList,num,condListSelect[i].btn[j].isSave,''));
                    }
                    break;
                  case '3个位置':
                    num = [];
                    condListSelect[i].btn[j].val.forEach((item,index) => {
                      if (item){
                        num.push(index);
                      }
                    });
                    if (isOverlap === 1){
                      numList = this.haveNumberMethod(numList,num,condListSelect[i].btn[j].isSave,condListSelect[i].btn[j].haveNumber);
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.haveNumberMethod(numList,num,condListSelect[i].btn[j].isSave,condListSelect[i].btn[j].haveNumber));
                    }
                    break;
                  case '2个位置':
                    num = [];
                    condListSelect[i].btn[j].val.forEach((item,index) => {
                      if (item){
                        num.push(index);
                      }
                    });
                    if (isOverlap === 1){
                      numList = this.haveNumberMethod(numList,num,condListSelect[i].btn[j].isSave,condListSelect[i].btn[j].haveNumber);
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.haveNumberMethod(numList,num,condListSelect[i].btn[j].isSave,condListSelect[i].btn[j].haveNumber));
                    }
                    break;
                }
              }
            }
            break;
          case '两数合':
            for (let j=0;j<condListSelect[i].btn.length;j++){
              if (condListSelect[i].btn[j].isTrue === 1){
                switch (condListSelect[i].btn[j].name) {
                  case '不定位置':
                    num = [];
                    condListSelect[i].btn[j].val.forEach((item,index) => {
                      if (item){
                        num.push(index);
                      }
                    });
                    if (isOverlap === 1){
                      numList = this.twoSumMethod(numList,num,condListSelect[i].btn[j].isSave,'');
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.twoSumMethod(numList,num,condListSelect[i].btn[j].isSave,''));
                    }
                    break;
                  case '定位置':
                    num = [];
                    condListSelect[i].btn[j].val.forEach((item,index) => {
                      if (item){
                        num.push(index);
                      }
                    });
                    if (isOverlap === 1){
                      numList = this.twoSumMethod(numList,num,condListSelect[i].btn[j].isSave,condListSelect[i].btn[j].numberSum);
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.twoSumMethod(numList,num,condListSelect[i].btn[j].isSave,condListSelect[i].btn[j].numberSum));
                    }
                    break;
                }
              }
            }
            break;
          case '三数合':
            for (let j=0;j<condListSelect[i].btn.length;j++){
              if (condListSelect[i].btn[j].isTrue === 1){
                switch (condListSelect[i].btn[j].name) {
                  case '不定位置':
                    num = [];
                    condListSelect[i].btn[j].val.forEach((item,index) => {
                      if (item){
                        num.push(index);
                      }
                    });
                    if (isOverlap === 1){
                      numList = this.threeSumMethod(numList,num,condListSelect[i].btn[j].isSave,'');
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.threeSumMethod(numList,num,condListSelect[i].btn[j].isSave,''));
                    }
                    break;
                  case '定位置':
                    num = [];
                    condListSelect[i].btn[j].val.forEach((item,index) => {
                      if (item){
                        num.push(index);
                      }
                    });
                    if (isOverlap === 1){
                      numList = this.threeSumMethod(numList,num,condListSelect[i].btn[j].isSave,condListSelect[i].btn[j].numberSum);
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.threeSumMethod(numList,num,condListSelect[i].btn[j].isSave,condListSelect[i].btn[j].numberSum));
                    }
                    break;
                }
              }
            }
            break;
          case '余数和':
            for (let j=0;j<condListSelect[i].btn.length;j++){
              if (condListSelect[i].btn[j].isTrue === 1){
                switch (condListSelect[i].btn[j].name) {
                  case '除以3':
                    num = [];
                    condListSelect[i].btn[j].val.forEach((item,index) => {
                      if (item){
                        num.push(index);
                      }
                    });
                    if (isOverlap === 1){
                      numList = this.remNumber(numList,num,condListSelect[i].btn[j].isSave);
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.remNumber(numList,num,condListSelect[i].btn[j].isSave));
                    }
                    break;
                }
              }
            }
            break;
          case '中肚有数':
            for (let j=0;j<condListSelect[i].btn.length;j++){
              if (condListSelect[i].btn[j].isTrue === 1){
                switch (condListSelect[i].btn[j].name) {
                  case '设置':
                    num = [];
                    condListSelect[i].btn[j].val.forEach((item,index) => {
                      if (item){
                        num.push(index);
                      }
                    });
                    if (isOverlap === 1){
                      numList = this.midNumber(numList,num,condListSelect[i].btn[j].isSave);
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.midNumber(numList,num,condListSelect[i].btn[j].isSave));
                    }
                    break;
                }
              }
            }
            break;
          case '头尾有数':
            for (let j=0;j<condListSelect[i].btn.length;j++){
              if (condListSelect[i].btn[j].isTrue === 1){
                switch (condListSelect[i].btn[j].name) {
                  case '设置':
                    num = [];
                    condListSelect[i].btn[j].val.forEach((item,index) => {
                      if (item){
                        num.push(index);
                      }
                    });
                    if (isOverlap === 1){
                      numList = this.hedEndNumber(numList,num,condListSelect[i].btn[j].isSave);
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.hedEndNumber(numList,num,condListSelect[i].btn[j].isSave));
                    }
                    break;
                }
              }
            }
            break;
          case '定位置':
            for (let j=0;j<condListSelect[i].btn.length;j++){
              if (condListSelect[i].btn[j].isTrue === 1){
                switch (condListSelect[i].btn[j].name) {
                  case '乘号位置':
                    if (isOverlap === 1){
                      numList = this.positionXMethod(numList,condListSelect[i].btn[j].val);
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.positionXMethod(numList,condListSelect[i].btn[j].val));
                    }
                    break;
                  case '批量选码':
                    num = [];
                    num.push(condListSelect[i].btn[j].thousandRadio);
                    num.push(condListSelect[i].btn[j].hundredRadio);
                    num.push(condListSelect[i].btn[j].tenRadio);
                    num.push(condListSelect[i].btn[j].pieceRadio);
                    if (isOverlap === 1){
                      numList = this.batchCodeMethod(numList,num,condListSelect[i].btn[j].isSave);
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.batchCodeMethod(numList,num,condListSelect[i].btn[j].isSave));
                    }
                    break;
                  case '自由选码':
                    num = [[],[],[],[]];
                    condListSelect[i].btn[j].val.forEach((item,index) => {
                      if (item){
                        num[0].push(index);
                      }
                    })
                    condListSelect[i].btn[j].val1.forEach((item,index) => {
                      if (item){
                        num[1].push(index);
                      }
                    })
                    condListSelect[i].btn[j].val2.forEach((item,index) => {
                      if (item){
                        num[2].push(index);
                      }
                    })
                    condListSelect[i].btn[j].val3.forEach((item,index) => {
                      if (item){
                        num[3].push(index);
                      }
                    })
                    if (isOverlap === 1){
                      numList = this.freeCodeMethod(numList,num,condListSelect[i].btn[j].isSave);
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.freeCodeMethod(numList,num,condListSelect[i].btn[j].isSave));
                    }
                    break;
                }
              }
            }
            break;
          case 'AC值':
            for (let j=0;j<condListSelect[i].btn.length;j++){
              if (condListSelect[i].btn[j].isTrue === 1){
                switch (condListSelect[i].btn[j].name) {
                  case '设置':
                    num = [];
                    condListSelect[i].btn[j].val.forEach((item,index) => {
                      if (item){
                        num.push(index + 1);
                      }
                    });
                    if (isOverlap === 1){
                      numList = this.ACnumber(numList,num,condListSelect[i].btn[j].isSave);
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.ACnumber(numList,num,condListSelect[i].btn[j].isSave));
                    }
                    break;
                }
              }
            }
            break;
          case '位积':
            for (let j=0;j<condListSelect[i].btn.length;j++){
              if (condListSelect[i].btn[j].isTrue === 1){
                switch (condListSelect[i].btn[j].name) {
                  case '合值':
                    num = [];
                    condListSelect[i].btn[j].val.forEach((item,index) => {
                      if (item){
                        num.push(index);
                      }
                    });
                    if (isOverlap === 1){
                      numList = this.positionAccu(numList,num,condListSelect[i].btn[j].isSave,'位积','合值');
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.positionAccu(numList,num,condListSelect[i].btn[j].isSave,'位积','合值'));
                    }
                    break;
                  case '和值':
                    num = [];
                    condListSelect[i].btn[j].val.forEach((item,index) => {
                      if (item){
                        num.push(index);
                      }
                    });
                    if (isOverlap === 1){
                      numList = this.positionAccu(numList,num,condListSelect[i].btn[j].isSave,'位积','和值');
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.positionAccu(numList,num,condListSelect[i].btn[j].isSave,'位积','和值'));
                    }
                    break;
                }
              }
            }
            break;
          case '反位积':
            for (let j=0;j<condListSelect[i].btn.length;j++){
              if (condListSelect[i].btn[j].isTrue === 1){
                switch (condListSelect[i].btn[j].name) {
                  case '合值':
                    num = [];
                    condListSelect[i].btn[j].val.forEach((item,index) => {
                      if (item){
                        num.push(index);
                      }
                    });
                    if (isOverlap === 1){
                      numList = this.sumNumber(numList,num,condListSelect[i].btn[j].isSave,'反位积','合值');
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.sumNumber(numList,num,condListSelect[i].btn[j].isSave,'反位积','合值'));
                    }
                    break;
                  case '和值':
                    num = [];
                    condListSelect[i].btn[j].val.forEach((item,index) => {
                      if (item){
                        num.push(index);
                      }
                    });
                    if (isOverlap === 1){
                      numList = this.positionAccu(numList,num,condListSelect[i].btn[j].isSave,'反位积','和值');
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.positionAccu(numList,num,condListSelect[i].btn[j].isSave,'反位积','和值'));
                    }
                    break;
                }
              }
            }
            break;
          case '凹凸升降':
            for (let j=0;j<condListSelect[i].btn.length;j++){
              if (condListSelect[i].btn[j].isTrue === 1){
                switch (condListSelect[i].btn[j].name) {
                  case '设置':
                    if (isOverlap === 1){
                      numList = this.aoTuMethod(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave);
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.aoTuMethod(numList,condListSelect[i].btn[j].val,condListSelect[i].btn[j].isSave));
                    }
                    break;
                }
              }
            }
            break;
          case '减式':
            for (let j=0;j<condListSelect[i].btn.length;j++){
              if (condListSelect[i].btn[j].isTrue === 1){
                switch (condListSelect[i].btn[j].name) {
                  case '合值':
                    num = [];
                    condListSelect[i].btn[j].val.forEach((item,index) => {
                      if (item){
                        num.push(index);
                      }
                    });
                    if (isOverlap === 1){
                      numList = this.decWay(numList,num,condListSelect[i].btn[j].isSave,condListSelect[i].btn[j].add,condListSelect[i].btn[j].dec);
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.decWay(numList,num,condListSelect[i].btn[j].isSave,condListSelect[i].btn[j].add,condListSelect[i].btn[j].dec));
                    }
                    break;
                }
              }
            }
            break;
          case '全转（全倒）':
            for (let j=0;j<condListSelect[i].btn.length;j++){
              if (condListSelect[i].btn[j].isTrue === 1){
                switch (condListSelect[i].btn[j].name) {
                  case '设置':
                    if (isOverlap === 1){
                      switch (this.$store.state.payWay.payType) {
                        case 1:
                          numList = this.filterAllSort(numList,condListSelect[i].btn[j].val,4);
                          break;
                        case 3:
                          numList = this.filterAllSort(numList,condListSelect[i].btn[j].val,3);
                          break;
                        case 5:
                          numList = this.filterAllSort(numList,condListSelect[i].btn[j].val,2);
                          break;
                      }
                    }else if (isOverlap === 2){
                      switch (this.$store.state.payWay.payType) {
                        case 1:
                          overlap = this.deduplicationMethod(overlap,this.filterAllSort(numList,condListSelect[i].btn[j].val,4));
                          break;
                        case 3:
                          overlap = this.deduplicationMethod(overlap,this.filterAllSort(numList,condListSelect[i].btn[j].val,3));
                          break;
                        case 5:
                          overlap = this.deduplicationMethod(overlap,this.filterAllSort(numList,condListSelect[i].btn[j].val,2));
                          break;
                      }
                    }
                    break;
                }
              }
            }
            break;
          case '开奖历史':
            for (let j=0;j<condListSelect[i].btn.length;j++){
              if (condListSelect[i].btn[j].isTrue === 1){
                switch (condListSelect[i].btn[j].name) {
                  case '七星彩':
                    if (isOverlap === 1){
                      numList = this.historyDataMethod(numList,condListSelect[i].btn[j].listNum,condListSelect[i].btn[j].isSave);
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.historyDataMethod(numList,condListSelect[i].btn[j].listNum,condListSelect[i].btn[j].isSave));
                    }
                    break;
                  case '排列五':
                    if (isOverlap === 1){
                      numList = this.historyDataMethod(numList,condListSelect[i].btn[j].listNum,condListSelect[i].btn[j].isSave);
                    }else if (isOverlap === 2){
                      overlap = this.deduplicationMethod(overlap,this.historyDataMethod(numList,condListSelect[i].btn[j].listNum,condListSelect[i].btn[j].isSave));
                    }
                    break;
                }
              }
            }
            break;
        }
      }
      if (isOverlap === 1){
        this.$store.commit('numComputed',numList);
      }else if (isOverlap === 2){
        this.$store.commit('numComputed',overlap);
      }
    },
    //排除码
    deadNumber(list,num){
      return  list.filter((item)=>{
        let flag=true;
        num.forEach((num)=>{
          if(item.includes(num)){
            flag=false;
          }
        })
        if(flag){
          return item;
        }
      })
    },
    //合值(合分)
    sumNumber(list,num,isSave){
      return list.filter(item =>{
        let sum = 0;
        let iteNum = item.toString();
        for (let i=0;i<iteNum.length;i++){
          if (!isNaN(Number(iteNum[i]))){
            sum = sum + parseInt(iteNum[i]);
          }
        }
        let isInclude = false;
        num.forEach((n)=>{
          if(parseInt(sum.toString()[sum.toString().length - 1]) === n){
            isInclude = true;
          }
        })
        if (isSave === 1){
          if (isInclude){
            return item;
          }
        }else {
          if (!isInclude){
            return item;
          }
        }
      })
    },
    //必出码
    mustNumber(list,num){
      return  list.filter((item)=>{
        let flag=false;
        num.forEach((num)=>{
          if(item.includes(num)){
            flag=true;
          }
        })
        if(flag){
          return item;
        }
      })
    },
    //配号码
    mixNumber(list,num){
      return list.filter(item =>{
        let isReturn = 0;
        let itemStr = item.toString().split('');
        // let numList = [];
        // num.forEach(item =>{
        //   numList = numList.concat(item);
        // })
        // itemStr.forEach(itemNum =>{
        //   if (numList.includes(parseInt(itemNum))){
        //     isReturn = isReturn + 1;
        //   }
        // })

        let repeat = [];
        let isBreak = false;
        itemStr.forEach(itemNum =>{
          for (let i=0;i<num.length;i++){
            isBreak = false;
            if (repeat.includes(i)){
              continue;
            }
            for (let j=0;j<num[i].length;j++){
              if (itemNum.toString() === num[i][j].toString()){
                isReturn = isReturn + 1;
                repeat.push(i);
                isBreak = true;
                break;
              }
            }
            if (isBreak){
              break;
            }
          }
        })



        // num.forEach(ite =>{
        //   let itemStr  = item.toString().split('');
        //   repeatIndex.forEach(reNum =>{
        //     itemStr[reNum] = '';
        //   })
        //   itemStr = itemStr.join('');
        //   for (let i = 0;i<ite.length;i++){
        //     if (itemStr.includes(ite[i])){
        //       repeatIndex.push(itemStr.indexOf(ite[i]));
        //       isReturn = isReturn + 1;
        //       break;
        //     }
        //   }
        // })
        if (isReturn === item.length){
          return item;
        }
      })
    },
    mixNumber2(list,num){
      return list.filter((item)=>{
        let list1 = num[0];
        let list2 = num[1];
        let list3 = num[2];
        let list4 = num[3];
        let temp=item
        let flagone=false
        let flagtwo=false
        let flagthree=false
        let flagfour=false
        for(let i=0;i<list1.length;i++){
          temp=item
          if(temp.indexOf(list1[i])!==-1){

            let temp1=temp.replace(list1[i],'')
            flagone=true
            for(let i=0;i<list2.length;i++){
              temp=temp1
              if(temp.indexOf(list2[i])!==-1){
                let temp2=temp.replace(list2[i],'')
                flagtwo=true
                for(let i=0;i<list3.length;i++){
                  temp=temp2
                  if(temp.indexOf(list3[i])!==-1){
                    let temp3=temp.replace(list3[i],'')
                    flagthree=true
                    for(let i=0;i<list4.length;i++){
                      temp=temp3
                      if(temp.indexOf(list4[i])!==-1){
                        temp=temp.replace(list4[i],'')
                        flagfour=true
                      }
                    }
                  }
                }
              }
            }
          }
        }
        if(list1.length===0)
          flagone=true
        if(list2.length===0)
          flagtwo=true
        if(list3.length===0)
          flagthree=true
        if(list4.length===0)
          flagfour=true
        if(flagone&&flagtwo&&flagthree&&flagfour){
          return item
        }
      })
    },
    //连号
    continueNumberCount(list,num,isSave){
      return list.filter((item)=>{
        let flag=false
        let flag2=true
        num.forEach((i)=>{
          if(i==='2'){  //二连号只需满足一个条件即可
            if(isSave===1){
              if(item.includes('0')&&item.includes('1')||
                  item.includes('1')&&item.includes('2')||
                  item.includes('2')&&item.includes('3')||
                  item.includes('3')&&item.includes('4')||
                  item.includes('4')&&item.includes('5')||
                  item.includes('5')&&item.includes('6')||
                  item.includes('6')&&item.includes('7')||
                  item.includes('7')&&item.includes('8')||
                  item.includes('8')&&item.includes('9')||
                  item.includes('9')&&item.includes('0')
              )
                flag=true
            }else{
              if(item.includes('0')&&item.includes('1')||
                  item.includes('1')&&item.includes('2')||
                  item.includes('2')&&item.includes('3')||
                  item.includes('3')&&item.includes('4')||
                  item.includes('4')&&item.includes('5')||
                  item.includes('5')&&item.includes('6')||
                  item.includes('6')&&item.includes('7')||
                  item.includes('7')&&item.includes('8')||
                  item.includes('8')&&item.includes('9')||
                  item.includes('9')&&item.includes('0')
              )
                flag2=false
            }
          }
          else if(i==='3'){ //三连号:012/123/234/345/456/567/678/789/890/901只要四位数出现其中三位，不管顺序，不管位置
            if(isSave===1){
              if(item.includes('0')&&item.includes('1')&&item.includes('2')||
                  item.includes('1')&&item.includes('2')&&item.includes('3')||
                  item.includes('2')&&item.includes('3')&&item.includes('4')||
                  item.includes('3')&&item.includes('4')&&item.includes('5')||
                  item.includes('4')&&item.includes('5')&&item.includes('6')||
                  item.includes('5')&&item.includes('6')&&item.includes('7')||
                  item.includes('6')&&item.includes('7')&&item.includes('8')||
                  item.includes('7')&&item.includes('8')&&item.includes('9')||
                  item.includes('8')&&item.includes('9')&&item.includes('0')||
                  item.includes('9')&&item.includes('0')&&item.includes('1')
              )
                flag=true
            }else{
              if(item.includes('0')&&item.includes('1')&&item.includes('2')||
                  item.includes('1')&&item.includes('2')&&item.includes('3')||
                  item.includes('2')&&item.includes('3')&&item.includes('4')||
                  item.includes('3')&&item.includes('4')&&item.includes('5')||
                  item.includes('4')&&item.includes('5')&&item.includes('6')||
                  item.includes('5')&&item.includes('6')&&item.includes('7')||
                  item.includes('6')&&item.includes('7')&&item.includes('8')||
                  item.includes('7')&&item.includes('8')&&item.includes('9')||
                  item.includes('8')&&item.includes('9')&&item.includes('0')||
                  item.includes('9')&&item.includes('0')&&item.includes('1')
              )
                flag2=false
            }
          }
          else{   //四连号至少需要满足三个条件且个十百千位都不相同
            if(isSave===1){
              if(item.includes('0')&&item.includes('1')&&item.includes('2')&&item.includes('3')||
                  item.includes('1')&&item.includes('2')&&item.includes('3')&&item.includes('4')||
                  item.includes('2')&&item.includes('3')&&item.includes('4')&&item.includes('5')||
                  item.includes('3')&&item.includes('4')&&item.includes('5')&&item.includes('6')||
                  item.includes('4')&&item.includes('5')&&item.includes('6')&&item.includes('7')||
                  item.includes('5')&&item.includes('6')&&item.includes('7')&&item.includes('8')||
                  item.includes('6')&&item.includes('7')&&item.includes('8')&&item.includes('9')||
                  item.includes('7')&&item.includes('8')&&item.includes('9')&&item.includes('0')||
                  item.includes('8')&&item.includes('9')&&item.includes('0')&&item.includes('1')||
                  item.includes('9')&&item.includes('0')&&item.includes('1')&&item.includes('2')
              )
                flag=true
            }else{
              if(item.includes('0')&&item.includes('1')&&item.includes('2')&&item.includes('3')||
                  item.includes('1')&&item.includes('2')&&item.includes('3')&&item.includes('4')||
                  item.includes('2')&&item.includes('3')&&item.includes('4')&&item.includes('5')||
                  item.includes('3')&&item.includes('4')&&item.includes('5')&&item.includes('6')||
                  item.includes('4')&&item.includes('5')&&item.includes('6')&&item.includes('7')||
                  item.includes('5')&&item.includes('6')&&item.includes('7')&&item.includes('8')||
                  item.includes('6')&&item.includes('7')&&item.includes('8')&&item.includes('9')||
                  item.includes('7')&&item.includes('8')&&item.includes('9')&&item.includes('0')||
                  item.includes('8')&&item.includes('9')&&item.includes('0')&&item.includes('1')||
                  item.includes('9')&&item.includes('0')&&item.includes('1')&&item.includes('2')
              )
                flag2=false
            }
          }
        })

        if(flag||(!(isSave===1)&&flag2)){
          return item
        }
      })
    },
    //连号2
    continueNumberCount2(list,num,isSave){
      let two = ['01','12','23','34','45','56','67','78','89','90'];
      let three = ['012','123','234','345','456','567','678','789','890','901'];
      let four = ['0123','1234','2345','3456','4567','5678','6789','7890','8901','9012'];
      return list.filter((item) =>{
        let isReturn = false;
        num.forEach((ite) =>{
          switch (ite) {
            case '2':
              for (let i=0;i<two.length;i++){
                let cutNumber = item.toString().split('');
                if (cutNumber.includes(two[i].charAt(0))){
                  cutNumber.splice(cutNumber.indexOf(two[i].charAt(0)),1);
                  if (cutNumber.includes(two[i].charAt(1))){
                    isReturn = true;
                  }
                }
                if (isReturn){
                  break;
                }
              }
              break;
            case '3':
              for (let i=0;i<three.length;i++){
                let cutNumber = item.toString().split('');
                if (cutNumber.includes(three[i].charAt(0))){
                  cutNumber.splice(cutNumber.indexOf(three[i].charAt(0)),1);
                  if (cutNumber.includes(three[i].charAt(1))){
                    cutNumber.splice(cutNumber.indexOf(three[i].charAt(1)),1);
                    if (cutNumber.includes(three[i].charAt(2))){
                      isReturn = true;
                    }
                  }
                }
                if (isReturn){
                  break;
                }
              }
              break;
            case '4':
              for (let i=0;i<four.length;i++){
                let cutNumber = item.toString().split('');
                if (cutNumber.includes(four[i].charAt(0))){
                  cutNumber.splice(cutNumber.indexOf(four[i].charAt(0)),1);
                  if (cutNumber.includes(four[i].charAt(1))){
                    cutNumber.splice(cutNumber.indexOf(four[i].charAt(1)),1);
                    if (cutNumber.includes(four[i].charAt(2))){
                      cutNumber.splice(cutNumber.indexOf(four[i].charAt(2)),1);
                      if (cutNumber.includes(four[i].charAt(3))){
                        isReturn = true;
                      }
                    }
                  }
                }
                if (isReturn){
                  break;
                }
              }
              break;
          }
        })
        if (isSave === 1 && isReturn){
          return item;
        }else if (isSave === 0 && !isReturn){
          return item;
        }
      })
    },
    //重数
    repeatNumberCount(list,num,isSave){
      return list.filter((item)=>{
        let ge= item.substr(3,1)==='X'?'X':parseInt(item.substr(3,1))
        let shi=item.substr(2,1)==='X'?'X':parseInt(item.substr(2,1))
        let bai=item.substr(1,1)==='X'?'X':parseInt(item.substr(1,1))
        let qian=item.substr(0,1)==='X'?'X':parseInt(item.substr(0,1))

        let flag=false
        let flag2=true
        num.forEach((i)=>{
          if(i==='2'){//双重ab ac ad bc bd cd
            if(isSave===1){
              if((qian===bai&&qian!=='X')||(qian===shi&&qian!=='X')||(qian===ge&&qian!=='X')||(bai===shi&&bai!=='X')||(bai===ge&&bai!=='X')||(shi===ge&&shi!=='X'))
                flag=true
            }else{
              if((qian===bai&&qian!=='X')||(qian===shi&&qian!=='X')||(qian===ge&&qian!=='X')||(bai===shi&&bai!=='X')||(bai===ge&&bai!=='X')||(shi===ge&&shi!=='X'))
                flag2=false
            }
          }
          if(i==='22'){//双双重 ab&&cd ac&&bd ad&&bc
            if(isSave===1){
              if(qian===bai&&shi===ge||qian===shi&&bai===ge||qian===ge&&bai===shi)
                flag=true
            }else{
              if(qian===bai&&shi===ge||qian===shi&&bai===ge||qian===ge&&bai===shi)
                flag2=false
            }
          }
          if(i==='3'){//三重 abc abd acd bcd
            if(isSave===1){
              if(qian===bai&&bai===shi||qian===shi&&shi===ge||qian===bai&&bai===ge||bai===shi&&shi===ge)
                flag=true
            }else{
              if(qian===bai&&bai===shi||qian===shi&&shi===ge||qian===bai&&bai===ge||bai===shi&&shi===ge)
                flag2=false
            }
          }
          if(i==='4'){//四重abcd
            if(isSave===1){
              if(qian===bai&&bai===shi&&shi===ge)
                flag=true
            }else{
              if(qian===bai&&bai===shi&&shi===ge)
                flag2=false
            }
          }
        })
        if(flag || (isSave === 0 && flag2))
          return item

      })

    },
    //重数2
    repeatNumberCount2(list,num,isSave){
      let two = ['0','1','2','3','4','5','6','7','8','9'];
      return list.filter((item) =>{
        let isReturn = false;
        num.forEach((ite) =>{
          switch (ite) {
            case '2':
              for (let i=0;i<two.length;i++){
                let cutNumber = item.toString().split('');
                if (cutNumber.includes(two[i])){
                  cutNumber.splice(item.indexOf(two[i]),1);
                  if (cutNumber.includes(two[i])){
                    isReturn = true;
                  }
                }
                if (isReturn){
                  break;
                }
              }
              break;
            case '3':
              for (let i=0;i<two.length;i++){
                let cutNumber = item.toString().split('');
                if (cutNumber.includes(two[i])){
                  cutNumber.splice(item.indexOf(two[i]),1);
                  if (cutNumber.includes(two[i])){
                    cutNumber.splice(item.indexOf(two[i]),1);
                    if (cutNumber.includes(two[i])){
                      isReturn = true;
                    }
                  }
                }
                if (isReturn){
                  break;
                }
              }
              break;
            case '4':
              for (let i=0;i<two.length;i++){
                let cutNumber = item.toString().split('');
                if (cutNumber.includes(two[i])){
                  cutNumber.splice(item.indexOf(two[i]),1);
                  if (cutNumber.includes(two[i])){
                    cutNumber.splice(item.indexOf(two[i]),1);
                    if (cutNumber.includes(two[i])){
                      cutNumber.splice(item.indexOf(two[i]),1);
                      if (cutNumber.includes(two[i])){
                        isReturn = true;
                      }
                    }
                  }
                }
                if (isReturn){
                  break;
                }
              }
              break;
          }
        })
        if (isSave === 1 && isReturn){
          return item;
        }else if (isSave === 0 && !isReturn){
          return item;
        }
      })
    },
    //中肚有数
    midNumber(list,num,isSave){
      return list.filter((item)=>{
        let shi=item.substr(2,1)==='X'?'X':parseInt(item.substr(2,1))
        let bai=item.substr(1,1)==='X'?'X':parseInt(item.substr(1,1))
        let flag=false
        let flag2=true
        num.forEach((i)=>{
          if(isSave===1){
            if(shi===i||bai===i)
              flag=true
          }else{
            if(shi===i||bai===i)
              flag2=false
          }
        })
        if(flag||(!(isSave===1)&&flag2))
          return item
      })
    },
    //头尾有数
    hedEndNumber(list,num,isSave){
      return list.filter((item)=>{
        let ge= item.substr(3,1)==='X'?'X':parseInt(item.substr(3,1))
        let qian=item.substr(0,1)==='X'?'X':parseInt(item.substr(0,1))

        let flag=false
        let flag2=true
        num.forEach((i)=>{
          if(isSave===1){
            if(ge===i||qian===i)
              flag=true
          }else{
            if(ge===i||qian===i)
              flag2=false
          }
        })
        if(flag||(!(isSave===1)&&flag2))
          return item
      })
    },
    //最大值
    maxNumber(list,num,isSave){
      return list.filter((item) =>{
        let isReturn = false;
        num.forEach((ite) =>{
          if (ite === Math.max.apply(null,item.toString().replaceAll('X','').split('')))
          {
            isReturn = true;
          }
        })
        if (isReturn && isSave === 1){
          return item;
        }else if (!isReturn && isSave === 0){
          return item;
        }
      })
    },
    //最小值
    minNumber(list,num,isSave){
      return list.filter((item) =>{
        let isReturn = false;
        num.forEach((ite) =>{
          if (ite === Math.min.apply(null,item.toString().replaceAll('X','').split('')))
          {
            isReturn = true;
          }
        })
        if (isReturn && isSave === 1){
          return item;
        }else if (!isReturn && isSave === 0){
          return item;
        }
      })
    },
    //跨度
    spanNumber(list,num,isSave){
      return list.filter((item) =>{
        let isReturn = false;
        let max = Math.max.apply(null,item.toString().replaceAll('X','').split(''));
        let min = Math.min.apply(null,item.toString().replaceAll('X','').split(''));
        num.forEach((ite) =>{
          if (ite === max-min){
            isReturn = true;
          }
        })
        if (isReturn && isSave === 1){
          return item;
        }else if (!isReturn && isSave === 0){
          return item;
        }
      })
    },
    //合值加跨度
    spanAddSum(list,num,isSave){
      return list.filter((item) =>{
        let isReturn = false;
        let sum = 0;
        item.toString().replaceAll('X','').split('').forEach((addNum) =>{
          sum = sum + parseInt(addNum);
        })
        if (sum.toString().length > 1){
          sum = parseInt(sum.toString().charAt(1));
        }
        let span = Math.max.apply(null,item.toString().replaceAll('X','').split('')) - Math.min.apply(null,item.toString().replaceAll('X','').split(''));
        num.forEach((ite) =>{
          if (parseInt(ite) === (sum + span)){
            isReturn = true;
          }
        })
        if (isReturn && isSave === 1){
          return item;
        }else if (!isReturn && isSave === 0){
          return item;
        }
      })
    },
    //合值减跨度
    sumSubSpan(list,num,isSave){
      return list.filter((item) =>{
        let isReturn = false;
        let sum = 0;
        item.toString().replaceAll('X','').split('').forEach((addNum) =>{
          sum = sum + parseInt(addNum);
        })
        if (sum.toString().length > 1){
          sum = parseInt(sum.toString().charAt(1));
        }
        let span = Math.max.apply(null,item.toString().replaceAll('X','').split('')) - Math.min.apply(null,item.toString().replaceAll('X','').split(''));
        num.forEach((ite) =>{
          if (parseInt(ite) === (sum - span)){
            isReturn = true;
          }
        })
        if (isReturn && isSave === 1){
          return item;
        }else if (!isReturn && isSave === 0){
          return item;
        }
      })
    },
    //余数和
    remNumber(list,num,isSave){
      return list.filter((item) =>{
        let isReturn = false;
        let sum = 0;
        item.toString().replaceAll('X','').split('').forEach((ite) =>{
          sum = sum + parseInt(ite) % 3;
        })
        num.forEach((remSum) =>{
          if (remSum === sum){
            isReturn = true;
          }
        })
        if (isReturn && isSave === 1){
          return item;
        }else if (!isReturn && isSave === 0){
          return item;
        }
      })
    },
    //和值
    sumAllNumber(list,num,isSave){
      return list.filter((item) =>{
        let isReturn = false;
        let sum = 0;
        item.toString().replaceAll('X','').split('').forEach((ite) =>{
          sum = sum + parseInt(ite);
        })
        num.forEach((sumNum) =>{
          if (sumNum === sum){
            isReturn = true;
          }
        })
        if (isReturn && isSave === 1){
          return item;
        }else if (!isReturn && isSave === 0){
          return item;
        }
      })
    },
    //乘积
    multipleMethod(list,num,isSave,multiplyWay){
      return list.filter((item) =>{
        let isReturn = false;
        let itemString = item.toString();
        let multSum = 1;
        if (multiplyWay !== ''){
          num.forEach((ite) =>{
            multiplyWay.forEach((mult) =>{
              switch (mult) {
                case 'ab':
                  if (itemString.charAt(0) !== 'X' && itemString.charAt(1) !== 'X'){
                    multSum = parseInt((parseInt(itemString.charAt(0)) * parseInt(itemString.charAt(1))).toString().split('').pop());
                    if (multSum === ite){
                      isReturn = true;
                    }
                  }
                  break;
                case 'ac':
                  if (itemString.charAt(0) !== 'X' && itemString.charAt(2) !== 'X'){
                    multSum = parseInt((parseInt(itemString.charAt(0)) * parseInt(itemString.charAt(2))).toString().split('').pop());
                    if (multSum === ite){
                      isReturn = true;
                    }
                  }
                  break;
                case 'ad':
                  if (itemString.charAt(0) !== 'X' && itemString.charAt(3) !== 'X'){
                    multSum = parseInt((parseInt(itemString.charAt(0)) * parseInt(itemString.charAt(3))).toString().split('').pop());
                    if (multSum === ite){
                      isReturn = true;
                    }
                  }
                  break;
                case 'bc':
                  if (itemString.charAt(1) !== 'X' && itemString.charAt(2) !== 'X'){
                    multSum = parseInt((parseInt(itemString.charAt(1)) * parseInt(itemString.charAt(2))).toString().split('').pop());
                    if (multSum === ite){
                      isReturn = true;
                    }
                  }
                  break;
                case 'bd':
                  if (itemString.charAt(1) !== 'X' && itemString.charAt(3) !== 'X'){
                    multSum = parseInt((parseInt(itemString.charAt(1)) * parseInt(itemString.charAt(3))).toString().split('').pop());
                    if (multSum === ite){
                      isReturn = true;
                    }
                  }
                  break;
                case 'cd':
                  if (itemString.charAt(2) !== 'X' && itemString.charAt(3) !== 'X'){
                    multSum = parseInt((parseInt(itemString.charAt(2)) * parseInt(itemString.charAt(3))).toString().split('').pop());
                    if (multSum === ite){
                      isReturn = true;
                    }
                  }
                  break;
                case 'abc':
                  if (itemString.charAt(0) !== 'X' && itemString.charAt(1) !== 'X' && itemString.charAt(2) !== 'X'){
                    multSum = parseInt((parseInt(itemString.charAt(0)) * parseInt(itemString.charAt(1)) * parseInt(itemString.charAt(2))).toString().split('').pop());
                    if (multSum === ite){
                      isReturn = true;
                    }
                  }
                  break;
                case 'abd':
                  if (itemString.charAt(0) !== 'X' && itemString.charAt(1) !== 'X' && itemString.charAt(3) !== 'X'){
                    multSum = parseInt((parseInt(itemString.charAt(0)) * parseInt(itemString.charAt(1)) * parseInt(itemString.charAt(3))).toString().split('').pop());
                    if (multSum === ite){
                      isReturn = true;
                    }
                  }
                  break;
                case 'acd':
                  if (itemString.charAt(0) !== 'X' && itemString.charAt(2) !== 'X' && itemString.charAt(3) !== 'X'){
                    multSum = parseInt((parseInt(itemString.charAt(0)) * parseInt(itemString.charAt(2)) * parseInt(itemString.charAt(3))).toString().split('').pop());
                    if (multSum === ite){
                      isReturn = true;
                    }
                  }
                  break;
                case 'bcd':
                  if (itemString.charAt(1) !== 'X' && itemString.charAt(2) !== 'X' && itemString.charAt(3) !== 'X'){
                    multSum = parseInt((parseInt(itemString.charAt(1)) * parseInt(itemString.charAt(2)) * parseInt(itemString.charAt(3))).toString().split('').pop());
                    if (multSum === ite){
                      isReturn = true;
                    }
                  }
                  break;
              }
            })
          })
        }else {
          num.forEach((ite) =>{
            multSum = 1;
            itemString.split('').forEach((multNumber) =>{
              multSum = multSum * parseInt(multNumber);
            })
            if (parseInt(multSum.toString().split('').pop()) === ite){
              isReturn = true;
            }
          })
        }
        if (isReturn && isSave === 1){
          return item;
        }else if (!isReturn && isSave === 0){
          return item;
        }
      })
    },
    //对数
    coupleNumberMethod(list,num,isSave){
      let coupNum = ['05','16','27','38','49'];
      return list.filter((item) =>{
        let isReturn = false;
        num.forEach((ite) =>{
          switch (ite) {
            case '1':
              for (let i=0;i<coupNum.length;i++){
                let itemList = item.toString().split('');
                if (itemList.includes(coupNum[i].charAt(0).toString())){
                  itemList.splice(itemList.indexOf(coupNum[i].charAt(0).toString()),1);
                  if (itemList.includes(coupNum[i].charAt(1).toString())){
                    isReturn = true;
                  }
                }
              }
              break;
            case '2':
              for (let i=0;i<coupNum.length;i++){
                let itemList = item.toString().split('');
                if (itemList.includes(coupNum[i].charAt(0).toString())){
                  itemList.splice(itemList.indexOf(coupNum[i].charAt(0).toString()),1);
                  if (itemList.includes(coupNum[i].charAt(1).toString())){
                    itemList.splice(itemList.indexOf(coupNum[i].charAt(1).toString()),1);
                    for (let j=0;j<coupNum.length;j++){
                      if (itemList.includes(coupNum[j].charAt(0).toString())){
                        itemList.splice(itemList.indexOf(coupNum[j].charAt(0).toString()),1);
                        if (itemList.includes(coupNum[j].charAt(1).toString())){
                          isReturn = true;
                        }
                      }
                    }
                  }
                }
              }
              break;
          }
        })
        if (isReturn && isSave === 1){
          return item;
        }else if (!isReturn && isSave === 0){
          return item;
        }
      })
    },
    //有数
    haveNumberMethod(list,num,isSave,haveWay){
      return list.filter((item) =>{
        let isReturn = false;
        if (haveWay !== ''){
          let itemList = item.toString().split('');
          num.forEach((ite) =>{
            haveWay.forEach((pos) =>{
              switch (pos) {
                case 'ab':
                  if (itemList[0] === ite.toString() || itemList[1] === ite.toString()){
                    isReturn = true;
                  }
                  break;
                case 'ac':
                  if (itemList[0] === ite.toString() || itemList[2] === ite.toString()){
                    isReturn = true;
                  }
                  break;
                case 'ad':
                  if (itemList[0] === ite.toString() || itemList[3] === ite.toString()){
                    isReturn = true;
                  }
                  break;
                case 'bc':
                  if (itemList[1] === ite.toString() || itemList[2] === ite.toString()){
                    isReturn = true;
                  }
                  break;
                case 'bd':
                  if (itemList[1] === ite.toString() || itemList[3] === ite.toString()){
                    isReturn = true;
                  }
                  break;
                case 'cd':
                  if (itemList[2] === ite.toString() || itemList[3] === ite.toString()){
                    isReturn = true;
                  }
                  break;
                case 'abc':
                  if (itemList[0] === ite.toString() || itemList[1] === ite.toString() || itemList[2] === ite.toString()){
                    isReturn = true;
                  }
                  break;
                case 'abd':
                  if (itemList[0] === ite.toString() || itemList[1] === ite.toString() || itemList[3] === ite.toString()){
                    isReturn = true;
                  }
                  break;
                case 'acd':
                  if (itemList[0] === ite.toString() || itemList[2] === ite.toString() || itemList[3] === ite.toString()){
                    isReturn = true;
                  }
                  break;
                case 'bcd':
                  if (itemList[1] === ite.toString() || itemList[2] === ite.toString() || itemList[3] === ite.toString()){
                    isReturn = true;
                  }
                  break;

              }
            })
          })
        }else {
          let itemList = item.toString().split('');
          num.forEach((ite) =>{
            if (itemList.includes(ite.toString()))
            {
              isReturn = true;
            }
          })
        }
        if (isReturn && isSave === 1){
          return item;
        }else if (!isReturn && isSave === 0){
          return item;
        }
      })
    },
    //两数合
    twoSumMethod(list,num,isSave,sumWay){
      return list.filter((item) =>{
        let isReturn = false;
        if (sumWay !== ''){
          num.forEach((ite) =>{
            sumWay.forEach((sw) =>{
              switch (sw) {
                case 'ab':{
                  let sum = 0;
                  if (item.toString().charAt(0) === 'X' || item.toString().charAt(1) === 'X')
                  {
                    break;
                  }else {
                    sum = parseInt(item.toString().charAt(0)) + parseInt(item.toString().charAt(1));
                    sum = parseInt(sum.toString().charAt(sum.toString().length - 1));
                    if (sum === ite){
                      isReturn = true;
                    }
                  }
                  break;
                }
               case 'ac':{
                 let sum = 0;
                 if (item.toString().charAt(0) === 'X' || item.toString().charAt(2) === 'X')
                 {
                   break;
                 }else {
                   sum = parseInt(item.toString().charAt(0)) + parseInt(item.toString().charAt(2));
                   sum = parseInt(sum.toString().charAt(sum.toString().length - 1));
                   if (sum === ite){
                     isReturn = true;
                   }
                 }
                 break;
               }
                case 'ad':{
                  let sum = 0;
                  if (item.toString().charAt(0) === 'X' || item.toString().charAt(3) === 'X')
                  {
                    break;
                  }else {
                    sum = parseInt(item.toString().charAt(0)) + parseInt(item.toString().charAt(3));
                    sum = parseInt(sum.toString().charAt(sum.toString().length - 1));
                    if (sum === ite){
                      isReturn = true;
                    }
                  }
                  break;
                }
                case 'bc':{
                  let sum = 0;
                  if (item.toString().charAt(1) === 'X' || item.toString().charAt(2) === 'X')
                  {
                    break;
                  }else {
                    sum = parseInt(item.toString().charAt(1)) + parseInt(item.toString().charAt(2));
                    sum = parseInt(sum.toString().charAt(sum.toString().length - 1));
                    if (sum === ite){
                      isReturn = true;
                    }
                  }
                  break;
                }
                case 'bd':{
                  let sum = 0;
                  if (item.toString().charAt(1) === 'X' || item.toString().charAt(3) === 'X')
                  {
                    break;
                  }else {
                    sum = parseInt(item.toString().charAt(1)) + parseInt(item.toString().charAt(3));
                    sum = parseInt(sum.toString().charAt(sum.toString().length - 1));
                    if (sum === ite){
                      isReturn = true;
                    }
                  }
                  break;
                }
                case 'cd':{
                  let sum = 0;
                  if (item.toString().charAt(2) === 'X' || item.toString().charAt(3) === 'X')
                  {
                    break;
                  }else {
                    sum = parseInt(item.toString().charAt(2)) + parseInt(item.toString().charAt(3));
                    sum = parseInt(sum.toString().charAt(sum.toString().length - 1));
                    if (sum === ite){
                      isReturn = true;
                    }
                  }
                  break;
                }
              }
            })
          })
        }else {
          let itemLength = item.toString().replaceAll('X','').length;
          num.forEach((ite) =>{
            switch (itemLength) {
              case 2:{
                let twoNum = item.toString().replaceAll('X','');
                let sum = parseInt(twoNum.charAt(0)) + parseInt(twoNum.charAt(1));
                sum = parseInt(sum.toString().charAt(sum.toString().length - 1));
                if (ite === sum){
                  isReturn = true;
                }
                break;
              }
              case 3:{
                let threeNum = item.toString().replaceAll('X','');
                let ab = parseInt(threeNum.charAt(0)) + parseInt(threeNum.charAt(1));
                let ac = parseInt(threeNum.charAt(0)) + parseInt(threeNum.charAt(2));
                let bc = parseInt(threeNum.charAt(1)) + parseInt(threeNum.charAt(2));
                let abSum = parseInt(ab.toString().charAt(ab.toString().length - 1));
                let acSum = parseInt(ac.toString().charAt(ac.toString().length - 1));
                let bcSum = parseInt(bc.toString().charAt(bc.toString().length - 1));
                if (abSum === ite || acSum === ite || bcSum === ite){
                  isReturn = true;
                }
                break;
              }
              case 4:{
                let threeNum = item.toString().replaceAll('X','');
                let ab = parseInt(threeNum.charAt(0)) + parseInt(threeNum.charAt(1));
                let ac = parseInt(threeNum.charAt(0)) + parseInt(threeNum.charAt(2));
                let ad = parseInt(threeNum.charAt(0)) + parseInt(threeNum.charAt(3));
                let bc = parseInt(threeNum.charAt(1)) + parseInt(threeNum.charAt(2));
                let bd = parseInt(threeNum.charAt(1)) + parseInt(threeNum.charAt(3));
                let cd = parseInt(threeNum.charAt(2)) + parseInt(threeNum.charAt(3));
                let abSum = parseInt(ab.toString().charAt(ab.toString().length - 1));
                let acSum = parseInt(ac.toString().charAt(ac.toString().length - 1));
                let adSum = parseInt(ad.toString().charAt(ad.toString().length - 1));
                let bcSum = parseInt(bc.toString().charAt(bc.toString().length - 1));
                let bdSum = parseInt(bd.toString().charAt(bd.toString().length - 1));
                let cdSum = parseInt(cd.toString().charAt(cd.toString().length - 1));
                if (abSum === ite || acSum === ite || adSum === ite || bcSum === ite || bdSum === ite || cdSum === ite){
                  isReturn = true;
                }
                break;
              }
            }
          })
        }
        if (isReturn && isSave === 1){
          return item;
        }else if (!isReturn && isSave === 0){
          return item;
        }
      })
    },
    //三数合
    threeSumMethod(list,num,isSave,sumWay){
      return list.filter((item) =>{
        let isReturn = false;
        if (sumWay !== ''){
          num.forEach((ite) =>{
            sumWay.forEach((sw) =>{
              switch (sw) {
                case 'abc':{
                  let sum = 0;
                  if (item.toString().charAt(0) === 'X' || item.toString().charAt(1) === 'X' || item.toString().charAt(2) === 'X')
                  {
                    break;
                  }else {
                    sum = parseInt(item.toString().charAt(0)) + parseInt(item.toString().charAt(1)) + parseInt(item.toString().charAt(2));
                    sum = parseInt(sum.toString().charAt(sum.toString().length - 1));
                    if (sum === ite){
                      isReturn = true;
                    }
                  }
                  break;
                }
                case 'abd':{
                  let sum = 0;
                  if (item.toString().charAt(0) === 'X' || item.toString().charAt(1) === 'X' || item.toString().charAt(3) === 'X')
                  {
                    break;
                  }else {
                    sum = parseInt(item.toString().charAt(0)) + parseInt(item.toString().charAt(1)) + parseInt(item.toString().charAt(3));
                    sum = parseInt(sum.toString().charAt(sum.toString().length - 1));
                    if (sum === ite){
                      isReturn = true;
                    }
                  }
                  break;
                }
                case 'acd':{
                  let sum = 0;
                  if (item.toString().charAt(0) === 'X' || item.toString().charAt(2) === 'X' || item.toString().charAt(3) === 'X')
                  {
                    break;
                  }else {
                    sum = parseInt(item.toString().charAt(0)) + parseInt(item.toString().charAt(2)) + parseInt(item.toString().charAt(3));
                    sum = parseInt(sum.toString().charAt(sum.toString().length - 1));
                    if (sum === ite){
                      isReturn = true;
                    }
                  }
                  break;
                }
                case 'bcd':{
                  let sum = 0;
                  if (item.toString().charAt(1) === 'X' || item.toString().charAt(2) === 'X' || item.toString().charAt(3) === 'X')
                  {
                    break;
                  }else {
                    sum = parseInt(item.toString().charAt(1)) + parseInt(item.toString().charAt(2)) + parseInt(item.toString().charAt(3));
                    sum = parseInt(sum.toString().charAt(sum.toString().length - 1));
                    if (sum === ite){
                      isReturn = true;
                    }
                  }
                  break;
                }
              }
            })
          })
        }else {
          let itemLength = item.toString().replaceAll('X','').length;
          num.forEach((ite) =>{
            switch (itemLength) {
              case 3:{
                let threeNum = item.toString().replaceAll('X','');
                let sum = parseInt(threeNum.charAt(0)) + parseInt(threeNum.charAt(1)) + parseInt(threeNum.charAt(2));
                sum = parseInt(sum.toString().charAt(sum.toString().length - 1));
                if (ite === sum){
                  isReturn = true;
                }
                break;
              }
              case 4:{
                let threeNum = item.toString().replaceAll('X','');
                let abc = parseInt(threeNum.charAt(0)) + parseInt(threeNum.charAt(1)) + parseInt(threeNum.charAt(2));
                let abd = parseInt(threeNum.charAt(0)) + parseInt(threeNum.charAt(1)) + parseInt(threeNum.charAt(3));
                let acd = parseInt(threeNum.charAt(0)) + parseInt(threeNum.charAt(2)) + parseInt(threeNum.charAt(3));
                let bcd = parseInt(threeNum.charAt(1)) + parseInt(threeNum.charAt(2)) + parseInt(threeNum.charAt(3));
                let abcSum = parseInt(abc.toString().charAt(abc.toString().length - 1));
                let abdSum = parseInt(abd.toString().charAt(abd.toString().length - 1));
                let acdSum = parseInt(acd.toString().charAt(acd.toString().length - 1));
                let bcdSum = parseInt(bcd.toString().charAt(bcd.toString().length - 1));
                if (abcSum === ite || abdSum === ite || acdSum === ite || bcdSum === ite){
                  isReturn = true;
                }
                break;
              }
            }
          })
        }
        if (isReturn && isSave === 1){
          return item;
        }else if (!isReturn && isSave === 0){
          return item;
        }
      })
    },
    //按位快选
    positionQuickChose(list,num,isSave,title){
      return list.filter((item) =>{
        if (isSave === 1){
          let isReturn = 0;
          let isX = false;
          switch (title) {
            case '单双（奇偶）':
              num.forEach((ite,index) =>{
                switch (index) {
                  case 0:
                    if (item.toString().charAt(0) === 'X') {
                      if (parseInt(ite) !== 0){
                        isX = true;
                      }
                      isReturn = isReturn + 1;
                      break;
                    }
                    if (parseInt(ite) === 0){
                      isReturn = isReturn + 1;
                      break;
                    }else if (parseInt(ite) === 1){
                      if (parseInt(item.toString().charAt(0)) % 2 !== 0){
                        isReturn = isReturn +1;
                      }
                    }else if (parseInt(ite) === 2){
                      if (parseInt(item.toString().charAt(0)) % 2 === 0){
                        isReturn = isReturn +1;
                      }
                    }
                    break;
                  case 1:
                    if (item.toString().charAt(1) === 'X') {
                      if (parseInt(ite) !== 0){
                        isX = true;
                      }
                      isReturn = isReturn + 1;
                      break;
                    }
                    if (parseInt(ite) === 0){
                      isReturn = isReturn + 1;
                      break;
                    }else if (parseInt(ite) === 1){
                      if (parseInt(item.toString().charAt(1)) % 2 !== 0){
                        isReturn = isReturn +1;
                      }
                    }else if (parseInt(ite) === 2){
                      if (parseInt(item.toString().charAt(1)) % 2 === 0){
                        isReturn = isReturn +1;
                      }
                    }
                    break;
                  case 2:
                    if (item.toString().charAt(2) === 'X') {
                      if (parseInt(ite) !== 0){
                        isX = true;
                      }
                      isReturn = isReturn + 1;
                      break;
                    }
                    if (parseInt(ite) === 0){
                      isReturn = isReturn + 1;
                      break;
                    }else if (parseInt(ite) === 1){
                      if (parseInt(item.toString().charAt(2)) % 2 !== 0){
                        isReturn = isReturn +1;
                      }
                    }else if (parseInt(ite) === 2){
                      if (parseInt(item.toString().charAt(2)) % 2 === 0){
                        isReturn = isReturn +1;
                      }
                    }
                    break;
                  case 3:
                    if (item.toString().charAt(3) === 'X') {
                      if (parseInt(ite) !== 0){
                        isX = true;
                      }
                      isReturn = isReturn + 1;
                      break;
                    }
                    if (parseInt(ite) === 0){
                      isReturn = isReturn + 1;
                      break;
                    }else if (parseInt(ite) === 1){
                      if (parseInt(item.toString().charAt(3)) % 2 !== 0){
                        isReturn = isReturn +1;
                      }
                    }else if (parseInt(ite) === 2){
                      if (parseInt(item.toString().charAt(3)) % 2 === 0){
                        isReturn = isReturn +1;
                      }
                    }
                    break;
                }
              })
              break;
            case '大小':
              num.forEach((ite,index) =>{
                switch (index) {
                  case 0:
                    if (item.toString().charAt(0) === 'X') {
                      if (parseInt(ite) !== 0){
                        isX = true;
                      }
                      isReturn = isReturn + 1;
                      break;
                    }
                    if (parseInt(ite) === 0){
                      isReturn = isReturn + 1;
                      break;
                    }else if (parseInt(ite) === 1){
                      if ([5,6,7,8,9].includes(parseInt(item.toString().charAt(0)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 2){
                      if ([0,1,2,3,4].includes(parseInt(item.toString().charAt(0)))){
                        isReturn = isReturn + 1;
                      }
                    }
                    break;
                  case 1:
                    if (item.toString().charAt(1) === 'X') {
                      if (parseInt(ite) !== 0){
                        isX = true;
                      }
                      isReturn = isReturn + 1;
                      break;
                    }
                    if (parseInt(ite) === 0){
                      isReturn = isReturn + 1;
                      break;
                    }else if (parseInt(ite) === 1){
                      if ([5,6,7,8,9].includes(parseInt(item.toString().charAt(1)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 2){
                      if ([0,1,2,3,4].includes(parseInt(item.toString().charAt(1)))){
                        isReturn = isReturn + 1;
                      }
                    }
                    break;
                  case 2:
                    if (item.toString().charAt(2) === 'X') {
                      if (parseInt(ite) !== 0){
                        isX = true;
                      }
                      isReturn = isReturn + 1;
                      break;
                    }
                    if (parseInt(ite) === 0){
                      isReturn = isReturn + 1;
                      break;
                    }else if (parseInt(ite) === 1){
                      if ([5,6,7,8,9].includes(parseInt(item.toString().charAt(2)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 2){
                      if ([0,1,2,3,4].includes(parseInt(item.toString().charAt(2)))){
                        isReturn = isReturn + 1;
                      }
                    }
                    break;
                  case 3:
                    if (item.toString().charAt(3) === 'X') {
                      if (parseInt(ite) !== 0){
                        isX = true;
                      }
                      isReturn = isReturn + 1;
                      break;
                    }
                    if (parseInt(ite) === 0){
                      isReturn = isReturn + 1;
                      break;
                    }else if (parseInt(ite) === 1){
                      if ([5,6,7,8,9].includes(parseInt(item.toString().charAt(3)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 2){
                      if ([0,1,2,3,4].includes(parseInt(item.toString().charAt(3)))){
                        isReturn = isReturn + 1;
                      }
                    }
                    break;
                }
              })
              break;
            case '质合':
              num.forEach((ite,index) =>{
                switch (index) {
                  case 0:
                    if (item.toString().charAt(0) === 'X') {
                      if (parseInt(ite) !== 0){
                        isX = true;
                      }
                      isReturn = isReturn + 1;
                      break;
                    }
                    if (parseInt(ite) === 0){
                      isReturn = isReturn + 1;
                      break;
                    }else if (parseInt(ite) === 1){
                      if ([1,2,3,5,7].includes(parseInt(item.toString().charAt(0)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 2){
                      if ([0,4,6,8,9].includes(parseInt(item.toString().charAt(0)))){
                        isReturn = isReturn + 1;
                      }
                    }
                    break;
                  case 1:
                    if (item.toString().charAt(1) === 'X') {
                      if (parseInt(ite) !== 0){
                        isX = true;
                      }
                      isReturn = isReturn + 1;
                      break;
                    }
                    if (parseInt(ite) === 0){
                      isReturn = isReturn + 1;
                      break;
                    }else if (parseInt(ite) === 1){
                      if ([1,2,3,5,7].includes(parseInt(item.toString().charAt(1)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 2){
                      if ([0,4,6,8,9].includes(parseInt(item.toString().charAt(1)))){
                        isReturn = isReturn + 1;
                      }
                    }
                    break;
                  case 2:
                    if (item.toString().charAt(2) === 'X') {
                      if (parseInt(ite) !== 0){
                        isX = true;
                      }
                      isReturn = isReturn + 1;
                      break;
                    }
                    if (parseInt(ite) === 0){
                      isReturn = isReturn + 1;
                      break;
                    }else if (parseInt(ite) === 1){
                      if ([1,2,3,5,7].includes(parseInt(item.toString().charAt(2)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 2){
                      if ([0,4,6,8,9].includes(parseInt(item.toString().charAt(2)))){
                        isReturn = isReturn + 1;
                      }
                    }
                    break;
                  case 3:
                    if (item.toString().charAt(3) === 'X') {
                      if (parseInt(ite) !== 0){
                        isX = true;
                      }
                      isReturn = isReturn + 1;
                      break;
                    }
                    if (parseInt(ite) === 0){
                      isReturn = isReturn + 1;
                      break;
                    }else if (parseInt(ite) === 1){
                      if ([1,2,3,5,7].includes(parseInt(item.toString().charAt(3)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 2){
                      if ([0,4,6,8,9].includes(parseInt(item.toString().charAt(3)))){
                        isReturn = isReturn + 1;
                      }
                    }
                    break;
                }
              })
              break;
            case '大中小':
              num.forEach((ite,index) =>{
                switch (index) {
                  case 0:
                    if (item.toString().charAt(0) === 'X') {
                      if (parseInt(ite) !== 0){
                        isX = true;
                      }
                      isReturn = isReturn + 1;
                      break;
                    }
                    if (parseInt(ite) === 0){
                      isReturn = isReturn + 1;
                      break;
                    }else if (parseInt(ite) === 1){
                      if ([7,8,9].includes(parseInt(item.toString().charAt(0)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 2){
                      if ([0,1,2].includes(parseInt(item.toString().charAt(0)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 3){
                      if ([3,4,5,6].includes(parseInt(item.toString().charAt(0)))){
                        isReturn = isReturn + 1;
                      }
                    }
                    break;
                  case 1:
                    if (item.toString().charAt(1) === 'X') {
                      if (parseInt(ite) !== 0){
                        isX = true;
                      }
                      isReturn = isReturn + 1;
                      break;
                    }
                    if (parseInt(ite) === 0){
                      isReturn = isReturn + 1;
                      break;
                    }else if (parseInt(ite) === 1){
                      if ([7,8,9].includes(parseInt(item.toString().charAt(1)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 2){
                      if ([0,1,2].includes(parseInt(item.toString().charAt(1)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 3){
                      if ([3,4,5,6].includes(parseInt(item.toString().charAt(1)))){
                        isReturn = isReturn + 1;
                      }
                    }
                    break;
                  case 2:
                    if (item.toString().charAt(2) === 'X') {
                      if (parseInt(ite) !== 0){
                        isX = true;
                      }
                      isReturn = isReturn + 1;
                      break;
                    }
                    if (parseInt(ite) === 0){
                      isReturn = isReturn + 1;
                      break;
                    }else if (parseInt(ite) === 1){
                      if ([7,8,9].includes(parseInt(item.toString().charAt(2)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 2){
                      if ([0,1,2].includes(parseInt(item.toString().charAt(2)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 3){
                      if ([3,4,5,6].includes(parseInt(item.toString().charAt(2)))){
                        isReturn = isReturn + 1;
                      }
                    }
                    break;
                  case 3:
                    if (item.toString().charAt(3) === 'X') {
                      if (parseInt(ite) !== 0){
                        isX = true;
                      }
                      isReturn = isReturn + 1;
                      break;
                    }
                    if (parseInt(ite) === 0){
                      isReturn = isReturn + 1;
                      break;
                    }else if (parseInt(ite) === 1){
                      if ([7,8,9].includes(parseInt(item.toString().charAt(3)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 2){
                      if ([0,1,2].includes(parseInt(item.toString().charAt(3)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 3){
                      if ([3,4,5,6].includes(parseInt(item.toString().charAt(3)))){
                        isReturn = isReturn + 1;
                      }
                    }
                    break;
                }
              })
              break;
            case '012路':
              num.forEach((ite,index) =>{
                switch (index) {
                  case 0:
                    if (item.toString().charAt(0) === 'X') {
                      if (parseInt(ite) !== 0){
                        isX = true;
                      }
                      isReturn = isReturn + 1;
                      break;
                    }
                    if (parseInt(ite) === 0){
                      isReturn = isReturn + 1;
                      break;
                    }else if (parseInt(ite) === 1){
                      if ([0,3,6,9].includes(parseInt(item.toString().charAt(0)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 2){
                      if ([1,4,7].includes(parseInt(item.toString().charAt(0)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 3){
                      if ([2,5,8].includes(parseInt(item.toString().charAt(0)))){
                        isReturn = isReturn + 1;
                      }
                    }
                    break;
                  case 1:
                    if (item.toString().charAt(1) === 'X') {
                      if (parseInt(ite) !== 0){
                        isX = true;
                      }
                      isReturn = isReturn + 1;
                      break;
                    }
                    if (parseInt(ite) === 0){
                      isReturn = isReturn + 1;
                      break;
                    }else if (parseInt(ite) === 1){
                      if ([0,3,6,9].includes(parseInt(item.toString().charAt(1)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 2){
                      if ([1,4,7].includes(parseInt(item.toString().charAt(1)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 3){
                      if ([2,5,8].includes(parseInt(item.toString().charAt(1)))){
                        isReturn = isReturn + 1;
                      }
                    }
                    break;
                  case 2:
                    if (item.toString().charAt(2) === 'X') {
                      if (parseInt(ite) !== 0){
                        isX = true;
                      }
                      isReturn = isReturn + 1;
                      break;
                    }
                    if (parseInt(ite) === 0){
                      isReturn = isReturn + 1;
                      break;
                    }else if (parseInt(ite) === 1){
                      if ([0,3,6,9].includes(parseInt(item.toString().charAt(2)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 2){
                      if ([1,4,7].includes(parseInt(item.toString().charAt(2)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 3){
                      if ([2,5,8].includes(parseInt(item.toString().charAt(2)))){
                        isReturn = isReturn + 1;
                      }
                    }
                    break;
                  case 3:
                    if (item.toString().charAt(3) === 'X') {
                      if (parseInt(ite) !== 0){
                        isX = true;
                      }
                      isReturn = isReturn + 1;
                      break;
                    }
                    if (parseInt(ite) === 0){
                      isReturn = isReturn + 1;
                      break;
                    }else if (parseInt(ite) === 1){
                      if ([0,3,6,9].includes(parseInt(item.toString().charAt(3)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 2){
                      if ([1,4,7].includes(parseInt(item.toString().charAt(3)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 3){
                      if ([2,5,8].includes(parseInt(item.toString().charAt(3)))){
                        isReturn = isReturn + 1;
                      }
                    }
                    break;
                }
              })
              break;
          }
          if (isReturn === 4 && isSave === 1 && !isX){
            return item;
          }
        }
        else if (isSave === 0){
          let isReturn = 0;
          switch (title) {
            case '单双（奇偶）':
              num.forEach((ite,index) =>{
                switch (index) {
                  case 0:
                    if (item.toString().charAt(0) === 'X') {
                      isReturn = isReturn + 1;
                      break;
                    }
                    if (parseInt(ite) === 0){
                      isReturn = isReturn + 1;
                      break;
                    }else if (parseInt(ite) === 1){
                      if (parseInt(item.toString().charAt(0)) % 2 === 0){
                        isReturn = isReturn +1;
                      }
                    }else if (parseInt(ite) === 2){
                      if (parseInt(item.toString().charAt(0)) % 2 !== 0){
                        isReturn = isReturn +1;
                      }
                    }
                    break;
                  case 1:
                    if (item.toString().charAt(1) === 'X') {
                      isReturn = isReturn + 1;
                      break;
                    }
                    if (parseInt(ite) === 0){
                      isReturn = isReturn + 1;
                      break;
                    }else if (parseInt(ite) === 1){
                      if (parseInt(item.toString().charAt(1)) % 2 === 0){
                        isReturn = isReturn +1;
                      }
                    }else if (parseInt(ite) === 2){
                      if (parseInt(item.toString().charAt(1)) % 2 !== 0){
                        isReturn = isReturn +1;
                      }
                    }
                    break;
                  case 2:
                    if (item.toString().charAt(2) === 'X') {
                      isReturn = isReturn + 1;
                      break;
                    }
                    if (parseInt(ite) === 0){
                      isReturn = isReturn + 1;
                      break;
                    }else if (parseInt(ite) === 1){
                      if (parseInt(item.toString().charAt(2)) % 2 === 0){
                        isReturn = isReturn +1;
                      }
                    }else if (parseInt(ite) === 2){
                      if (parseInt(item.toString().charAt(2)) % 2 !== 0){
                        isReturn = isReturn +1;
                      }
                    }
                    break;
                  case 3:
                    if (item.toString().charAt(3) === 'X') {
                      isReturn = isReturn + 1;
                      break;
                    }
                    if (parseInt(ite) === 0){
                      isReturn = isReturn + 1;
                      break;
                    }else if (parseInt(ite) === 1){
                      if (parseInt(item.toString().charAt(3)) % 2 === 0){
                        isReturn = isReturn +1;
                      }
                    }else if (parseInt(ite) === 2){
                      if (parseInt(item.toString().charAt(3)) % 2 !== 0){
                        isReturn = isReturn +1;
                      }
                    }
                    break;
                }
              })
              break;
            case '大小':
              num.forEach((ite,index) =>{
                switch (index) {
                  case 0:
                    if (item.toString().charAt(0) === 'X') {
                      isReturn = isReturn + 1;
                      break;
                    }
                    if (parseInt(ite) === 0){
                      isReturn = isReturn + 1;
                      break;
                    }else if (parseInt(ite) === 1){
                      if (![5,6,7,8,9].includes(parseInt(item.toString().charAt(0)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 2){
                      if (![0,1,2,3,4].includes(parseInt(item.toString().charAt(0)))){
                        isReturn = isReturn + 1;
                      }
                    }
                    break;
                  case 1:
                    if (item.toString().charAt(1) === 'X') {
                      isReturn = isReturn + 1;
                      break;
                    }
                    if (parseInt(ite) === 0){
                      isReturn = isReturn + 1;
                      break;
                    }else if (parseInt(ite) === 1){
                      if (![5,6,7,8,9].includes(parseInt(item.toString().charAt(1)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 2){
                      if (![0,1,2,3,4].includes(parseInt(item.toString().charAt(1)))){
                        isReturn = isReturn + 1;
                      }
                    }
                    break;
                  case 2:
                    if (item.toString().charAt(2) === 'X') {
                      isReturn = isReturn + 1;
                      break;
                    }
                    if (parseInt(ite) === 0){
                      isReturn = isReturn + 1;
                      break;
                    }else if (parseInt(ite) === 1){
                      if (![5,6,7,8,9].includes(parseInt(item.toString().charAt(2)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 2){
                      if (![0,1,2,3,4].includes(parseInt(item.toString().charAt(2)))){
                        isReturn = isReturn + 1;
                      }
                    }
                    break;
                  case 3:
                    if (item.toString().charAt(3) === 'X') {
                      isReturn = isReturn + 1;
                      break;
                    }
                    if (parseInt(ite) === 0){
                      isReturn = isReturn + 1;
                      break;
                    }else if (parseInt(ite) === 1){
                      if (![5,6,7,8,9].includes(parseInt(item.toString().charAt(3)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 2){
                      if (![0,1,2,3,4].includes(parseInt(item.toString().charAt(3)))){
                        isReturn = isReturn + 1;
                      }
                    }
                    break;
                }
              })
              break;
            case '质合':
              num.forEach((ite,index) =>{
                switch (index) {
                  case 0:
                    if (item.toString().charAt(0) === 'X') {
                      isReturn = isReturn + 1;
                      break;
                    }
                    if (parseInt(ite) === 0){
                      isReturn = isReturn + 1;
                      break;
                    }else if (parseInt(ite) === 1){
                      if (![1,2,3,5,7].includes(parseInt(item.toString().charAt(0)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 2){
                      if (![0,4,6,8,9].includes(parseInt(item.toString().charAt(0)))){
                        isReturn = isReturn + 1;
                      }
                    }
                    break;
                  case 1:
                    if (item.toString().charAt(1) === 'X') {
                      isReturn = isReturn + 1;
                      break;
                    }
                    if (parseInt(ite) === 0){
                      isReturn = isReturn + 1;
                      break;
                    }else if (parseInt(ite) === 1){
                      if (![1,2,3,5,7].includes(parseInt(item.toString().charAt(1)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 2){
                      if (![0,4,6,8,9].includes(parseInt(item.toString().charAt(1)))){
                        isReturn = isReturn + 1;
                      }
                    }
                    break;
                  case 2:
                    if (item.toString().charAt(2) === 'X') {
                      isReturn = isReturn + 1;
                      break;
                    }
                    if (parseInt(ite) === 0){
                      isReturn = isReturn + 1;
                      break;
                    }else if (parseInt(ite) === 1){
                      if (![1,2,3,5,7].includes(parseInt(item.toString().charAt(2)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 2){
                      if (![0,4,6,8,9].includes(parseInt(item.toString().charAt(2)))){
                        isReturn = isReturn + 1;
                      }
                    }
                    break;
                  case 3:
                    if (item.toString().charAt(3) === 'X') {
                      isReturn = isReturn + 1;
                      break;
                    }
                    if (parseInt(ite) === 0){
                      isReturn = isReturn + 1;
                      break;
                    }else if (parseInt(ite) === 1){
                      if (![1,2,3,5,7].includes(parseInt(item.toString().charAt(3)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 2){
                      if (![0,4,6,8,9].includes(parseInt(item.toString().charAt(3)))){
                        isReturn = isReturn + 1;
                      }
                    }
                    break;
                }
              })
              break;
            case '大中小':
              num.forEach((ite,index) =>{
                switch (index) {
                  case 0:
                    if (item.toString().charAt(0) === 'X') {
                      isReturn = isReturn + 1;
                      break;
                    }
                    if (parseInt(ite) === 0){
                      isReturn = isReturn + 1;
                      break;
                    }else if (parseInt(ite) === 1){
                      if (![7,8,9].includes(parseInt(item.toString().charAt(0)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 2){
                      if (![0,1,2].includes(parseInt(item.toString().charAt(0)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 3){
                      if (![3,4,5,6].includes(parseInt(item.toString().charAt(0)))){
                        isReturn = isReturn + 1;
                      }
                    }
                    break;
                  case 1:
                    if (item.toString().charAt(1) === 'X') {
                      isReturn = isReturn + 1;
                      break;
                    }
                    if (parseInt(ite) === 0){
                      isReturn = isReturn + 1;
                      break;
                    }else if (parseInt(ite) === 1){
                      if (![7,8,9].includes(parseInt(item.toString().charAt(1)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 2){
                      if (![0,1,2].includes(parseInt(item.toString().charAt(1)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 3){
                      if (![3,4,5,6].includes(parseInt(item.toString().charAt(1)))){
                        isReturn = isReturn + 1;
                      }
                    }
                    break;
                  case 2:
                    if (item.toString().charAt(2) === 'X') {
                      isReturn = isReturn + 1;
                      break;
                    }
                    if (parseInt(ite) === 0){
                      isReturn = isReturn + 1;
                      break;
                    }else if (parseInt(ite) === 1){
                      if (![7,8,9].includes(parseInt(item.toString().charAt(2)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 2){
                      if (![0,1,2].includes(parseInt(item.toString().charAt(2)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 3){
                      if (![3,4,5,6].includes(parseInt(item.toString().charAt(2)))){
                        isReturn = isReturn + 1;
                      }
                    }
                    break;
                  case 3:
                    if (item.toString().charAt(3) === 'X') {
                      isReturn = isReturn + 1;
                      break;
                    }
                    if (parseInt(ite) === 0){
                      isReturn = isReturn + 1;
                      break;
                    }else if (parseInt(ite) === 1){
                      if (![7,8,9].includes(parseInt(item.toString().charAt(3)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 2){
                      if (![0,1,2].includes(parseInt(item.toString().charAt(3)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 3){
                      if (![3,4,5,6].includes(parseInt(item.toString().charAt(3)))){
                        isReturn = isReturn + 1;
                      }
                    }
                    break;
                }
              })
              break;
            case '012路':
              num.forEach((ite,index) =>{
                switch (index) {
                  case 0:
                    if (item.toString().charAt(0) === 'X') {
                      isReturn = isReturn + 1;
                      break;
                    }
                    if (parseInt(ite) === 0){
                      isReturn = isReturn + 1;
                      break;
                    }else if (parseInt(ite) === 1){
                      if (![0,3,6,9].includes(parseInt(item.toString().charAt(0)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 2){
                      if (![1,4,7].includes(parseInt(item.toString().charAt(0)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 3){
                      if (![2,5,8].includes(parseInt(item.toString().charAt(0)))){
                        isReturn = isReturn + 1;
                      }
                    }
                    break;
                  case 1:
                    if (item.toString().charAt(1) === 'X') {
                      isReturn = isReturn + 1;
                      break;
                    }
                    if (parseInt(ite) === 0){
                      isReturn = isReturn + 1;
                      break;
                    }else if (parseInt(ite) === 1){
                      if (![0,3,6,9].includes(parseInt(item.toString().charAt(1)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 2){
                      if (![1,4,7].includes(parseInt(item.toString().charAt(1)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 3){
                      if (![2,5,8].includes(parseInt(item.toString().charAt(1)))){
                        isReturn = isReturn + 1;
                      }
                    }
                    break;
                  case 2:
                    if (item.toString().charAt(2) === 'X') {
                      isReturn = isReturn + 1;
                      break;
                    }
                    if (parseInt(ite) === 0){
                      isReturn = isReturn + 1;
                      break;
                    }else if (parseInt(ite) === 1){
                      if (![0,3,6,9].includes(parseInt(item.toString().charAt(2)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 2){
                      if (![1,4,7].includes(parseInt(item.toString().charAt(2)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 3){
                      if (![2,5,8].includes(parseInt(item.toString().charAt(2)))){
                        isReturn = isReturn + 1;
                      }
                    }
                    break;
                  case 3:
                    if (item.toString().charAt(3) === 'X') {
                      isReturn = isReturn + 1;
                      break;
                    }
                    if (parseInt(ite) === 0){
                      isReturn = isReturn + 1;
                      break;
                    }else if (parseInt(ite) === 1){
                      if (![0,3,6,9].includes(parseInt(item.toString().charAt(3)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 2){
                      if (![1,4,7].includes(parseInt(item.toString().charAt(3)))){
                        isReturn = isReturn + 1;
                      }
                    }else if (parseInt(ite) === 3){
                      if (![2,5,8].includes(parseInt(item.toString().charAt(3)))){
                        isReturn = isReturn + 1;
                      }
                    }
                    break;
                }
              })
              break;
          }
          if (isReturn === 4 && isSave === 0){
            return item;
          }
        }
      })
    },
    //按位任选
    positionAnyChoose(list,num,isSave,title){
      return list.filter((item) =>{
        let isReturn = 0;
        switch (title) {
          case '单双（奇偶）':
            for (let j=0;j<num.length;j++){
              isReturn = 0;
              for (let i=0;i<num[j].toString().length;i++){
                switch (num[j].toString().charAt(i)) {
                  case 's':
                    if ([1,3,5,7,9].includes(parseInt(item.toString().charAt(i))) && item.toString().charAt(i) !== 'X'){
                      isReturn = isReturn + 1;
                    }
                    break;
                  case 'd':
                    if ([0,2,4,6,8].includes(parseInt(item.toString().charAt(i))) && item.toString().charAt(i) !== 'X'){
                      isReturn = isReturn + 1;
                    }
                    break;
                  case 'X':
                    if (item.toString().charAt(i) === 'X'){
                      isReturn = isReturn + 1;
                    }
                    break;
                }
              }
              if (isReturn === 4){
                break;
              }
            }
            break;
          case '大小':
            for (let j=0;j<num.length;j++){
              isReturn = 0;
              for (let i=0;i<num[j].toString().length;i++){
                switch (num[j].toString().charAt(i)) {
                  case 'x':
                    if ([0,1,2,3,4].includes(parseInt(item.toString().charAt(i))) && item.toString().charAt(i) !== 'X'){
                      isReturn = isReturn + 1;
                    }
                    break;
                  case 'b':
                    if ([5,6,7,8,9].includes(parseInt(item.toString().charAt(i))) && item.toString().charAt(i) !== 'X'){
                      isReturn = isReturn + 1;
                    }
                    break;
                  case 'X':
                    if (item.toString().charAt(i) === 'X'){
                      isReturn = isReturn + 1;
                    }
                    break;
                }
              }
              if (isReturn === 4){
                break;
              }
            }
            break;
          case '质合':
            for (let j=0;j<num.length;j++){
              isReturn = 0;
              for (let i=0;i<num[j].toString().length;i++){
                switch (num[j].toString().charAt(i)) {
                  case 'z':
                    if ([1,2,3,5,7].includes(parseInt(item.toString().charAt(i))) && item.toString().charAt(i) !== 'X'){
                      isReturn = isReturn + 1;
                    }
                    break;
                  case 'h':
                    if ([0,4,6,8,9].includes(parseInt(item.toString().charAt(i))) && item.toString().charAt(i) !== 'X'){
                      isReturn = isReturn + 1;
                    }
                    break;
                  case 'X':
                    if (item.toString().charAt(i) === 'X'){
                      isReturn = isReturn + 1;
                    }
                    break;
                }
              }
              if (isReturn === 4){
                break;
              }
            }
            break;
          case '大中小':
            for (let j=0;j<num.length;j++){
              isReturn = 0;
              for (let i=0;i<num[j].toString().length;i++){
                switch (num[j].toString().charAt(i)) {
                  case 'b':
                    if ([7,8,9].includes(parseInt(item.toString().charAt(i))) && item.toString().charAt(i) !== 'X'){
                      isReturn = isReturn + 1;
                    }
                    break;
                  case 'm':
                    if ([3,4,5,6].includes(parseInt(item.toString().charAt(i))) && item.toString().charAt(i) !== 'X'){
                      isReturn = isReturn + 1;
                    }
                    break;
                  case 's':
                    if ([0,1,2].includes(parseInt(item.toString().charAt(i))) && item.toString().charAt(i) !== 'X'){
                      isReturn = isReturn + 1;
                    }
                    break;
                  case 'X':
                    if (item.toString().charAt(i) === 'X'){
                      isReturn = isReturn + 1;
                    }
                    break;
                }
              }
              if (isReturn === 4){
                break;
              }
            }
            break;
          case '012路':
            for (let j=0;j<num.length;j++){
              isReturn = 0;
              for (let i=0;i<num[j].toString().length;i++){
                switch (num[j].toString().charAt(i)) {
                  case '0':
                    if ([0,3,6,9].includes(parseInt(item.toString().charAt(i))) && item.toString().charAt(i) !== 'X'){
                      isReturn = isReturn + 1;
                    }
                    break;
                  case '1':
                    if ([1,4,7].includes(parseInt(item.toString().charAt(i))) && item.toString().charAt(i) !== 'X'){
                      isReturn = isReturn + 1;
                    }
                    break;
                  case '2':
                    if ([2,5,8].includes(parseInt(item.toString().charAt(i))) && item.toString().charAt(i) !== 'X'){
                      isReturn = isReturn + 1;
                    }
                    break;
                  case 'X':
                    if (item.toString().charAt(i) === 'X'){
                      isReturn = isReturn + 1;
                    }
                    break;
                }
              }
              if (isReturn === 4){
                break;
              }
            }
            break;
        }
        if (isReturn === 4 && isSave === 1){
          return item;
        }else if (isReturn !== 4 && isSave === 0){
          return item;
        }
      })
    },
    //出现比例
    apperRatioMethod(list,num,isSave,title){
      return list.filter((item) =>{
        let isReturn = false;
        num.forEach((ite) =>{
          switch (title) {
            case '单双（奇偶）':{
              let num = item.toString().replaceAll('X','').split('');
              let cond1 = num.filter((it) => [1,3,5,7,9].includes(parseInt(it))).length === parseInt(ite.toString().charAt(0));
              let cond2 = num.filter((it) => [0,2,4,6,8].includes(parseInt(it))).length === parseInt(ite.toString().charAt(1));
              if (cond1 && cond2){
                isReturn = true;
              }
              break;
            }
            case '大小':{
              let num = item.toString().replaceAll('X','').split('');
              let cond1 = num.filter((it) => [5,6,7,8,9].includes(parseInt(it))).length === parseInt(ite.toString().charAt(0));
              let cond2 = num.filter((it) => [0,1,2,3,4].includes(parseInt(it))).length === parseInt(ite.toString().charAt(1));
              if (cond1 && cond2){
                isReturn = true;
              }
              break;
            }
            case '质合':{
              let num = item.toString().replaceAll('X','').split('');
              let cond1 = num.filter((it) => [1,2,3,5,7].includes(parseInt(it))).length === parseInt(ite.toString().charAt(0));
              let cond2 = num.filter((it) => [0,4,6,8,9].includes(parseInt(it))).length === parseInt(ite.toString().charAt(1));
              if (cond1 && cond2){
                isReturn = true;
              }
              break;
            }
            case '大中小':{
              let num = item.toString().replaceAll('X','').split('');
              let cond1 = num.filter((it) => [7,8,9].includes(parseInt(it))).length === parseInt(ite.toString().charAt(0));
              let cond2 = num.filter((it) => [3,4,5,6].includes(parseInt(it))).length === parseInt(ite.toString().charAt(1));
              let cond3 = num.filter((it) => [0,1,2].includes(parseInt(it))).length === parseInt(ite.toString().charAt(2));
              if (cond1 && cond2 && cond3){
                isReturn = true;
              }
              break;
            }
            case '012路':{
              let num = item.toString().replaceAll('X','').split('');
              let cond1 = num.filter((it) => [0,3,6,9].includes(parseInt(it))).length === parseInt(ite.toString().charAt(0));
              let cond2 = num.filter((it) => [1,4,7].includes(parseInt(it))).length === parseInt(ite.toString().charAt(1));
              let cond3 = num.filter((it) => [2,5,8].includes(parseInt(it))).length === parseInt(ite.toString().charAt(2));
              if (cond1 && cond2 && cond3){
                isReturn = true;
              }
              break;
            }
          }
        })
        if (isReturn && isSave === 1){
          return item;
        }else if (!isReturn && isSave === 0){
          return item;
        }
      })
    },
    //断组
    breakGroupMethod(list,num,isSave){
      return list.filter((item) =>{
        let isReturn = true;
        num.forEach((ite) =>{
          switch (ite) {
            case '1':{
              let num = item.toString().replaceAll('X','').split('');
              let cond1 = num.filter((it) => [1,3,5,7,9].includes(parseInt(it))).length > 0;
              let cond2 = num.filter((it) => [0,2,4,6,8].includes(parseInt(it))).length > 0;
              if (cond1 && cond2){
                isReturn = false;
              }
              break;
            }
            case '2':{
              let num = item.toString().replaceAll('X','').split('');
              let cond1 = num.filter((it) => [5,6,7,8,9].includes(parseInt(it))).length > 0;
              let cond2 = num.filter((it) => [0,1,2,3,4].includes(parseInt(it))).length > 0;
              if (cond1 && cond2){
                isReturn = false;
              }
              break;
            }
            case '3':{
              let num = item.toString().replaceAll('X','').split('');
              let cond1 = num.filter((it) => [1,2,3,5,7].includes(parseInt(it))).length > 0;
              let cond2 = num.filter((it) => [0,4,6,8,9].includes(parseInt(it))).length > 0;
              if (cond1 && cond2){
                isReturn = false;
              }
              break;
            }
            case '4':{
              let num = item.toString().replaceAll('X','').split('');
              let cond1 = num.filter((it) => [7,8,9].includes(parseInt(it))).length > 0;
              let cond2 = num.filter((it) => [3,4,5,6].includes(parseInt(it))).length > 0;
              let cond3 = num.filter((it) => [0,1,2].includes(parseInt(it))).length > 0;
              if (cond1 && cond2 && cond3){
                isReturn = false;
              }
              break;
            }
            case '5':{
              let num = item.toString().replaceAll('X','').split('');
              let cond1 = num.filter((it) => [0,3,6,9].includes(parseInt(it))).length >0;
              let cond2 = num.filter((it) => [1,4,7].includes(parseInt(it))).length >0;
              let cond3 = num.filter((it) => [2,5,8].includes(parseInt(it))).length >0;
              if (cond1 && cond2 && cond3){
                isReturn = false;
              }
              break;
            }
          }
        })
        if (isReturn && isSave === 1){
          return item;
        }else if (!isReturn && isSave === 0){
          return item;
        }
      })
    },
    //乘号位置
    positionXMethod(list,num){
      return list.filter((item) =>{
        let condNum = num.length;
        let haveNum = false;
        num.forEach((ite) =>{
          haveNum = true;
          switch (ite) {
            case '1':
              if (item.toString().charAt(0) === 'X')
              {
                condNum = condNum - 1;
              }
              break;
            case '2':
              if (item.toString().charAt(1) === 'X')
              {
                condNum = condNum - 1;
              }
              break;
            case '3':
              if (item.toString().charAt(2) === 'X')
              {
                condNum = condNum - 1;
              }
              break;
            case '4':
              if (item.toString().charAt(3) === 'X')
              {
                condNum = condNum - 1;
              }
              break;
          }
        })
        if (haveNum && condNum === 0){
          return item;
        }
      })
    },
    //批量选码
    batchCodeMethod(list,num,isSave){
      return list.filter((item) =>{
        let condNum = 0;
        let isHave = false;
        num.forEach((itemList) =>{
          itemList.forEach((itemNum) =>{
            if (itemNum !== 0){
              condNum = condNum + 1;
            }
          })
        })
        if (isSave === 1){
          num.forEach((itemList,indexList) =>{
            isHave = true;
            itemList.forEach((itemNum,indexNum) =>{
              switch (indexList) {
                case 0:
                  switch (indexNum) {
                    case 0:
                      if (itemNum === '1'){
                        if (['1','3','5','7','9'].includes(item.toString().charAt(0))){
                          condNum = condNum - 1;
                        }
                      }else if (itemNum === '2'){
                        if (['0','2','4','6','8'].includes(item.toString().charAt(0))){
                          condNum = condNum - 1;
                        }
                      }
                      break;
                    case 1:
                      if (itemNum === '1'){
                        if (['5','6','7','8','9'].includes(item.toString().charAt(0))){
                          condNum = condNum - 1;
                        }
                      }else if (itemNum === '2'){
                        if (['0','1','2','3','4'].includes(item.toString().charAt(0))){
                          condNum = condNum - 1;
                        }
                      }
                      break;
                    case 2:
                      if (itemNum === '1'){
                        if (['0','3','6','9'].includes(item.toString().charAt(0))){
                          condNum = condNum - 1;
                        }
                      }else if (itemNum === '2'){
                        if (['1','4','7'].includes(item.toString().charAt(0))){
                          condNum = condNum - 1;
                        }
                      }else if (itemNum === '3'){
                        if (['2','5','8'].includes(item.toString().charAt(0))){
                          condNum = condNum - 1;
                        }
                      }
                      break;
                  }
                  break;
                case 1:
                  switch (indexNum) {
                    case 0:
                      if (itemNum === '1'){
                        if (['1','3','5','7','9'].includes(item.toString().charAt(1))){
                          condNum = condNum - 1;
                        }
                      }else if (itemNum === '2'){
                        if (['0','2','4','6','8'].includes(item.toString().charAt(1))){
                          condNum = condNum - 1;
                        }
                      }
                      break;
                    case 1:
                      if (itemNum === '1'){
                        if (['5','6','7','8','9'].includes(item.toString().charAt(1))){
                          condNum = condNum - 1;
                        }
                      }else if (itemNum === '2'){
                        if (['0','1','2','3','4'].includes(item.toString().charAt(1))){
                          condNum = condNum - 1;
                        }
                      }
                      break;
                    case 2:
                      if (itemNum === '1'){
                        if (['0','3','6','9'].includes(item.toString().charAt(1))){
                          condNum = condNum - 1;
                        }
                      }else if (itemNum === '2'){
                        if (['1','4','7'].includes(item.toString().charAt(1))){
                          condNum = condNum - 1;
                        }
                      }else if (itemNum === '3'){
                        if (['2','5','8'].includes(item.toString().charAt(1))){
                          condNum = condNum - 1;
                        }
                      }
                      break;
                  }
                  break;
                case 2:
                  switch (indexNum) {
                    case 0:
                      if (itemNum === '1'){
                        if (['1','3','5','7','9'].includes(item.toString().charAt(2))){
                          condNum = condNum - 1;
                        }
                      }else if (itemNum === '2'){
                        if (['0','2','4','6','8'].includes(item.toString().charAt(2))){
                          condNum = condNum - 1;
                        }
                      }
                      break;
                    case 1:
                      if (itemNum === '1'){
                        if (['5','6','7','8','9'].includes(item.toString().charAt(2))){
                          condNum = condNum - 1;
                        }
                      }else if (itemNum === '2'){
                        if (['0','1','2','3','4'].includes(item.toString().charAt(2))){
                          condNum = condNum - 1;
                        }
                      }
                      break;
                    case 2:
                      if (itemNum === '1'){
                        if (['0','3','6','9'].includes(item.toString().charAt(2))){
                          condNum = condNum - 1;
                        }
                      }else if (itemNum === '2'){
                        if (['1','4','7'].includes(item.toString().charAt(2))){
                          condNum = condNum - 1;
                        }
                      }else if (itemNum === '3'){
                        if (['2','5','8'].includes(item.toString().charAt(2))){
                          condNum = condNum - 1;
                        }
                      }
                      break;
                  }
                  break;
                case 3:
                  switch (indexNum) {
                    case 0:
                      if (itemNum === '1'){
                        if (['1','3','5','7','9'].includes(item.toString().charAt(3))){
                          condNum = condNum - 1;
                        }
                      }else if (itemNum === '2'){
                        if (['0','2','4','6','8'].includes(item.toString().charAt(3))){
                          condNum = condNum - 1;
                        }
                      }
                      break;
                    case 1:
                      if (itemNum === '1'){
                        if (['5','6','7','8','9'].includes(item.toString().charAt(3))){
                          condNum = condNum - 1;
                        }
                      }else if (itemNum === '2'){
                        if (['0','1','2','3','4'].includes(item.toString().charAt(3))){
                          condNum = condNum - 1;
                        }
                      }
                      break;
                    case 2:
                      if (itemNum === '1'){
                        if (['0','3','6','9'].includes(item.toString().charAt(3))){
                          condNum = condNum - 1;
                        }
                      }else if (itemNum === '2'){
                        if (['1','4','7'].includes(item.toString().charAt(3))){
                          condNum = condNum - 1;
                        }
                      }else if (itemNum === '3'){
                        if (['2','5','8'].includes(item.toString().charAt(3))){
                          condNum = condNum - 1;
                        }
                      }
                      break;
                  }
                  break;
              }
            })
          })
        }else if (isSave === 0){
          num.forEach((itemList,indexList) =>{
            isHave = true;
            itemList.forEach((itemNum,indexNum) =>{
              switch (indexList) {
                case 0:
                  switch (indexNum) {
                    case 0:
                      if (itemNum === '1'){
                        if (!['1','3','5','7','9'].includes(item.toString().charAt(0))){
                          condNum = condNum - 1;
                        }
                      }else if (itemNum === '2'){
                        if (!['0','2','4','6','8'].includes(item.toString().charAt(0))){
                          condNum = condNum - 1;
                        }
                      }
                      break;
                    case 1:
                      if (itemNum === '1'){
                        if (!['5','6','7','8','9'].includes(item.toString().charAt(0))){
                          condNum = condNum - 1;
                        }
                      }else if (itemNum === '2'){
                        if (!['0','1','2','3','4'].includes(item.toString().charAt(0))){
                          condNum = condNum - 1;
                        }
                      }
                      break;
                    case 2:
                      if (itemNum === '1'){
                        if (!['0','3','6','9'].includes(item.toString().charAt(0))){
                          condNum = condNum - 1;
                        }
                      }else if (itemNum === '2'){
                        if (!['1','4','7'].includes(item.toString().charAt(0))){
                          condNum = condNum - 1;
                        }
                      }else if (itemNum === '3'){
                        if (!['2','5','8'].includes(item.toString().charAt(0))){
                          condNum = condNum - 1;
                        }
                      }
                      break;
                  }
                  break;
                case 1:
                  switch (indexNum) {
                    case 0:
                      if (itemNum === '1'){
                        if (!['1','3','5','7','9'].includes(item.toString().charAt(1))){
                          condNum = condNum - 1;
                        }
                      }else if (itemNum === '2'){
                        if (!['0','2','4','6','8'].includes(item.toString().charAt(1))){
                          condNum = condNum - 1;
                        }
                      }
                      break;
                    case 1:
                      if (itemNum === '1'){
                        if (!['5','6','7','8','9'].includes(item.toString().charAt(1))){
                          condNum = condNum - 1;
                        }
                      }else if (itemNum === '2'){
                        if (!['0','1','2','3','4'].includes(item.toString().charAt(1))){
                          condNum = condNum - 1;
                        }
                      }
                      break;
                    case 2:
                      if (itemNum === '1'){
                        if (!['0','3','6','9'].includes(item.toString().charAt(1))){
                          condNum = condNum - 1;
                        }
                      }else if (itemNum === '2'){
                        if (!['1','4','7'].includes(item.toString().charAt(1))){
                          condNum = condNum - 1;
                        }
                      }else if (itemNum === '3'){
                        if (!['2','5','8'].includes(item.toString().charAt(1))){
                          condNum = condNum - 1;
                        }
                      }
                      break;
                  }
                  break;
                case 2:
                  switch (indexNum) {
                    case 0:
                      if (itemNum === '1'){
                        if (!['1','3','5','7','9'].includes(item.toString().charAt(2))){
                          condNum = condNum - 1;
                        }
                      }else if (itemNum === '2'){
                        if (!['0','2','4','6','8'].includes(item.toString().charAt(2))){
                          condNum = condNum - 1;
                        }
                      }
                      break;
                    case 1:
                      if (itemNum === '1'){
                        if (!['5','6','7','8','9'].includes(item.toString().charAt(2))){
                          condNum = condNum - 1;
                        }
                      }else if (itemNum === '2'){
                        if (!['0','1','2','3','4'].includes(item.toString().charAt(2))){
                          condNum = condNum - 1;
                        }
                      }
                      break;
                    case 2:
                      if (itemNum === '1'){
                        if (!['0','3','6','9'].includes(item.toString().charAt(2))){
                          condNum = condNum - 1;
                        }
                      }else if (itemNum === '2'){
                        if (!['1','4','7'].includes(item.toString().charAt(2))){
                          condNum = condNum - 1;
                        }
                      }else if (itemNum === '3'){
                        if (!['2','5','8'].includes(item.toString().charAt(2))){
                          condNum = condNum - 1;
                        }
                      }
                      break;
                  }
                  break;
                case 3:
                  switch (indexNum) {
                    case 0:
                      if (itemNum === '1'){
                        if (!['1','3','5','7','9'].includes(item.toString().charAt(3))){
                          condNum = condNum - 1;
                        }
                      }else if (itemNum === '2'){
                        if (!['0','2','4','6','8'].includes(item.toString().charAt(3))){
                          condNum = condNum - 1;
                        }
                      }
                      break;
                    case 1:
                      if (itemNum === '1'){
                        if (!['5','6','7','8','9'].includes(item.toString().charAt(3))){
                          condNum = condNum - 1;
                        }
                      }else if (itemNum === '2'){
                        if (!['0','1','2','3','4'].includes(item.toString().charAt(3))){
                          condNum = condNum - 1;
                        }
                      }
                      break;
                    case 2:
                      if (itemNum === '1'){
                        if (!['0','3','6','9'].includes(item.toString().charAt(3))){
                          condNum = condNum - 1;
                        }
                      }else if (itemNum === '2'){
                        if (!['1','4','7'].includes(item.toString().charAt(3))){
                          condNum = condNum - 1;
                        }
                      }else if (itemNum === '3'){
                        if (!['2','5','8'].includes(item.toString().charAt(3))){
                          condNum = condNum - 1;
                        }
                      }
                      break;
                  }
                  break;
              }
            })
          })
        }
        if (isHave && condNum === 0){
          return item;
        }
      })
    },
    //自由选码
    freeCodeMethod(list,num,isSave){
      return list.filter((item) =>{
        let condNum = 0;
        let isHave = false;
        num.forEach((ite) =>{
          if (ite.length !== 0){
            condNum = condNum + 1;
          }
        })
        if (isSave === 1){
          num.forEach((condList,condIndex) =>{
            isHave = true;
            switch (condIndex) {
              case 0:
                if (item.toString().charAt(0) !== 'X' && condList.includes(parseInt(item.toString().charAt(0)))){
                  condNum = condNum - 1;
                }
                break;
              case 1:
                if (item.toString().charAt(1) !== 'X' && condList.includes(parseInt(item.toString().charAt(1)))){
                  condNum = condNum - 1;
                }
                break;
              case 2:
                if (item.toString().charAt(2) !== 'X' && condList.includes(parseInt(item.toString().charAt(2)))){
                  condNum = condNum - 1;
                }
                break;
              case 3:
                if (item.toString().charAt(3) !== 'X' && condList.includes(parseInt(item.toString().charAt(3)))){
                  condNum = condNum - 1;
                }
                break;
            }
          })
        }else if (isSave === 0){
          num.forEach((condList,condIndex) =>{
            isHave = true;
            switch (condIndex) {
              case 0:
                if (item.toString().charAt(0) !== 'X' && !condList.includes(parseInt(item.toString().charAt(0)))){
                  condNum = condNum - 1;
                }
                break;
              case 1:
                if (item.toString().charAt(1) !== 'X' && !condList.includes(parseInt(item.toString().charAt(1)))){
                  condNum = condNum - 1;
                }
                break;
              case 2:
                if (item.toString().charAt(2) !== 'X' && !condList.includes(parseInt(item.toString().charAt(2)))){
                  condNum = condNum - 1;
                }
                break;
              case 3:
                if (item.toString().charAt(3) !== 'X' && !condList.includes(parseInt(item.toString().charAt(3)))){
                  condNum = condNum - 1;
                }
                break;
            }
          })
        }
        if (isHave && condNum === 0){
          return item;
        }
      })
    },
    //入数个数
    inNumberMethod(list,num,isSave){
      return list.filter((item) =>{
        let condNum = num.length;
        let isHave = false;
        if (isSave === 1){
          num.forEach((ite) =>{
            isHave = true;
            switch (ite) {
              case '1':{
                let returnList = this.deduplicationMethod([],item.toString().replaceAll('X','').split(''));
                if (returnList.length === 1){
                  condNum = condNum - 1;
                }
                break;
              }
              case '2':{
                let returnList = this.deduplicationMethod([],item.toString().replaceAll('X','').split(''));
                if (returnList.length === 2){
                  condNum = condNum - 1;
                }
                break;
              }
              case '3':{
                let returnList = this.deduplicationMethod([],item.toString().replaceAll('X','').split(''));
                if (returnList.length === 3){
                  condNum = condNum - 1;
                }
                break;
              }
              case '4':{
                let returnList = this.deduplicationMethod([],item.toString().replaceAll('X','').split(''));
                if (returnList.length === 4){
                  condNum = condNum - 1;
                }
                break;
              }
            }
          })
        }else if (isSave === 0){
          num.forEach((ite) =>{
            isHave = true;
            switch (ite) {
              case '1':{
                let returnList = this.deduplicationMethod([],item.toString().replaceAll('X','').split(''));
                if (returnList.length !== 1){
                  condNum = condNum - 1;
                }
                break;
              }
              case '2':{
                let returnList = this.deduplicationMethod([],item.toString().replaceAll('X','').split(''));
                if (returnList.length !== 2){
                  condNum = condNum - 1;
                }
                break;
              }
              case '3':{
                let returnList = this.deduplicationMethod([],item.toString().replaceAll('X','').split(''));
                if (returnList.length !== 3){
                  condNum = condNum - 1;
                }
                break;
              }
              case '4':{
                let returnList = this.deduplicationMethod([],item.toString().replaceAll('X','').split(''));
                if (returnList.length !== 4){
                  condNum = condNum - 1;
                }
                break;
              }
            }
          })
        }
        if (isHave && condNum === 0){
          return item;
        }
      })
    },
    //AC值
    ACnumber(list,num,isSave){
      return list.filter((item) =>{
        let returnNum = false;
        if ([3,4].includes(this.$store.state.payWay.payType)){
          num.forEach((ite) =>{
            switch (ite) {
              case 1:{
                let itemNum = item.toString().replaceAll('X','').split('');
                let decList = [];
                decList.push(parseInt(itemNum[1]) - parseInt(itemNum[0]));
                decList.push(parseInt(itemNum[2]) - parseInt(itemNum[0]));
                decList.push(parseInt(itemNum[2]) - parseInt(itemNum[1]));
                if (this.deduplicationMethod([],decList).length === 1){
                  returnNum = true;
                }
                break;
              }
              case 2:{
                let itemNum = item.toString().replaceAll('X','').split('');
                let decList = [];
                decList.push(parseInt(itemNum[1]) - parseInt(itemNum[0]));
                decList.push(parseInt(itemNum[2]) - parseInt(itemNum[0]));
                decList.push(parseInt(itemNum[2]) - parseInt(itemNum[1]));
                if (this.deduplicationMethod([],decList).length === 2){
                  returnNum = true;
                }
                break;
              }
              case 3:{
                let itemNum = item.toString().replaceAll('X','').split('');
                let decList = [];
                decList.push(parseInt(itemNum[1]) - parseInt(itemNum[0]));
                decList.push(parseInt(itemNum[2]) - parseInt(itemNum[0]));
                decList.push(parseInt(itemNum[2]) - parseInt(itemNum[1]));
                if (this.deduplicationMethod([],decList).length === 3){
                  returnNum = true;
                }
                break;
              }
            }
          })
        }else if ([1,2].includes(this.$store.state.payWay.payType)){
          num.forEach((ite) =>{
            switch (ite) {
              case 1:{
                let itemNum = item.toString().replaceAll('X','').split('');
                let decList = [];
                decList.push(parseInt(itemNum[1]) - parseInt(itemNum[0]));
                decList.push(parseInt(itemNum[2]) - parseInt(itemNum[0]));
                decList.push(parseInt(itemNum[3]) - parseInt(itemNum[0]));
                decList.push(parseInt(itemNum[2]) - parseInt(itemNum[1]));
                decList.push(parseInt(itemNum[3]) - parseInt(itemNum[1]));
                decList.push(parseInt(itemNum[3]) - parseInt(itemNum[2]));
                if (this.deduplicationMethod([],decList).length === 1){
                  returnNum = true;
                }
                break;
              }
              case 2:{
                let itemNum = item.toString().replaceAll('X','').split('');
                let decList = [];
                decList.push(parseInt(itemNum[1]) - parseInt(itemNum[0]));
                decList.push(parseInt(itemNum[2]) - parseInt(itemNum[0]));
                decList.push(parseInt(itemNum[3]) - parseInt(itemNum[0]));
                decList.push(parseInt(itemNum[2]) - parseInt(itemNum[1]));
                decList.push(parseInt(itemNum[3]) - parseInt(itemNum[1]));
                decList.push(parseInt(itemNum[3]) - parseInt(itemNum[2]));
                if (this.deduplicationMethod([],decList).length === 2){
                  returnNum = true;
                }
                break;
              }
              case 3:{
                let itemNum = item.toString().replaceAll('X','').split('');
                let decList = [];
                decList.push(parseInt(itemNum[1]) - parseInt(itemNum[0]));
                decList.push(parseInt(itemNum[2]) - parseInt(itemNum[0]));
                decList.push(parseInt(itemNum[3]) - parseInt(itemNum[0]));
                decList.push(parseInt(itemNum[2]) - parseInt(itemNum[1]));
                decList.push(parseInt(itemNum[3]) - parseInt(itemNum[1]));
                decList.push(parseInt(itemNum[3]) - parseInt(itemNum[2]));
                if (this.deduplicationMethod([],decList).length === 3){
                  returnNum = true;
                }
                break;
              }
              case 4:{
                let itemNum = item.toString().replaceAll('X','').split('');
                let decList = [];
                decList.push(parseInt(itemNum[1]) - parseInt(itemNum[0]));
                decList.push(parseInt(itemNum[2]) - parseInt(itemNum[0]));
                decList.push(parseInt(itemNum[3]) - parseInt(itemNum[0]));
                decList.push(parseInt(itemNum[2]) - parseInt(itemNum[1]));
                decList.push(parseInt(itemNum[3]) - parseInt(itemNum[1]));
                decList.push(parseInt(itemNum[3]) - parseInt(itemNum[2]));
                if (this.deduplicationMethod([],decList).length === 4){
                  returnNum = true;
                }
                break;
              }
              case 5:{
                let itemNum = item.toString().replaceAll('X','').split('');
                let decList = [];
                decList.push(parseInt(itemNum[1]) - parseInt(itemNum[0]));
                decList.push(parseInt(itemNum[2]) - parseInt(itemNum[0]));
                decList.push(parseInt(itemNum[3]) - parseInt(itemNum[0]));
                decList.push(parseInt(itemNum[2]) - parseInt(itemNum[1]));
                decList.push(parseInt(itemNum[3]) - parseInt(itemNum[1]));
                decList.push(parseInt(itemNum[3]) - parseInt(itemNum[2]));
                if (this.deduplicationMethod([],decList).length === 5){
                  returnNum = true;
                }
                break;
              }
              case 6:{
                let itemNum = item.toString().replaceAll('X','').split('');
                let decList = [];
                decList.push(parseInt(itemNum[1]) - parseInt(itemNum[0]));
                decList.push(parseInt(itemNum[2]) - parseInt(itemNum[0]));
                decList.push(parseInt(itemNum[3]) - parseInt(itemNum[0]));
                decList.push(parseInt(itemNum[2]) - parseInt(itemNum[1]));
                decList.push(parseInt(itemNum[3]) - parseInt(itemNum[1]));
                decList.push(parseInt(itemNum[3]) - parseInt(itemNum[2]));
                if (this.deduplicationMethod([],decList).length === 6){
                  returnNum = true;
                }
                break;
              }
            }
          })
        }
        if (isSave === 1){
          if (returnNum){
            return item;
          }
        }else {
          if (!returnNum){
            return item;
          }
        }

      })
    },
    //位积 反位积 合值
    positionAccu(list,num,isSave,title,btnName){
      return list.filter((item) =>{
        let isReturn = false;
        switch (title) {
          case '位积':
            switch (btnName) {
              case '合值':{
                let accuNum = item.toString().replaceAll('X','0').split('');
                let sum = 0;
                accuNum.forEach((itemNum,index) =>{
                  sum = sum + parseInt(itemNum) * (index + 1);
                })
                num.forEach((x) =>{
                  if (sum.toString().charAt(sum.toString().length - 1) === x.toString()){
                    isReturn = true;
                  }
                })
                break;
              }
              case '和值':{
                let accuNum = item.toString().replaceAll('X','0').split('');
                let sum = 0;
                accuNum.forEach((itemNum,index) =>{
                  sum = sum + parseInt(itemNum) * (index + 1);
                })
                num.forEach((x) =>{
                  if (sum.toString() === x.toString()){
                    isReturn = true;
                  }
                })
                break;
              }
            }
            break;
          case '反位积':
            switch (btnName) {
              case '合值':{
                let accuNum = item.toString().replaceAll('X','0').split('');
                let sum = 0;
                accuNum.forEach((itemNum,index) =>{
                  sum = sum + parseInt(itemNum) * (accuNum.length - index);
                })
                num.forEach((x) =>{
                  if (sum.toString().charAt(sum.toString().length - 1) === x.toString()){
                    isReturn = true;
                  }
                })
                break;
              }
              case '和值':{
                let accuNum = item.toString().replaceAll('X','0').split('');
                let sum = 0;
                accuNum.forEach((itemNum,index) =>{
                  sum = sum + parseInt(itemNum) * (accuNum.length - index);
                })
                num.forEach((x) =>{
                  if (sum.toString() === x.toString()){
                    isReturn = true;
                  }
                })
                break;
              }
            }
            break;
        }
        if (isSave === 1){
          if (isReturn){
            return item;
          }
        }else {
          if (!isReturn){
            return item;
          }
        }
      })
    },
    //凹凸升降
    aoTuMethod(list,num,isSave){
      return list.filter((item) =>{
        let isReturn = false;
        num.forEach((ite) =>{
          switch (ite) {
            case '1':{
              let numList = item.toString().replaceAll('X','').split('');
              if (parseInt(numList[1]) < parseInt(numList[0]) && parseInt(numList[1]) < parseInt(numList[3]) && parseInt(numList[2]) < parseInt(numList[0]) && parseInt(numList[2]) < parseInt(numList[3])){
                isReturn = true;
              }
              break;
            }
            case '2':{
              let numList = item.toString().replaceAll('X','').split('');
              if (parseInt(numList[1]) > parseInt(numList[0]) && parseInt(numList[1]) > parseInt(numList[3]) && parseInt(numList[2]) > parseInt(numList[0]) && parseInt(numList[2]) > parseInt(numList[3])){
                isReturn = true;
              }
              break;
            }
            case '3':{
              let numList = item.toString().replaceAll('X','').split('');
              if (parseInt(numList[1]) > parseInt(numList[0]) && parseInt(numList[2]) > parseInt(numList[1]) && parseInt(numList[3]) > parseInt(numList[2])){
                isReturn = true;
              }
              break;
            }
            case '4':{
              let numList = item.toString().replaceAll('X','').split('');
              if (parseInt(numList[1]) < parseInt(numList[0]) && parseInt(numList[2]) < parseInt(numList[1]) && parseInt(numList[3]) < parseInt(numList[2])){
                isReturn = true;
              }
              break;
            }
            case '5':{
              let numList = item.toString().replaceAll('X','').split('');
              if (parseInt(numList[1]) === parseInt(numList[0]) && parseInt(numList[2]) === parseInt(numList[1]) && parseInt(numList[3]) === parseInt(numList[2])){
                isReturn = true;
              }
              break;
            }
            case '6':{
              let numList = item.toString().replaceAll('X','').split('');
              if (parseInt(numList[1]) < parseInt(numList[0]) && parseInt(numList[1]) < parseInt(numList[2])){
                isReturn = true;
              }
              break;
            }
            case '7':{
              let numList = item.toString().replaceAll('X','').split('');
              if (parseInt(numList[1]) > parseInt(numList[0]) && parseInt(numList[1]) > parseInt(numList[2])){
                isReturn = true;
              }
              break;
            }
            case '8':{
              let numList = item.toString().replaceAll('X','').split('');
              if (parseInt(numList[1]) > parseInt(numList[0]) && parseInt(numList[2]) > parseInt(numList[1])){
                isReturn = true;
              }
              break;
            }
            case '9':{
              let numList = item.toString().replaceAll('X','').split('');
              if (parseInt(numList[1]) < parseInt(numList[0]) && parseInt(numList[2]) < parseInt(numList[1])){
                isReturn = true;
              }
              break;
            }
            case '10':{
              let numList = item.toString().replaceAll('X','').split('');
              if (parseInt(numList[1]) === parseInt(numList[0]) && parseInt(numList[2]) === parseInt(numList[1])){
                isReturn = true;
              }
              break;
            }
          }
        })
        if (isSave === 1){
          if (isReturn){
            return item;
          }
        }else {
          if (!isReturn){
            return item;
          }
        }
      })
    },
    //减式
    decWay(list,num,isSave,addList,decList){
      return list.filter((item) =>{
        let isReturn = false;
        let addNum = 0;
        let decNum = 0;
        addList.forEach((ite) =>{
          switch (ite) {
            case 'A':
              addNum = addNum + parseInt(item.toString().charAt(0));
              break;
            case 'B':
              addNum = addNum + parseInt(item.toString().charAt(1));
              break;
            case 'C':
              addNum = addNum + parseInt(item.toString().charAt(2));
              break;
            case 'D':
              addNum = addNum + parseInt(item.toString().charAt(3));
              break;
          }
        })
        decList.forEach((ite) =>{
          switch (ite) {
            case 'A':
              decNum = decNum + parseInt(item.toString().charAt(0));
              break;
            case 'B':
              decNum = decNum + parseInt(item.toString().charAt(1));
              break;
            case 'C':
              decNum = decNum + parseInt(item.toString().charAt(2));
              break;
            case 'D':
              decNum = decNum + parseInt(item.toString().charAt(3));
              break;
          }
        })
        let sum = Math.abs(addNum - decNum);
        let sumOneNum = sum.toString().charAt(sum.toString().length - 1);
        if (isSave === 1){
          if (num.includes(parseInt(sumOneNum))){
            isReturn = true;
          }
        }else {
          if (!num.includes(parseInt(sumOneNum))){
            isReturn = true;
          }
        }
        if (isReturn){
          return item;
        }
      })
    },
    //全倒
    filterAllSort(list,num,payType){
      let computedList = this.allSort(num,payType);
      return list.filter((item) =>{
        if (computedList.includes(item.toString().replaceAll('X',''))){
          return item;
        }
      })
    },
    allSort(list,n){
      let allNum = [];
      this.allSort2(list,'',list.length,list.length,allNum);
      let p = allNum.map(item =>{
        let ite = '';
        for (let i=0;i<n;i++){
          ite = ite + item.toString().charAt(i);
        }
        return ite;
      })
      let x = Array.from(new Set(p));
      return x;
    },
    allSort2(list,num,posNum,listLength,allNum){
      if (listLength - list.length === posNum - 1){
        allNum.push(num+list[0].toString());
      }else {
        list.forEach((item,itemIndex) =>{
          let list1 = this.deepClone(list);
          list1.splice(itemIndex,1);
          this.allSort2(list1,item.toString()+num,posNum,listLength,allNum);
        })
      }
    },
    //开奖历史
    historyDataMethod(list,num,isSave){
      let numType = list[0].toString().replaceAll('X','').length;
      return list.filter((item) =>{
        let isReturn = false;
        switch (numType) {
          case 2:{
            let firstX = item.toString().indexOf('X');
            let secondX = item.toString().indexOf('X',firstX+1);
            num.forEach((ite) =>{
              let x = ite.toString().split('');
              x.splice(firstX,1,'');
              x.splice(secondX,1);
              if (x.join('') === item.toString().replaceAll('X','')){
                isReturn = true;
              }
            })
            break;
          }
          case 3:{
            let firstX = item.toString().indexOf('X');
            num.forEach((ite) =>{
              ite.toString().split('').splice(firstX,1,'').join('');
              if (ite === item.toString().replaceAll('X','')){
                isReturn = true;
              }
            })
            break;
          }
          case 4:{
            num.forEach((ite) =>{
              if (ite.toString() === item.toString()){
                isReturn = true;
              }
            })
            break;
          }
        }
        if (isSave === 1 && isReturn){
          return item;
        }else if (isSave === 0 && !isReturn){
          return  item;
        }
      })
    }
  }
}
</script>

<style lang="less">
.mainBox {
  .header_common_box {
    border-bottom: 1px solid #eee;
  }

  .van-sidebar {
    width: 2.8rem;
    position: fixed;
    top: 1.2rem;
    left: 0;
    bottom: 1.8rem;
    overflow-y: scroll;
  }

  .right_box {
    position: fixed;
    top: 45px;
    left: 2.7rem;
    bottom: 1.8rem;
    right: 0;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    font-size: 15px;

    div {
      h4 {
        margin: 10px;
        padding: 0px;
        color: red;
      }

      .van-grid {
        margin-bottom: 8px;
        margin-top: 3px;

        .van-grid-item {
          .van-grid-item__content--center {
            padding: 3px 10px;

            .infoTitleBox {
              width: 100%;

              h4 {
                margin: 0px;
                padding: 0px;
                color: black;
              }

              .returnStyle{
                margin: 0px;
                padding: 0px;
                color: red;
              }
            }

            .infoMesBox {
              width: 100%;

              p {
                margin: 5px 0px;
                font-size: small;
              }
            }

            .infoBtnBox {
              width: 100%;
              height: 40%;

              .van-button {
                float: right;
                margin: 5px;
              }
            }
          }
        }

      }
    }

    .van-popup{
      background-color: #FFFFFFD8;

      div{
        width: 100%;
        height: 1.2rem;
        background-color: red;
        color: white;

        .lapDev{
          float: left;
          width: 20%;
          height: 50%;
          background-color: white;
          margin-top: 0.2rem;
          margin-left: 10px;
          border: 2px solid white;

          .chooseClass{
            border-radius: 0px;
            width: 50%;
            height: 103%;
            padding: 0;
            background-color: white;
            color: red;
            border: none;
          }

          .noChooseClass{
            border-radius: 0px;
            width: 50%;
            height: 103%;
            padding: 0;
            background-color: red;
            color: white;
            border: none;
          }
        }

        .van-button{
          float: right;
          font-size: 13px;
          height: 70%;
          background-color: red;
          border-block-color: white;
          border: solid;
          margin-top: 7px;
          margin-right: 10px;

          .van-button__content{
            height: 10px;
          }
        }
      }

      p{
        margin: 5px 5px;
        color: #7a7a7a;
      }

      .condMes{
        width: 90%;
        height: 1.9rem;
        margin: 10px auto;
        padding: 0;
        background-color: white;

        .iconCheck{
          color: red;
          font-size: 30px;
          margin: 20px 10px;
          border-radius: 50%;
          float: left;
        }

        .iconNoCheck{
          color: grey;
          font-size: 30px;
          margin: 20px 10px;
          border-radius: 50%;
          float: left;
        }

        div{
          margin: 0;
          padding: 0;
          width: 60%;
          height: 100%;
          float: left;
          display: inline-block;
          background-color: white;
        }

        .condTitle{
          display: inline-block;
          color: black;
          margin: 8px 5px;
          margin-bottom: 0px;
          padding: 0;
          float: left;
        }
        .btnTitle{
          display: inline-block;
          margin: 8px 5px;
          margin-bottom: 0px;
          float: left;
          background-color: #dcdee0;
          font-size: 10px;
          padding: 1px 5px;
          color: darkgray;
        }
        .condNumber{
          display: inline-block;
          color: gray;
          margin: 1px 5px;
          margin-bottom: 0px;
          padding: 0;
          float: left;
        }

        .van-button{
          padding: 0;
          width: 15%;
          background-color: white;
          float: right;
          height: 40%;
          margin: 20px 10px;
          border-width: 0.5px;
          border-bottom-color: grey;

          div{
            background-color: white;
            width: 100%;
            height: 50%;

            span{
              color: grey;
              font-size: 15px;
            }
          }
        }
      }

      .padDiv{
        width: 100%;
        height: 1.8rem
      }
    }

  }

  .van-tabbar{
    height: 1.8rem;

    .mes{
      width: 25%;
      height: 100%;

      div{
        width: 100%;
        height: 100%;
        background-color: orange;

        p:nth-child(1){
          margin: 0;
          padding: 0;
          color: white;
          font-size: 23px;
          text-align: center;
          padding-top: 8px;
        }
        p:nth-child(2){
          margin: 0;
          color: white;
          font-size: 15px;
          text-align: center;
        }
      }
    }

    .condition{
      width: 45%;
      height: 100%;

      div{
        width: 100%;
        height: 100%;
        background-color: #7a7a7a;

        p:nth-child(1){
          color: white;
          padding: 5px 10px;
          margin: 0;
          font-size: 15px;
        }
        p:nth-child(2){
          color: rgba(255, 255, 255, 0.7);
          padding: 2px 10px;
          margin: 0;
          font-size: 10px;
        }

      }
    }
    .groupNum{
      width: 30%;
      height: 100%;

      div{
        width: 100%;
        height: 100%;
        background-color: red;

        p:nth-child(1){
          margin: 0;
          padding: 0;
          color: white;
          font-size: 23px;
          text-align: center;
          padding-top: 8px;
        }
        p:nth-child(2){
          margin: 0;
          color: white;
          font-size: 15px;
          text-align: center;
        }

      }
    }
  }

  .van-popup{
    div:nth-child(1){
      width: 100%;
      height: 46.39px;
      background-color: red;
      color: white;

      a:nth-child(1){
        font-size: 20px;
        padding: 5px 10px;
        padding-right: 7px;
        float: left;
      }
      a:nth-child(2){
        font-size: 10px;
        padding: 5px 10px;
        margin-top: 10px;
        background-color: rgba(255, 255, 255, 0.38);
        float: left;
      }
      .van-button{
        float: right;
        font-size: 13px;
        height: 70%;
        background-color: red;
        border: solid;
        margin-top: 7px;
        margin-right: 10px;
        border-color: white;

        .van-button__content{
          height: 10px;
        }
      }
    }
    div:nth-child(2){
      height: calc(100% - 92.78px);;
      width: 100%;

      div{
        width: 80%;
        height: 100%;
        background-color: white;
        color: black;
        margin: auto;

        .mesBox{
          font-size: 13px;
        }

        h5{
          margin: 10px 30px;
          font-size: 20px;
        }

        ol{
          li{
            border-radius: 50%;
            width: 36px;
            height: 36px;
            display: inline-block;
            background-color: white;
            text-align: center;
            margin: 10px 10px;
            font-size: 0.8rem;
            border: solid;
            border-width: 1.8px;
            border-color: gray;
          }
          .liStyle{
            border-radius: 50%;
            width: 36px;
            height: 36px;
            display: inline-block;
            background-color: white;
            text-align: center;
            margin: 10px 10px;
            font-size: 30px;
            border: solid;
            border-width: 1.8px;
            border-color: gray;
          }

          .liStyleCheck{
            border-radius: 50%;
            width: 36px;
            height: 36px;
            display: inline-block;
            background-color: #fb3e44;
            text-align: center;
            margin: 10px 10px;
            font-size: 30px;
            color: white;
            border: solid;
            border-width: 1.8px;
            border-color: #fb3e44;
          }
        }

        .freeCodeOl{
          li{
            border-radius: 50%;
            width: 26px;
            height: 26px;
            display: inline-block;
            background-color: white;
            text-align: center;
            margin: 3px 10px;
            font-size: 0.6rem;
            border: solid;
            border-width: 1.8px;
            border-color: gray;
            pointer-events: auto;
          }
        }

        .van-checkbox-group{
          margin: 0;
          padding: 0;
          width: 100%;

          div{
            margin: 8px 0px;
            padding: 0;
            width: 100%;

            div{
              width: 10%;
            }
          }
        }
        
        .positionAnyChoose{
          margin: 0;
          padding: 0;
          width: 100%;

          div{
            margin: 5px 0px;
            padding: 0;
            width: 50%;

            div{
              width: 10%;
            }

            span{
              font-size: 15px;
            }
          }
        }

        .van-radio-group{
          margin: 0;
          padding: 0;
          width: 100%;

          div{
            margin: 8px 0px;
            padding: 0;
            width: 20%;
            display: inline-block;
            font-size: 15px;

            div{
              width: 10%;
            }

            span{
              padding-left: 10px;
            }
          }
        }

        .freeCodeRadio{
          margin: 0;
          padding: 0;
          padding-left: 20px;
          width: 100%;
          height: 30%;

          div{
            margin: 0px 2px;
            padding: 0;
            width: 30%;
            display: inline-block;
            font-size: 15px;

            div{
              width: 10%;
            }

            span{
              padding-left: 10px;
            }
          }
        }

        .van-tabs{
          margin-top: 10px;

          div:nth-child(1){
            width: 100%;

            div{
              width: 100%;

              div{
                width: 15%;
              }
            }
          }
          div:nth-child(2){
            width: 100%;

            .positionAnyChooseBMS{
              margin: 0;
              padding: 0;
              width: 100%;

              div{
                margin: 2px 0px;
                padding: 0;
                width: 33%;

                div{
                  width: 10%;
                }

                span{
                  font-size: 14px;
                  margin-left: 15px;
                }
              }
            }
          }
        }

        .multiplyWay{
          margin: 0;
          padding: 0;
          padding-top: 5px;
          width: 100%;

          div{
            margin: 2px 0px;
            padding: 0;
            width: 45%;
            display: inline-block;

            div{
              width: 10%;
            }
          }
        }

        .btnStyle{
          margin: 0;
          margin-top: 10px;
          padding: 3px 8px;
          border: none;
          font-size: 20px;
          display: inline-block;
          background-color: cornsilk;
          color: black;
          float: right;
        }
        .btnSave{
          margin: 0;
          margin-top: 10px;
          padding: 3px 8px;
          border: none;
          font-size: 20px;
          display: inline-block;
          background-color: green;
          color: white;
          float: right;
        }
        .btnExclude{
          margin: 0;
          margin-top: 10px;
          padding: 3px 8px;
          border: none;
          font-size: 20px;
          display: inline-block;
          background-color: red;
          color: white;
          float: right;
        }

      }

      .divTwo{
        width: 100%;
        height: 100%;
        background-color: white;
        color: black;

        h5{
          margin: 10px 30px;
          font-size: 20px;
        }

        div{
          margin: 0;
          padding: 0;
          width: 100%;
          height: 100%;
          background-color: white;
          color: black;

          h5{
            display: inline-block;
            margin: 10px 20px;
            float: left;
          }

          ol{
            margin: 0;
            padding: 0;
            display: inline-block;
            width: 70%;

            li{
              border-radius: 50%;
              width: 26px;
              height: 26px;
              display: inline-block;
              background-color: #fbf0ff;
              text-align: center;
              margin: 10px 10px;
              font-size: 0.6rem;
            }

            .liConfigNumber{
              border-radius: 50%;
              width: 26px;
              height: 26px;
              display: inline-block;
              background-color: red;
              text-align: center;
              margin: 10px 10px;
              font-size: 0.6rem;
              color: white;
            }
          }
        }

        p{
          margin: 10px 20px;
        }

        .mesBox{
          font-size: 13px;
        }
      }


    }
    div:nth-child(3){
      width: 100%;
      height: 46.39px;

      .van-button:nth-child(1){
        margin: 5px 20px;
        border: none;
        border-radius: 10px;
        width: 40%;
        height: 70%;
        padding: 0;
        float: left;

        .van-button__content{
          height: 10px;
          background-color: #7a7a7a;
        }
      }
      .van-button:nth-child(2){
        margin: 5px 10px;
        border: none;
        border-radius: 10px;
        width: 40%;
        height: 70%;
        padding: 0;
        float: left;

        .van-button__content{
          height: 10px;
          background-color: red;
        }
      }
    }
  }

  .conditionNum{
    position: absolute;
    z-index: 0;

    .van-popup{
      background-color: #FFFFFFD8;

      div{
        width: 100%;
        height: 1.2rem;
        background-color: red;
        color: white;

        .lapDev{
          float: left;
          width: 20%;
          height: 50%;
          background-color: white;
          margin-top: 0.2rem;
          margin-left: 10px;
          border: 2px solid white;
          font-size: 10px;

          .chooseClass{
            border-radius: 0px;
            width: 50%;
            height: 103%;
            padding: 0;
            background-color: white;
            color: red;
            border: none;
          }

          .noChooseClass{
            border-radius: 0px;
            width: 50%;
            height: 103%;
            padding: 0;
            background-color: red;
            color: white;
            border: none;
          }
        }

        .van-button{
          float: right;
          font-size: 13px;
          height: 70%;
          background-color: red;
          border-block-color: white;
          border: solid;
          margin-top: 7px;
          margin-right: 10px;
          border-color: white;

          .van-button__content{
            height: 10px;
          }
        }
      }

      p{
        margin: 5px 5px;
        color: #7a7a7a;
        font-size: 15px;
      }

      .condMes{
        width: 90%;
        height: 1.9rem;
        margin: 10px auto;
        padding: 0;
        background-color: white;

        .iconCheck{
          color: red;
          font-size: 30px;
          margin: 20px 10px;
          border-radius: 50%;
          float: left;
        }

        .iconNoCheck{
          color: grey;
          font-size: 30px;
          margin: 20px 10px;
          border-radius: 50%;
          float: left;
        }

        div{
          margin: 0;
          padding: 0;
          width: 60%;
          height: 100%;
          float: left;
          display: inline-block;
          background-color: white;
        }

        .condTitle{
          display: inline-block;
          color: black;
          margin: 8px 5px;
          margin-bottom: 0px;
          padding: 0;
          float: left;
        }
        .btnTitle{
          display: inline-block;
          margin: 8px 5px;
          margin-bottom: 0px;
          float: left;
          background-color: #dcdee0;
          font-size: 10px;
          padding: 1px 5px;
          color: darkgray;
        }
        .condNumber{
          display: inline-block;
          color: gray;
          margin: 1px 5px;
          margin-bottom: 0px;
          padding: 0;
          float: left;
          width: 100%;
        }

        .van-button{
          padding: 0;
          width: 15%;
          background-color: white;
          float: right;
          height: 40%;
          margin: 20px 10px;
          border-width: 0.5px;
          border-bottom-color: grey;
          border-color: grey;

          div{
            background-color: white;
            width: 100%;
            height: 50%;

            span{
              color: grey;
              font-size: 15px;
            }
          }
        }
      }

      .padDiv{
        width: 100%;
        height: 1.8rem
      }
    }
  }
}

</style>
